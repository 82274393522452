import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import QuotationListWrapper from '../components/quotation/list/QuotationListWrapper';
import {
  CreateGroupModal,
  CreateQuotationModal,
  DeleteFeatureModal,
  DeleteItemModal,
  SelectFeatureModal,
} from '../components/quotation/modals';
import CreateGTConfirmationModal from '../components/quotation/modals/CreateGTConfirmationModal';
import CreateServiceModal from '../components/quotation/modals/CreateServiceModal';
import GeneratePDFModalWrapper from '../components/quotation/modals/GeneratePDFModalWrapper';
import CreateItemOverlay from '../components/quotation/overlays/CreateProductOverlay';
import ProjectLogOverlay from '../components/quotation/overlays/ProjectLogOverlay';
import GroupSuggestionOverlay from '../components/quotation/suggestions/GroupSuggestionOverlay';
import PresetSuggestionOverlay from '../components/quotation/suggestions/PresetSuggestionOverlay';
import InfoOverlay from '../components/quotation/topBar/InfoOverlay';
import TopBar from '../components/quotation/topBar/TopBar';
import ModalWrapper from '../components/shared/modal/ModalWrapper';
import { useAppDispatch, useAppSelector } from '../helpers/reduxHooks';
import {
  setShowCreateGroupModal,
  setShowCreateQuotationModal,
  setShowCreateServiceModal,
  setShowDeleteFeatureModal,
  setShowDeleteModal,
  setShowGeneratePDFModal,
  setShowSelectFeatureModal,
  setShowCreateGTConfirmationModal,
} from '../redux/quotationSlice';

export default function QuotationDetails() {
  const { quotationId } = useParams();
  const showDeleteModal = useAppSelector(state => state.quotation.showDeleteModal);
  const showCreateGroupModal = useAppSelector(state => state.quotation.showCreateGroupModal);
  const showCreateServiceModal = useAppSelector(state => state.quotation.showCreateServiceModal);
  const showCreateQuotationModal = useAppSelector(state => state.quotation.showCreateQuotationModal);
  const showGeneratePDFModal = useAppSelector(state => state.quotation.showGeneratePDFModal);
  const showSelectFeatureModal = useAppSelector(state => state.quotation.showSelectFeatureModal);
  const showDeleteFeatureModal = useAppSelector(state => state.quotation.showDeleteFeatureModal);
  const showCreateGTConfirmationModal = useAppSelector(state => state.quotation.showCreateGTConfirmationModal);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className="relative flex flex-wrap w-fit min-w-full overflow-x-auto whitespace-nowrap">
      <div className="w-full">
        <div>
          <main className='mr-4 ml-1'>
            <TopBar />
            {quotationId
              ? <QuotationListWrapper />
              : <div className="text-3xl w-full h-96 flex items-center justify-center text-cgray-400">{t('Quotation not found')}</div>
            }
          </main>
          <InfoOverlay />
          <ProjectLogOverlay />
          <GroupSuggestionOverlay />
          <PresetSuggestionOverlay />
          <CreateItemOverlay />
          {showDeleteModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowDeleteModal(false))}
              show={showDeleteModal}
            >
              <DeleteItemModal handleClose={() => dispatch(setShowDeleteModal(false))} />
            </ModalWrapper>
          }
          {showCreateGroupModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowCreateGroupModal(false))}
              show={showCreateGroupModal}
            >
              <CreateGroupModal
                handleClose={() => dispatch(setShowCreateGroupModal(false))}
              />
            </ModalWrapper>
          }
          {showCreateServiceModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowCreateServiceModal(false))}
              show={showCreateServiceModal}
            >
              <CreateServiceModal
                handleClose={() => dispatch(setShowCreateServiceModal(false))}
              />
            </ModalWrapper>
          }
          {showCreateQuotationModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowCreateQuotationModal(false))}
              show={showCreateQuotationModal}
            >
              <CreateQuotationModal
                handleClose={() => dispatch(setShowCreateQuotationModal(false))}
              />
            </ModalWrapper>
          }
          {showGeneratePDFModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowGeneratePDFModal(false))}
              show={showGeneratePDFModal}
              maxWidthClass='max-w-7xl'
            >
              <GeneratePDFModalWrapper
                handleClose={() => dispatch(setShowGeneratePDFModal(false))}
              />
            </ModalWrapper>
          }
          {showSelectFeatureModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowSelectFeatureModal(false))}
              show={showSelectFeatureModal}
            >
              <SelectFeatureModal
                handleClose={() => dispatch(setShowSelectFeatureModal(false))}
              />
            </ModalWrapper>
          }
          {showDeleteFeatureModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowDeleteFeatureModal(false))}
              show={showDeleteFeatureModal}
            >
              <DeleteFeatureModal handleClose={() => dispatch(setShowDeleteFeatureModal(false))} />
            </ModalWrapper>
          }
          {showCreateGTConfirmationModal &&
            <ModalWrapper
              handleClose={() => dispatch(setShowCreateGTConfirmationModal(false))}
              show={showCreateGTConfirmationModal}
            >
              <CreateGTConfirmationModal handleClose={() => dispatch(setShowCreateGTConfirmationModal(false))} />
            </ModalWrapper>
          }
        </div>
      </div>
    </div>
  );
}
