import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ALPHANUMERIC_FEATURE_VALUES_QUERY } from '../../../../../../../api/queries/etimReleases';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import { SelectInput } from '../../../../../../../layout/fields';
import { QuotationGroupFeature, setSelectedGroupFeature } from '../../../../../../../redux/quotationSlice';

export default function GroupFeatureANValue() {
  const { t } = useTranslation();
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();

  const handleChange = (e: SelectOption) => {
    const payload = {
      ...selectedGroupFeature,
      value: e.value,
    } as QuotationGroupFeature;
    dispatch(setSelectedGroupFeature(payload));
  };
 
  const [getANFeatureValuesData, { data: featureValuesData }] = useLazyQuery(ALPHANUMERIC_FEATURE_VALUES_QUERY);

  useEffect(() => {
    getANFeatureValuesData({
      fetchPolicy: 'cache-and-network',
      variables: { feature: selectedGroupFeature?.feature.id },
      onCompleted: () => {
        const newResponse = featureValuesData?.alphanumericFeatureValues?.response;
        if (!selectedGroupFeature?.value &&  newResponse) {
          const payload = {
            ...selectedGroupFeature,
            value: newResponse[0]?.localizedDescription,
          } as QuotationGroupFeature;
          dispatch(setSelectedGroupFeature(payload));
        }
      },
    });
  }, [dispatch, featureValuesData, getANFeatureValuesData, selectedGroupFeature]);

  // set options for AN type feature, when the featureValuesData is fetched
  let options: SelectOption[] = [];
  if (featureValuesData && featureValuesData.alphanumericFeatureValues && featureValuesData.alphanumericFeatureValues.response) {
    options = (featureValuesData.alphanumericFeatureValues.response.map((item) => ({ value: item?.code, label: item?.localizedDescription } as SelectOption)));
  }
  const value = options.find((item) => item.label === selectedGroupFeature?.value);

  return (
    <SelectInput
      options={options}
      placeholder={t('Select Value')}
      onChange={handleChange}
      value={value}
    />
  );
}
