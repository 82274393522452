import { useEffect } from 'react';
import { SEARCH_UNIVERSAL_QUERY } from '../api/queries/searchRecords';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import Filter from '../components/search/Filter';
import SearchHeader from '../components/search/searchHeader/SearchHeader';
import SearchListDescriptionData from '../components/search/SearchListDescriptionData';
import SearchListEtimData from '../components/search/SearchListEtimData';
import SearchListQuotationData from '../components/search/SearchListQuotationData';
import SearchSingleListWrapper from '../components/search/searchSingle/SearchSingleListWrapper';
import { SearchType } from '../constants';
import { useAppSelector, useAppDispatch } from '../helpers/reduxHooks';
import { Navbar } from '../layout';
import { setSearchType } from '../redux/searchSlice';

export default function SearchSingle() {
  const searchType = useAppSelector(state => state.search.searchType);
  const dispatch = useAppDispatch();

  // Set Description as default search type on page mount.
  useEffect(() => {
    dispatch(setSearchType(SearchType.DESCRIPTION));
  }, [dispatch]);

  return (
    <div className="flex flex-wrap min-h-screen w-fit min-w-full">
      <div className="w-full">
        <Navbar />
        <div className="pt-5">
          <ReduxAlertWrapper />
          <main className='w-full px-5 flex flex-col gap-4 mx-auto'>
            <SearchHeader />
            <div className='flex'>
              {searchType === SearchType.ETIM && <Filter />}
              <div className='w-full'>
                {searchType === SearchType.ETIM
                  && <SearchListEtimData
                    SearchListWrapper={SearchSingleListWrapper}
                    searchRecordsQuery={SEARCH_UNIVERSAL_QUERY}
                  />}
                {searchType === SearchType.DESCRIPTION
                  && <SearchListDescriptionData SearchListWrapper={SearchSingleListWrapper} />}
                {searchType === SearchType.QUOTATION
                  && <SearchListQuotationData SearchListWrapper={SearchSingleListWrapper} />}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
