export interface GroupIconModifiersProps {
  representsTemplate?: boolean;
}

interface Props extends GroupIconModifiersProps {
  className?: string;
  onClick?: () => void;
}

export default function IconG(props: Props) {
  const { representsTemplate, className, onClick } = props;

  return (
    <div onClick={onClick} className={className}>
      <span>G</span>
      {representsTemplate && <div className='absolute -top-1 -right-1 w-2.5 h-2.5 bg-cblue-500 rounded-full' />}
    </div>
  );
}