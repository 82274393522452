import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SERVICE_WAGES_QUERY } from '../../../../api/queries/settings';
import SelectInput from '../../../../layout/fields/SelectInput';

interface Props {
  setFieldValue: SetFieldValue,
}

export default function SelectServiceWageInput(props: Props) {
  const { setFieldValue } = props;
  const [projects, setProjects] = useState([] as SelectOption[]);
  const { t } = useTranslation();
  const [getData, { data, loading }] = useLazyQuery(SERVICE_WAGES_QUERY);

  useEffect(() => {
    getData();
    if (data && data.serviceWages && data.serviceWages.response) {
      const options: SelectOption[] = data.serviceWages.response.map((item) => ({ value: item?.id, label: item?.label } as SelectOption));
      setProjects(options);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && data.serviceWages && data.serviceWages.response) {
      const options: SelectOption[] = data.serviceWages.response.map((item) => ({ value: item?.id, label: item?.label } as SelectOption));
      setProjects(options);
    }
  }, [data]);

  return (
    <SelectInput
      options={projects}
      placeholder={t('Select Service Wage')}
      isLoading={loading}
      onChange={(e: SelectOption) => setFieldValue('serviceWage', e)}
    />
  );
}
