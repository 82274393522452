import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { InlineUserType } from '../../../__generated__/graphql';
import { INLINE_USERS_QUERY } from '../../../api/queries/users';
import { SelectInput } from '../../../layout/fields';

interface Props {
  managers: SelectOption[],
  setManagers: (managers: SelectOption[]) => void,
}

export default function ProjectManagerSelect(props: Props) {
  const { managers, setManagers } = props;
  const { t } = useTranslation();
  const { data, loading } = useQuery(INLINE_USERS_QUERY);

  const inlineUsers = data ? data.inlineUsers as InlineUserType[] : [];

  const options = inlineUsers.map((user) => ({ value: user.id, label: user.fullName } ));
    
  return (
    <SelectInput
      options={options}
      placeholder={t('Project managers')}
      isLoading={loading}
      isMulti
      onMultiChange={setManagers}
      multiValue={managers}
    />
  );
}
