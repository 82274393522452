import {
  EtimReleasesEtimFeatureFeatureTypeChoices,
  FeatureType,
  SearchFeatureType,
} from '../../../../__generated__/graphql';
import AlphanumericFeature from './AlphanumericFeature';
import LogicalFeature from './LogicalFeature';
import RangeFeature from './RangeFeature';

interface Props {
  feature: SearchFeatureType,
}

function FeatureDisplay(props: Props) {
  const { feature } = props;

  if (feature) {
    switch (feature.featureType) {
      case EtimReleasesEtimFeatureFeatureTypeChoices.Range:
      case EtimReleasesEtimFeatureFeatureTypeChoices.Numeric:
        return (
          <RangeFeature
            key={feature.id}
            feature={feature} 
          />
        );
      case EtimReleasesEtimFeatureFeatureTypeChoices.Alphanumeric:
        return (
          <AlphanumericFeature
            key={feature.id}
            feature={feature as FeatureType}
          />
        );
      case EtimReleasesEtimFeatureFeatureTypeChoices.Logical:
        return (
          <LogicalFeature
            key={feature.id}
            feature={feature as SearchFeatureType}
          />
        );
      default:
    }
  }

  return <></>;
}

export default FeatureDisplay;