import { useApolloClient, useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { IconCoins, IconInfoSquareRounded, IconNotes } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { TreeItem, arrayToTree, Item } from 'performant-array-to-tree';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemTypeChoices, QuotationVersionTypeChoices } from '../../../__generated__/graphql';
import { INLINE_PROJECTS_QUERY } from '../../../api/queries/projects';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { INLINE_RECORDS_SIZE } from '../../../config';
import { useProjectChange } from '../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../../helpers/utils';
import { 
  setIsComposeMode, setIsInfoOverlayOpen, setShowFullSummary, setIsProjectLogOverlayOpen,
} from '../../../redux/quotationSlice';
import ContextMenu from './ContextMenu';
import SelectProject from './SelectProject';
import SelectProjectStatus from './SelectProjectStatus';
import SelectQuotation from './SelectQuotation';

export default function TopBar() {
  const { projectId, quotationId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();
  const query: DocumentNode = INLINE_PROJECTS_QUERY;
  const [, setItemsTree] = useState<TreeItem[]>([]);
  const showFullSummary = useAppSelector(state => state.quotation.showFullSummary);

  const {
    data: quotationData,
    loading: quotationLoading,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId,
  });

  const isQuotationReadOnly = quotationData?.quotation?.response?.versionType === QuotationVersionTypeChoices.Readonly;
  const quotationName = quotationData?.quotation?.response?.name;

  const handleProjectChange = useProjectChange();

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadProjectOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_PROJECTS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);

    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  useEffect(() => {
    if (isQuotationReadOnly) {
      dispatch(setIsComposeMode(false));
    }
  }, [dispatch, isQuotationReadOnly]);

  const handleQuotationChange = (selectedId: string) => {
    navigate(`/quotation/${projectId}/${selectedId}/`);
  };

  useEffect(() => {
    const quotationItems = quotationData
      && quotationData.quotation
      && quotationData.quotation.response
      && quotationData.quotation.response.items || [];

    const preparedItems = quotationItems.map(item => {
      return {
        ...item,
        canHaveChildren: item?.itemType === ItemTypeChoices.Group,
      };
    });
    if (quotationData) {
      setItemsTree(arrayToTree(preparedItems as Item[], { dataField: null }));
    }
  }, [dispatch, quotationData]);


  return (
    <div className="sticky top-0 z-30 bg-white">
      <div className="pt-4 flex justify-between">
        <div className="flex gap-4 items-center justify-center">
          <div className="w-64 flex flex-col">
            <SelectProject
              projectId={projectId}
              onChange={handleProjectChange}
              loadOptions={loadProjectOptions}
            />
          </div>
          {
            projectId &&
            <div className="w-56 flex flex-col" >
              <SelectProjectStatus
                changeQuotation={handleQuotationChange}
              />
            </div>
          }
          {
            projectId && quotationId &&
            <div className="w-96 flex flex-col">
              <SelectQuotation
                onChange={handleQuotationChange}
                projectId={projectId}
                quotationId={quotationId}
              />
            </div>
          }
          {isQuotationReadOnly && (
            <span className="h-full inline-flex items-center rounded-sm bg-cyellow-500 px-2 py-1 text-cgray-700 ring-1 ring-inset ring-gray-500/10">
              {quotationData?.quotation?.response?.versionTypeLabel}
            </span>
          )}
        </div>

        {!quotationLoading && <div className="flex gap-2 items-center">
          {quotationData?.quotation?.response?.generateItemsPositions
            ? <div className='text-xs pr-6'></div>
            : <div className='text-xs pr-6'>{t('Items positions generation disabled')}</div>
          }
         
          <div>
            {quotationData?.quotation?.response?.items
              && quotationData.quotation.response.items.length > 1 
              && !showFullSummary
              ?
                <div
                  onClick={() => dispatch(setShowFullSummary(true))}
                  className="flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100 cursor-pointer w-44"
                >
                  <IconCoins className="w-5 h-5 mr-2" />
                  {quotationData?.quotation?.response?.totalSalesPriceLabel as string}
                </div>
              :
                <div
                  onClick={() => dispatch(setShowFullSummary(false))}
                  className="flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300 cursor-pointer w-44"
                >
                  <IconCoins className="w-5 h-5 mr-2" />
                  {quotationData?.quotation?.response?.totalSalesPriceLabel as string}
                </div>
            }
          </div>
          <Tippy content={t('Info')} placement="top" >
            <IconInfoSquareRounded
              onClick={() => dispatch(setIsInfoOverlayOpen(true))}
              className=' w-6 text-cblue-500 hover:text-cblue-700 cursor-pointer focus:outline-0'
            />
          </Tippy>
          <Tippy content={t('Project Log')} placement="top" >
            <IconNotes
              onClick={() => dispatch(setIsProjectLogOverlayOpen(true))}
              className=' w-6 text-cblue-500 hover:text-cblue-700 cursor-pointer focus:outline-0'
            />
          </Tippy>
          {quotationId && quotationName && (
            <ContextMenu quotationName={quotationName} isQuotationReadOnly={isQuotationReadOnly} />
          )}
        </div>}
      </div>
    </div>
  );
}
