import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  GroupTemplateGroupFeatureType,
  QuotationGroupFeatureType,
  GroupTemplateGroupDetailsType,
  QuotationGroupDetailsType,
} from '../../../../../../__generated__/graphql';
import { GROUP_TEMPLATE_GROUP_DETAILS_QUERY } from '../../../../../../api/queries/groupTemplates';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../../../../api/queries/quotations/details';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { LoadingIndicator } from '../../../../../../layout';
import ROGroupFeatures from './groupFeature/ROGroupFeatures';
import ROGroupTemplateFeatures from './groupFeature/ROGroupTemplateFeatures';

interface Props {
  groupId: ID;
  isGroupQuotation: boolean;
  indentationWidth: number;
  depth: number;
  maximumUncollapsedDepth: number;
}

export default function ROGroupDetails(props: Props) {
  const { groupId, isGroupQuotation, indentationWidth, depth, maximumUncollapsedDepth } = props;
  const [group, setGroup] = useState<GroupTemplateGroupDetailsType | GroupTemplateGroupDetailsType | undefined>();
  const { t } = useTranslation();

  const GROUP_DETAILS_QUERY = isGroupQuotation ? QUOTATION_GROUP_DETAILS_QUERY : GROUP_TEMPLATE_GROUP_DETAILS_QUERY;

  const {
    loading: groupLoading,
  } = useQuery(GROUP_DETAILS_QUERY, {
    variables: {
      group: groupId as string,
    },
    onCompleted: (data) => {
      // @ts-ignore
      if (data.quotationGroupDetails?.response) {
        // @ts-ignore
        setGroup(data.quotationGroupDetails.response as QuotationGroupDetailsType);
        // @ts-ignore
      } else if (data.groupTemplateGroupDetails.response) {
        // @ts-ignore
        setGroup(data.groupTemplateGroupDetails.response as GroupTemplateGroupDetailsType);
      }
    },
  });
  if (groupLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!groupLoading && group) {
    return (
    <div className='p-2.5 pr-5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-cgray-400 z-20 bg-white'>
      <div
        className="flex justify-end"
        style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
      ></div>
      <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal">
        <div className="grid grid-cols-12 w-full gap-2 text-sm text-cgray-600">
          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
            <p className="text-2xs text-cgray-400">{t('Description')}</p>
            <p className='text-sm overflow-auto grow'>{group?.description}</p>
          </div>
          <div className='cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
            <p className="text-2xs text-cgray-400">{t('ETIM Class')}</p>
            <p className='text-sm overflow-auto grow'>{group?.etimClass?.localizedDescription}</p>
          </div>
          <div className='col-span-4 justify-self-end'>
          </div>
          <div className='h-36 cursor-default col-span-4 flex flex-col pl-2 py-px rounded-t border border-cgray-200'>
            <p className="text-2xs text-cgray-400">{t('Notes')}</p>
            <p className='text-sm overflow-auto grow'>{group?.notes}</p>
          </div>
          <div className='col-span-8 flex gap-2 flex-wrap'>
              {
                isGroupQuotation
                  ?
                  <ROGroupFeatures
                    groupFeatures={
                      group.groupFeatures
                        ? group.groupFeatures as QuotationGroupFeatureType[] | GroupTemplateGroupFeatureType[]
                        : []
                    }
                  /> 
                  :
                  <ROGroupTemplateFeatures
                    groupFeatures={group.groupFeatures ? group.groupFeatures as GroupTemplateGroupFeatureType[] : []}
                  /> 
              }
          </div>
        </div>

      </div>
    </div>
    );
  } else {
    return (<></>);
  }
}