import { gql } from '../../__generated__/gql';

export const PAGINATED_PROJECTS_QUERY = gql(`
  query paginatedProjects(
      $searchInput: String,
      $page: Int,
      $projectStatus: String,
      $managers: [ID],
    ){
    paginatedProjects(
        searchInput: $searchInput
        page: $page
        projectStatus: $projectStatus
        managers: $managers
      ){
        response{
          page
          pages
          hasNext
          hasPrev
          elidedPageRange
          totalObjectsQuantity
          objects {
            id
            nameLabel
            name
            projectStatus
            managers{
              id
              fullName
            }
            quotations{
              id
              name
            }
          }
        }
    }
  }
`);

export const INLINE_PROJECTS_QUERY = gql(`
  query inlineProjects($searchInput: String, $skip: Int){
    inlineProjects(
      searchInput: $searchInput
      skip: $skip
    ){
      response{
        hasNext
        objects{
          id
          nameLabel
          name
          quotations{
            id
            name
          }
        }
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const PROJECT_TYPES_QUERY = gql(`
  query projectTypes{
    projectTypes{
      response{
        value
        label
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const PROJECT_LOG_RECORDS_SCOPE_QUERY = gql(`
  query projectLogRecordsScope{
    projectLogRecordsScope{
      response{
        value
        label
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const PROJECT_ADDRESS_QUERY = gql(`
  query projectAddress($project: ID!){
    projectAddress(project: $project){
      response{
        city
        country
        street
        zipCode
        id
      }
      errors{
        field 
        messages
      }
    }
  }
`);

export const PROJECT_QUERY = gql(`
  query project($project: ID!){
    project(project: $project){
      response{
        id
        nameLabel
        name
        projectStatus
        daysOfValidity
        externalId
        notes
        projectType
        managers{
          id
          fullName
        }
        customer{
          id
          name
          label
        }
        latestQuotation{
          id
        }
        quotations{
          id
          name
          versionType
        }
      }
      errors{
        field 
        messages
      }
    }
  }
`);


export const PROJECT_LOGS_QUERY = gql(`
query ProjectsLog(
    $project: ID!
    $page: Int
    $projectLogRecordsScope: String
  ){
  projectLogs(
    project: $project
    page: $page
    projectLogRecordsScope: $projectLogRecordsScope
    ){
  
      response {
        page
        hasNext
        hasPrev
        totalObjectsQuantity
        elidedPageRange
        objects{
          ... on ProjectEventType {
            id
            createdAt
            eventType
            message
            triggeredBy{
              id
              fullName
            }
            quotation{
              id
              name
            }
            quotationGroup{
              id
              description
            }
            quotationProductionUnitStatus{
              id
              statusType
              color
              title
            }
          }
          ... on QuotationNoteType {
            id
            createdAt
            body
            author{
              id
              fullName
              firstName
              lastName
            }
            quotation{
              id
              name
            }
          }
        }
      }
      errors {
        messages
        field
      }
    }
}
`);