import Cookies from 'js-cookie';

export function clearAuthCookies() {
  Cookies.remove('jwt_token');
  Cookies.remove('jwt_refresh');
}

export function setAuthCookies(token: string, refreshToken: string) {
  Cookies.set('jwt_token', token);
  Cookies.set('jwt_refresh', refreshToken);
}

export function getRefreshToken() {
  return Cookies.get('jwt_refresh');
}
