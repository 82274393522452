import { useQuery } from '@apollo/client';
import { ClassType } from '../../../__generated__/graphql';
import {
  FEATURES_PRESET_QUERY,
} from '../../../api/queries/etimReleases';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { classNames } from '../../../helpers/utils';
import { setClass, setFeature } from '../../../redux/searchSlice';

interface Props {
  etimClass: ClassType,
}

export function EtimClass(props: Props) {
  const { etimClass } = props;

  const dispatch = useAppDispatch();
  const checkedClass = useAppSelector(state => state.search.selectedETIMClassId);
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);

  const {
    data: presetData,
  } = useQuery(FEATURES_PRESET_QUERY, {
    variables: {
      etimClass: etimClass.id,
    },
    skip: (!isComposeMode || etimClass.id !== checkedClass),
  });

  interface HandleChangeProps {
    value: string,
    checked: boolean,
  }

  const handleChangeClasses = (e: HandleChangeProps) => {
    const { value, checked } = e;
    // Add id's of selected classes to redux state, to use it in FilterFeatures component
    if (checked) {
      dispatch(setClass(value));
      if (presetData && presetData.featuresPreset && presetData.featuresPreset.response) {
        presetData.featuresPreset.response.map( presetItem => {
          dispatch(setFeature({
            id: presetItem?.etimFeature?.id,
            userValue: presetItem?.value,
            code: presetItem?.etimFeature?.code as string,
            featureType: presetItem?.etimFeature?.featureType as string,
          }));
        });
      }
    } else {
      dispatch(setClass(''));
    }
    (document.activeElement as HTMLElement).blur();
  };

  const isClassChecked = checkedClass === etimClass?.id;

  return (
    <div 
      key={etimClass?.id} 
      className={classNames(isClassChecked ? 'bg-cblue-500 text-white' : 'bg-cgray-100 text-cgray-500', 'flex items-center justify-between relative min-h-[40px]')}
      onClick={() => handleChangeClasses({ value: etimClass?.id, checked: !isClassChecked })}
    >
      <label
        htmlFor={`filter-${etimClass?.id}`}
        className="flex-grow ml-3 text-sm"
      >
        {etimClass?.localizedDescription}
      </label>
    </div>
            
  );
}
