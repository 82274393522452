import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../helpers/utils';

interface Props {
  page: number,
  hasNext: boolean,
  hasPrev: boolean,
  elidedPageRange: string[],
  onPageChange: (item: number) => void,
  omitBorder?: boolean,
  omitNumbers?: boolean,
}

export default function Pagination(props: Props) {
  const {
    page, hasNext, hasPrev, elidedPageRange, onPageChange, omitBorder, omitNumbers,
  } = props;
  const { t } = useTranslation();

  const handleClick = (item: number) => {
    if (!!item) {
      onPageChange(item);
    }
  };
    
  return (
    <nav
      className={classNames(omitBorder ? '' : 'border-t', 'border-transparent px-4 flex items-center justify-between sm:px-0')}
      aria-label="Pagination"
    >
      <div className="-mt-px w-0 flex-1 flex">
        {hasPrev && (
          <div
            role="button"
            tabIndex={0}
            onClick={() => handleClick(Number(page) - 1)}
            className={classNames(omitNumbers ? '' : 'border-b-2', 'border-transparent pt-4 pr-1 inline-flex items-center text-cblue-500 text-sm font-medium hover:border-cblue-200')}
          >
            <IconArrowNarrowLeft className="mr-3 h-5 w-5" aria-hidden="true" />
            <span>{t('Previous')}</span>
          </div>
        )}
      </div>

    {!omitNumbers && (
      <div className="hidden md:-mt-px md:flex">
        {elidedPageRange?.length > 1 && elidedPageRange.map((item, index) => (
          <div
            key={index}
            tabIndex={0}
            role="button"
            onClick={() => handleClick(Number(item))}
            className={classNames(
              Number(item) === page
                ? 'border-cblue-500 text-cblue-500 border-b-2 pt-4 px-4 inline-flex items-center text-sm font-medium'
                : 'border-transparent hover:border-cblue-200 border-b-2 pt-4 px-4 inline-flex items-center text-cgray-400 text-sm font-medium',
              '',
            )}
          >
            {item}
          </div>
        ))}
      </div>
    )}

    <div className="-mt-px w-0 flex-1 flex justify-end">
      {hasNext && (
        <div
          role="button"
          tabIndex={0}
          onClick={() => handleClick(Number(page) + 1)}
          className={classNames(omitNumbers ? '' : 'border-b-2', ' border-transparent pt-4 pr-1 inline-flex items-center text-cblue-500 text-sm font-medium hover:border-cblue-200')}
        >
          <span>{t('Next')}</span>
          <IconArrowNarrowRight className="ml-3 h-5 w-5 " aria-hidden="true" />
        </div>
      )}
    </div>
  </nav>
  );
}