import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import RODefaultProductDetails from '../../../../../quotation/list/row/details/RODefaultProductDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import PasteArrow from '../../../fields/PasteArrow';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
  isSearchResult: boolean;
}

function ProductItem(props: Props) {
  const { indentationWidth, item } = props;
  const [showDetails, setShowDetails] = useState(false);
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  return (
    <li
      className={classNames(styles.Wrapper, 'relative')}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
        className={classNames(styles.TreeItem, 'overflow-hidden')}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': '#d3d3d3',
            '--border-b-l-radius': '4px 4px',
            '--border-b-r-radius': '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className='flex justify-end'
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
        >
        </div>
        <div className='w-14 py-3.5 flex items-center justify-between'>
          <TreeItemIcon 
            item={item} 
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
        </div>
        <div className="w-16 ">{item.product?.unit}</div>
        <div className="w-20 ">{item.product?.supplierLabel}</div>
        <div className="w-28 ">{item.product?.externalId}</div>
        <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 40} placement="top">
          <div className="w-[600px] 3xl:w-[900px] 4xl:w-[1400px] truncate">{item?.product?.description}</div>
        </Tippy>
        <PasteArrow item={item} />
      </div>
      {showDetails && (
        <RODefaultProductDetails
          externalProductId={item.product?.externalId as ID}
          catalogId={item?.product?.catalogId as ID}
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
        />
      )}
    </li>
  );
}

export default ProductItem;
