import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { UPDATE_PRODUCT_SPECIFIC_SURCHARGE } from '../../../../../api/mutations/quotations/product';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames } from '../../../../../helpers/utils';

export interface Props {
  productId: ID,
  specificSurcharge: number,
  label?: boolean,
  quotationId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
}

interface FormValues {
  specificSurcharge: number,
  product: ID,
  quotation: ID,
}

export default function SpecificSurchargeInput(props: Props) {
  const { productId, specificSurcharge, quotationId, refetchQueries, label } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fieldName = 'specificSurcharge';

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .transform((_, transformedValue) => {
        if (typeof transformedValue === 'string') {
          const numberValue = parseFloat(transformedValue);
          if (isNaN(numberValue)) {
            return undefined; // return original string if it's not a valid number
          }
          // If it is a valid number, format it to two decimal places
          return parseFloat(numberValue.toFixed(2));
        }
        // If transformedValue is already a number, just return it
        return transformedValue;
      })
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    specificSurcharge: specificSurcharge,
    product: productId,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError } = useQuotationItemInputHandler({
    mutation: UPDATE_PRODUCT_SPECIFIC_SURCHARGE,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  return (
    <div
      className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'border-b relative pl-2 h-full flex items-center justify-center rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
    >
      {label && (
        <label
        className={classNames(isError ? 'text-cred-500' : 'text-cgray-400', 'absolute top-0 left-2 text-2xs cursor-text')}
        onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
        >
          {t('Specific Surcharge')}
        </label>
      )}
        <input
          name={fieldName}
          value={values[fieldName]}
          onChange={handleChange}
          className="p-0 w-full min-h-[38px] text-sm text-right border-none rounded-t ring-0 outline-none bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:ring-0"
          ref={inputRef}
          onFocus={() => setInputFocused(true)}
          onBlur={() => handleBlur()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
              (e.target as HTMLInputElement).blur();
            }
          }}
        />
        <span className="text-gray-400 text-sm px-1.5">%</span>
    </div>
  );
}
