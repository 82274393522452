import { IconCircleFilled } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string | number | null,
  isCurrent?: boolean | null
}

export default function ObsoletableValue(props: Props) {
  const { value, isCurrent } = props;
  const { t } = useTranslation();

  switch (isCurrent) {
    case true:
      return <div>{value}</div>;
      break;
    case false:
      return (
        <Tippy content={t('Value is obsolete')} placement="right" >
          <div className='flex'>
            <div>{value}</div>
            <IconCircleFilled className='w-2 h-2 text-cyellow-500 self-start' />
          </div>
        </Tippy>
      );
      break;
    default:
      return <></>;
      break;
  }
}
