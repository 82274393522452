import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TEMPLATE_SUGGESTIONS } from '../../../../../../api/queries/groupTemplates';
import { useAppDispatch } from '../../../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../../../layout';
import {
  setGroupItemIdForSuggestion,
  setIsGroupSuggestionOpened,
} from '../../../../../../redux/quotationSlice';

interface Props {
  itemId: ID
}


function GroupTemplatesLookup(props: Props) {
  const { itemId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // We lookup only for the first page
  const {
    data: suggestionsData,
    loading: suggestionsLoading,
  } = useQuery(TEMPLATE_SUGGESTIONS, {
    variables: {
      item: itemId as string,
      page: 1,
    },
  });

  const suggestionsResponse = suggestionsData?.templateSuggestions?.response;


  return (
      <div>
        {suggestionsLoading
          && <div
            className="cursor-default text-cgray-400 inline-flex items-center gap-x-1.5 px-1 py-1 text-sm font-semibold"
          >
            <LoadingIndicator color="cgray-400" size='sm' />
            {t('Looking for Group Template Suggestions')}
          </div>
        }
        {!suggestionsLoading && !suggestionsResponse?.totalObjectsQuantity
          && <div
            className="cursor-default text-cgray-400 inline-flex items-center gap-x-1.5 px-1 py-1 text-sm font-semibold"
          >
            {t('No Group Template suggestions found')}
          </div>
        }
        {!suggestionsLoading && !!suggestionsResponse?.totalObjectsQuantity
          &&
          <button
            type="button"
            onClick={() => {
              dispatch(setIsGroupSuggestionOpened(true));
              dispatch(setGroupItemIdForSuggestion(itemId as string));
            }}
            className="cursor-pointer inline-flex items-center gap-x-1.5 px-1 py-1 text-sm font-semibold text-cblue-500 hover:bg-cgray-200 focus:z-10"
          >
            {suggestionsResponse?.totalObjectsQuantity}
            {' '}
            {t('Group Template suggestions found')}
          </button>
        }
      </div>
  );
}

export default GroupTemplatesLookup;
