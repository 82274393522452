import { useEffect } from 'react';
import { SEARCH_UNIVERSAL_QUERY } from '../api/queries/searchRecords';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import Filter from '../components/search/Filter';
import SearchHeader from '../components/search/searchHeader/SearchHeader';
import SearchListDescriptionData from '../components/search/SearchListDescriptionData';
import SearchListEtimData from '../components/search/SearchListEtimData';
import SearchListQuotationData from '../components/search/SearchListQuotationData';
import SearchMultipleListWrapper from '../components/search/searchMultiple/list/SearchMultipleListWrapper';
import { SearchType } from '../constants';
import { useAppSelector, useAppDispatch } from '../helpers/reduxHooks';
import { Navbar } from '../layout';
import { setSearchType } from '../redux/searchSlice';

export default function SearchMultiple() {
  const searchType = useAppSelector(state => state.search.searchType);
  const dispatch = useAppDispatch();

  // Set Description as default search type on page mount.
  useEffect(() => {
    dispatch(setSearchType(SearchType.DESCRIPTION));
  }, [dispatch]);

  return (
    <div className="flex flex-wrap min-h-screen w-fit min-w-full">
      <div className="w-full">
        <Navbar />
        <div className="pt-5">
          <ReduxAlertWrapper />
          <main className="flex flex-col gap-4 ml-4 mr-4">
            <SearchHeader />
            {searchType === SearchType.ETIM
              &&
              <div className="flex gap-4 border-t-2 border-cblue-500">
                <Filter />
                <div className='w-full max-h-screen overflow-y-scroll'>
                  <SearchListEtimData
                    SearchListWrapper={SearchMultipleListWrapper}
                    searchRecordsQuery={SEARCH_UNIVERSAL_QUERY}
                  />
                </div>
              </div>}
            {searchType === SearchType.DESCRIPTION
              &&
              <div className='w-full'>
                <SearchListDescriptionData SearchListWrapper={SearchMultipleListWrapper} />
              </div>}
            {searchType === SearchType.QUOTATION
              &&
              <div className='w-full'>
                <SearchListQuotationData SearchListWrapper={SearchMultipleListWrapper} />
              </div>}
          </main>
        </div>
      </div>
    </div>
  );
}
