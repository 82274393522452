import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_SERVICE_TIME } from '../../../../../api/mutations/quotations/service';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames, convertMinutesToTimeLabel } from '../../../../../helpers/utils';

export interface Props {
  refetchQueries: InternalRefetchQueriesInclude,
  serviceTime: number,
  id: ID,
  label?: string,
}

interface FormValues {
  serviceTime: number | undefined,
  service: ID,
  quotation: ID,
}

export default function ServiceTimeInput(props: Props) {
  const { serviceTime, id, refetchQueries, label } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const { quotationId } = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const fieldName = 'serviceTime';

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .integer()
      .positive(t('Number must be positive'))
      .required(t('Required')),
  });

  const initialValues: FormValues = {
    serviceTime: serviceTime as number,
    service: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError  } = useQuotationItemInputHandler({
    mutation: UPDATE_SERVICE_TIME,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  const hours = Math.floor(values.manufacturingTime as number / 60);

  const inputClass = classNames(
    !inputFocused && hours > 99 ? 'text-xs' : 'text-sm',
    inputFocused && hours >= 16 ? 'text-xs' : 'text-sm',
    (inputFocused || isError) ? 'text-left pl-1' : 'text-center pl-0',
    (inputFocused && !isError) && 'border-cblue-500',
    isError && 'border-cred-500',
    'border-b w-full h-full flex items-center justify-end rounded-t bg-cgray-100 hover:bg-cgray-200 focus:outline-none focus:bg-cgray-200 group-hover:bg-cgray-200',
  );

  return (
    <div className="relative h-full flex items-center group">
      {label && (
        <label 
          htmlFor={fieldName} 
          className={classNames(isError ? 'text-cred-500' : 'text-cgray-400', 'absolute top-0 left-2 text-2xs cursor-text')}
          onClick={() => inputRef.current?.focus()}
        >
          {label}
        </label>
      )}
      <input
        name={fieldName}
        ref={inputRef}
        value={inputFocused || isError
          ? values[fieldName]
          : convertMinutesToTimeLabel(values[fieldName])
        }
        onChange={handleChange}
        className={inputClass}
        onFocus={() => setInputFocused(true)}
        onBlur={() => handleBlur()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
            (e.target as HTMLInputElement).blur();
          }
        }}
      />
      {(inputFocused || isError) && <div className={classNames(hours >= 16 ? 'text-xs' : 'text-sm', 'absolute h-full right-1 top-0 flex items-center')}>m</div>}
    </div>
  );
}
