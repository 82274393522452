import { useMutation } from '@apollo/client';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { LOGIN_MUTATION } from '../../api/mutations/users';
import { setAuthCookies } from '../../helpers/auth';
import { classNames } from '../../helpers/utils';
import { AuthLayout, LoadingIndicator } from '../../layout';
import { Input } from '../../layout/fields';

function Login() {
  const navigate = useNavigate();
  const [
    login,
    {
      data: loginData,
      loading: loginLoading,
      error: loginError,
    }] = useMutation(LOGIN_MUTATION);
  const { t } = useTranslation();

  const schema = yup.object({
    email: yup.string().trim().required(`${t('Email is Required')}`),
    password: yup.string().trim().required(`${t('Password is Required')}`),
  });

  const {
    handleSubmit,
    handleChange,
    touched,
    errors: formikErrors,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        ...values,
      };
      login({ variables });
    },
  });

  useEffect(() => {
    if (loginData) {
      if (loginData.tokenAuth && loginData.tokenAuth.token) {
        setAuthCookies(loginData.tokenAuth.token, loginData.tokenAuth.refreshToken);
        navigate('/projects/');
      }
    }
  }, [loginData, navigate]);

  const [passwordDisplayType, setPasswordDisplayType] = useState('password');

  const togglePasswordDisplay = () => {
    if (passwordDisplayType === 'password') {
      setPasswordDisplayType('text');
      return;
    }
    setPasswordDisplayType('password');
  };

  return (
    <AuthLayout>
      {loginError && <div className="bg-red-100 text-cred-500 rounded-sm p-4 text-sm mb-7 shadow-sm" role="alert">{loginError.message}</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <Input
            id="email"
            name="email"
            type="text"
            autoComplete="email"
            //@ts-ignore
            errorMessage={touched.email && formikErrors.email}
            label={t('E-mail')}
            onChange={handleChange}
            placeholder={t('E-mail')}
          />
        </div>

        <div className="relative space-y-1">
          <Input
            id="password"
            name="password"
            type={passwordDisplayType}
            autoComplete="password"
            //@ts-ignore
            errorMessage={touched.password && formikErrors.password}
            label={t('Password')}
            onChange={handleChange}
            placeholder={t('Password')}
          />
          <div
            className="absolute inset-y-0 right-0 mr-2 flex items-center cursor-pointer text-cblue-500 hover:text-cblue-700"
            aria-hidden="true"
          >
            {passwordDisplayType === 'password' ? (
              <IconEye
                className="h-6 w-6"
                onClick={togglePasswordDisplay}
              />
            ) : (
              <IconEyeOff
                className="h-6 w-6"
                onClick={togglePasswordDisplay}
              />
            )}
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={loginLoading}
            className={classNames(loginLoading 
              ? 'bg-cgray-300 text-cgray-300' 
              : 'bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100', 
            'relative w-full flex justify-center py-2 px-4 border border-transparent rounded text-base font-medium uppercase text-white')}
          >
            {loginLoading 
              ? <div className='w-6 h-6'><LoadingIndicator color="white" className="flex justify-center items-center absolute w-full h-full top-0 right-0" /></div>
              : t('Log in')
            }
          </button>
        </div>
      </form>
    </AuthLayout>
  );
}

export default Login;
