import { gql } from '../../__generated__/gql';


export const TOGGLE_ETIM_CLASS_FAVOURITE_STATUS = gql(`
  mutation toggleEtimClassFavouriteStatus(
    $etimClass: ID!
  ) 
  {
    toggleEtimClassFavouriteStatus(
      input: {
        etimClass: $etimClass
      }
    ) 
    {
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const SAVE_ETIM_FEATURES_PRESET = gql(`
  mutation SaveFeaturesPreset(
    $etimClass: ID!
    $searchFeatures: [SearchFeature]!
  ) 
  {
    saveEtimFeaturesPreset(
      input: {
        etimClass: $etimClass
        searchFeatures: $searchFeatures
      }
    ) 
    {
      response
      errors{
        field
        messages
      }
    }
  }
`);


export const DUPLICATE_FEATURE_PRESET = gql(`
  mutation duplicateFeaturePreset(
    $group: ID!
    $featurePreset: ID!
  ) 
  {
    duplicateFeaturePreset(
      input: {
        group: $group
        featurePreset: $featurePreset
      }
    )
    {
      response
      errors{
        field
        messages
      }
    }
  }
`);