import { arrayToTree, Item, TreeItem } from 'performant-array-to-tree';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchResponseObjectType } from '../../../../__generated__/graphql';
import { PaginationData } from '../../../../helpers/PaginationUtils';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../../../layout';
import { setLastSearchResults, setSearchListPage } from '../../../../redux/searchSlice';
import { FlattenedItem } from '../../../shared/dnd/types';
import SearchListHeader from '../../SearchListHeader';
import SearchList from './SearchList';

interface Props {
  objectsData: SearchResponseObjectType[]
  isLoading: boolean
  paginationData?: PaginationData
  totalObjectsQuantity: number | undefined
}

export default function SearchMultipleListWrapper(props: Props) {
  const { objectsData, isLoading, paginationData, totalObjectsQuantity } = props;
  const pinnedItems = useAppSelector(state => state.search.pinnedItems);
  const dispatch = useAppDispatch(); 
  const [itemsTree, setItemsTree] = useState<TreeItem[]>([]);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const { t } = useTranslation();

  useEffect(() => {
    if (objectsData?.length) {
      setItemsTree(arrayToTree(objectsData as Item[], { dataField: null }));
    } 
  }, [objectsData]);
  
  useEffect(() => {
    // Filter out duplicates
    const filteredData = itemsTree.filter(
      treeItem => !pinnedItems.some(
        pinnedItem => pinnedItem.id === treeItem.id,
      ),
    );
    dispatch(setLastSearchResults(filteredData as FlattenedItem[]));
  }, [dispatch, itemsTree, pinnedItems]);
  
  const searchHelp = useAppSelector(state => state.search.searchHelp);
  const indentationWidth = 50;
  
  useEffect(() => {
    dispatch(setSearchListPage(1));
  }, [dispatch, searchInputValue]);

  return (
    <>
      <SearchListHeader indentationWidth={indentationWidth} />
      <div className='mb-4'>
        {
          <SearchList
            indentationWidth={indentationWidth}
            defaultItems={pinnedItems as FlattenedItem[]}
            isSearchResult={false}  
          />
        }
      </div>

      {objectsData && (
        <>
          {!totalObjectsQuantity
            ? (
            <div className="w-full h-screen pb-64 flex items-center justify-center text-3xl text-cgray-400">
              {t('No results found')}
            </div>
            ) : (
              <SearchList
                indentationWidth={indentationWidth}
                defaultItems={itemsTree as FlattenedItem[]}
                isSearchResult={true}  
              />
            )
          }
          {
            paginationData &&
            <div className="h-14">
              <Pagination
                page={paginationData.page}
                hasNext={paginationData.hasNext}
                hasPrev={paginationData.hasPrev}
                elidedPageRange={paginationData.elidedPageRange}
                onPageChange={(e:number) => dispatch(setSearchListPage(e))}
              />
            </div>
          }
        </>
      )}
      {!objectsData && !isLoading && (
      <div className="text-3xl w-full h-96 flex items-center justify-center text-cgray-400 relative">
      <div
        className={`transition ease-out duration-100 absolute inset-0 flex items-center justify-center ${!searchHelp ? 'opacity-100' : 'opacity-0'}`}
      >
        {t('Search results will be displayed here')}
      </div>
      <div
        className={`transition ease-in delay-500 duration-100 absolute inset-0 flex items-center justify-center ${searchHelp ? 'opacity-100' : 'opacity-0'}`}
      >
        {t('Please hit Enter to start Search')}
      </div>
    </div>
    
      )}
      {!!isLoading && (
        <LoadingIndicator className="w-full h-96 flex justify-center items-center" />
      )}
    </>
  );
}
