import type { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { getChildCount } from '../../../../../helpers/dnd/utilities';
import type { FlattenedItem } from '../../../../shared/dnd/types';
import { SearchListItem } from './SearchListItem';


interface Props {
  items: FlattenedItem[];
  handleCollapse: (id: UniqueIdentifier[]) => void,
  indentationWidth: number;
}

export default function SearchList(props: Props) {
  const {
    items, handleCollapse, indentationWidth,
  } = props;

  return (
    <SortableContext
      items={[]}
      >
        {items.map((item) => (
          <SearchListItem
            indentationWidth={indentationWidth}
            key={item.id}
            item={item}
            handleCollapse={handleCollapse}
            childCount={getChildCount(items, item.id) + 1}
          />
        ))}
    </SortableContext>
  );
}
