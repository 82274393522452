import { gql } from '../../__generated__/gql';

export const CUSTOMERS_QUERY = gql(`
  query Customers($searchInput: String){
    customers(
        searchInput: $searchInput
      ){
        response{
          id
          name
        }
    }
  }
`);

export const INLINE_CUSTOMERS_QUERY = gql(`
  query inlineCustomers($searchInput: String, $skip: Int){
    inlineCustomers(
      searchInput: $searchInput
      skip: $skip
    ){
      response{
        hasNext
        objects{
          id
          name
          label
        }
      }
      errors{
        field
        messages
      }
    }
  }
`);
