import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { language } from './config';
import { de, en } from './locales';

const resources = {
  en,
  de,
};
const debug = true;


i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: 'de',
    debug,
  });

export default i18n;
