import {
  IconBackspace,
  IconCopy,
  IconFileTypeXls,
  IconIdOff,
  IconListDetails,
  IconScissors,
  IconTrash,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import Cookies from 'js-cookie';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../../../../config';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { LoadingIndicator } from '../../../../../layout';
import { 
  IconGroupBelow, 
  IconGroupInside, 
  IconItemBelow, 
  IconItemInside, 
  IconServiceBelow, 
  IconServiceInside, 
  IconProductBelow, 
  IconProductInside,
} from '../../../../../layout/icons';
import { discardAlert } from '../../../../../redux/alertSlice';
import {
  openCreateGroupModal,
  openCreateServiceModal,
  setCopiedItem,
  setCreateNewItemInside,
  setIsAnyGroupBeingExported,
  setIsCreateItemOverlayOpen,
  setItemToCut,
  setItemToSinglePasteBelow,
  setNewProductOrder,
  setShowDeleteModal,
  unsetCopiedItem,
  unsetItemToCut,
} from '../../../../../redux/quotationSlice';
import { setDestinationProjectId, setDestinationQuotationId, setRedirectToQuotation } from '../../../../../redux/searchSlice';
import { FlattenedItem } from '../../../../shared/dnd/types';
import { CreateGroupTemplate, PasteQuotationItem } from '../../actions';
import ChangeOrderArrows from './ChangeOrderArrows';

interface Props {
  item: FlattenedItem;
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
  isItemHoveredId: string,
  setIsItemHoveredId: (id: string | null) => void,
  quotationLength?: number,
  initialIndex?: number,
}

export default function HoveredGroupContextMenu(props: Props) {
  const { item, showDetails, setShowDetails, isItemHoveredId, setIsItemHoveredId, quotationLength, initialIndex } = props;
  const { projectId, quotationId } = useParams();
  const copiedItem = useAppSelector(state => state.quotation.copiedItem);
  const itemToCut = useAppSelector(state => state.quotation.itemToCut);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const isQuotationReadOnly = useAppSelector(state => state.quotation.isQuotationReadOnly);
  const isAnyGroupBeingExported = useAppSelector(state => state.quotation.isAnyGroupBeingExported);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showDetailsHandleClick = (isShow: boolean) => {
    if (setShowDetails) setShowDetails(!isShow);
  };

  const copyQuotationItemHandleClick = () => {
    dispatch(setCopiedItem(item));
    dispatch(unsetItemToCut());
  };

  const cutQuotationItemHandleClick = () => {
    dispatch(setItemToCut(item));
    dispatch(unsetCopiedItem());
  };

  const handleClearSelection = () => {
    dispatch(unsetCopiedItem());
    dispatch(unsetItemToCut());
  };

  const handleAddItem = () => {
    if (projectId && quotationId) {
      dispatch(setRedirectToQuotation(true));
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(item));
      navigate('/search/single/');
    }
  };
  const createProduct = () => {
    if (projectId && quotationId) {
      dispatch(discardAlert());
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(item));
      dispatch(setIsCreateItemOverlayOpen(true));
    }
  };

  const handleExportToXLSX = async () => {
    try {
      dispatch(setIsAnyGroupBeingExported(true));
  
      const response = await fetch(`${config.GENERATE_XLSX_URL}${item.id}/`, {
        method: 'GET',
        headers: {
          'Authorization': `JWT ${Cookies.get('jwt_token')}`,
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      dispatch(setIsAnyGroupBeingExported(false));
      close();
      
      const filename = `${item.group?.description}.xlsx`;
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error generating XLSX:', error);
    }
  };

  const hoveredStyles = () => {
    if (copiedItem && copiedItem.id === item.id) {
      return 'bg-cblue-300';
    }
    if (selectedItem && selectedItem.id === item.id) {
      return 'bg-cblue-200';
    }
    return 'bg-white';
  };

  return (
    <div 
      className={`absolute right-0 inset-y-0 mr-4 flex items-center justify-center gap-3 ${hoveredStyles()}`}
      onMouseLeave={() => {if (isItemHoveredId !== item.id) setIsItemHoveredId(null);}}
    >
      {!isQuotationReadOnly && <ChangeOrderArrows
        item={item}
        quotationLength={quotationLength as number}
        quotationId={quotationId as string}
        initialIndex={initialIndex as number}
      />}
      {showDetails ? (
        <>
          <Tippy content={t('Hide details')} placement="top" >
            <IconIdOff className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
      ) : (
        <>
          <Tippy content={t('Show details')} placement="top" >
            <IconListDetails className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
      )}
      { isAnyGroupBeingExported 
        ? <LoadingIndicator color="cgray-900" className="flex justify-center items-center w-6 h-6" />
        : (
          <Tippy content={t('Export as Electronic Sheet')} placement="top" >
            <div>
              <IconFileTypeXls 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  handleExportToXLSX();
                }}
              />
            </div>
          </Tippy>
        )
      }
      {!isQuotationReadOnly && (
        <>
          {item.group && !item.group?.representsTemplate && (
            <Tippy content={t('Create Group Template')} placement="top" >
              <div>
                <CreateGroupTemplate item={item} />
              </div>
          </Tippy>
          )}
          <Tippy content={t('Create Group Below')} placement="top" >
            <div>
              <IconGroupBelow 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(false));
                  dispatch(openCreateGroupModal({ targetOrder: (item?.order as number) + 1 }));
                }}
              />
            </div>
          </Tippy>
          <Tippy content={t('Create Group Inside')} placement="top" >
            <div>
              <IconGroupInside 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(true));
                  dispatch(openCreateGroupModal({ targetOrder: item?.children.length }));
                }}
              />
            </div>
          </Tippy>
          {item.parentId &&
            <Tippy content={t('Create Service Below')} placement="top" >
              <div>
                <IconServiceBelow 
                  className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                  onClick={() =>{
                    dispatch(discardAlert());
                    dispatch(setCreateNewItemInside(false));
                    dispatch(openCreateServiceModal( { targetOrder: (item?.order as number) + 1 }));
                  }}
                /> 
              </div>
            </Tippy>
          }
          <Tippy content={t('Create Service Inside')} placement="top" >
            <div>
              <IconServiceInside 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(true));
                  dispatch(openCreateServiceModal({ targetOrder: item?.children.length }));
                }}
              /> 
            </div>
          </Tippy>
          <Tippy content={t('Add Item Below')} placement="top" >
            <div>
              <IconItemBelow 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(false));
                  handleAddItem();
                }}
            />
            </div>
          </Tippy>
          <Tippy content={t('Add Item Inside')} placement="top" >
            <div>
              <IconItemInside 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(discardAlert());
                  dispatch(setCreateNewItemInside(true));
                  handleAddItem();
                }}
              />
            </div>
          </Tippy>
          <Tippy content={t('Create Product Inside')} placement="top" >
            <div>
              <IconProductInside 
                className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                onClick={() => {
                  dispatch(setCreateNewItemInside(true));
                  dispatch(setNewProductOrder(item?.children.length));
                  createProduct();
                }}
              />
            </div>
          </Tippy>
          {item.parentId &&  
            <Tippy content={t('Create Product Below')} placement="top" >
              <div>
                <IconProductBelow 
                  className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200"
                  onClick={() => {
                    dispatch(setItemToSinglePasteBelow(item));
                    dispatch(setCreateNewItemInside(false));
                    dispatch(setNewProductOrder((item?.order as number) + 1));
                    createProduct();
                  }}
                />
              </div>
            </Tippy>
          }
          {copiedItem?.id !== item.id && (
            <>
              <Tippy content={t('Copy Item')} placement="top" >
                <IconCopy className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={copyQuotationItemHandleClick} />
              </Tippy>
            </>
          ) }
          {itemToCut?.id !== item.id && (
            <>
              <Tippy content={t('Cut Item')} placement="top" >
                <IconScissors className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={cutQuotationItemHandleClick} />
              </Tippy>
            </>
          ) }
          {(copiedItem || itemToCut) && (
            <>
              <Tippy content={t('Paste below')} placement="top" >
                <div>
                  <PasteQuotationItem item={item} />
                </div>
              </Tippy>
            </>
          ) }
          {
            ((copiedItem && copiedItem.id === item.id) || 
            (itemToCut && itemToCut.id === item.id)) 
            && (
            <>
              <Tippy content={t('Clear selection')} placement="top" >
                <IconBackspace className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={handleClearSelection} />
              </Tippy>
            </>
            )
          }
          <Tippy content={t('Remove Item')} placement="top" >
            <div>
              <IconTrash className="h-5 w-5 cursor-pointer hover:bg-cblue-200" onClick={() => dispatch(setShowDeleteModal(true))} />
            </div>
          </Tippy>
        </>
      )}
    </div>
  );
}