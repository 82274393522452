import { useTranslation } from 'react-i18next';
import { SearchResultsType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import SelectInput from '../../../layout/fields/SelectInput';
import { setSearchResultsType } from '../../../redux/searchSlice';

export default function SearchResultsTypeSelect() {
  const dispatch = useAppDispatch();
  const searchResultsType = useAppSelector(state => state.search.searchResultsType);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const { t } = useTranslation();

  const options: SelectOption[] = [
    { value: SearchResultsType.PRODUCTS, label: t('Products') },
    { value: SearchResultsType.GROUPS, label: t('Groups') },
  ];

  return (
    <SelectInput
      options={options}
      placeholder={t('Search for')}
      value={options.find(option => option.value === searchResultsType)}
      onChange={(e: SelectOption) => dispatch(setSearchResultsType(e.value as SearchResultsType))}
      isDisabled={!!QuotationContext?.searchProjectId}
    />
  );
}
