import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { QuotationVersionTypeChoices } from '../../../__generated__/graphql';
import { QUOTATIONS_QUERY } from '../../../api/queries/quotations/quotation';
import { SearchType } from '../../../constants';
import { useAppSelector } from '../../../helpers/reduxHooks';
import SelectInput from '../../../layout/fields/SelectInput';

interface Props {
  onChange: (selectedId: string) => void;
  projectId: string;
  quotationId?: string;
  isDisabled?: boolean;
  hideROQuotations?: boolean;
  isClearable?: boolean;
}

export default function SelectQuotation(props: Props) {
  const { onChange, projectId, quotationId, isDisabled, hideROQuotations, isClearable } = props;
  const { t } = useTranslation();
  const searchType = useAppSelector(state => state.search.searchType);
  const searchQuotationId = useAppSelector(state => state.search.QuotationContext.searchQuotationId);
  const location = useLocation();
  const {
    data: quotationsData,
    refetch: quotationsRefetch,
  } = useQuery(QUOTATIONS_QUERY, {
    variables: {
      project: projectId as string,
    },
    skip: !projectId,
  });

  let options: SelectOption[];

  const initialQuotations = quotationsData && quotationsData.quotations && !!quotationsData.quotations.response?.length ? quotationsData.quotations.response : [];

  if (hideROQuotations) {
    const editableQuotations = initialQuotations.filter(item => item?.versionType !== QuotationVersionTypeChoices.Readonly);
    options = editableQuotations.map(item => ({ value: item?.id, label: item?.name } as SelectOption));
  } else {
    options = initialQuotations.map(item => ({ value: item?.id, label: item?.name } as SelectOption));
  }

  // If "Search by Quotation" type was used, exclude the Source Quotation from Destination Quotations options, on SearchPaste page.
  let selectOptions = options;
  if (searchType === SearchType.QUOTATION && location.pathname.includes('paste')) {
    selectOptions = selectOptions.filter(option => option.value !== searchQuotationId);
  }

  useEffect(() => {
    if (quotationId) {
      quotationsRefetch({
        project: projectId as string,
      });
    }
  }, [quotationId, quotationsRefetch, projectId]);

  return (
    <SelectInput
      value={quotationId ? selectOptions.find(item => item.value === quotationId) : undefined}
      options={selectOptions}
      placeholder={t('Quotation')}
      onChange={(e) => onChange(e?.value ? e.value as string : '')}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  );
}
