import { IconSearch, IconX } from '@tabler/icons-react';
import React, { useRef, useState } from 'react';
import { classNames } from '../../../helpers/utils';

interface Props {
  placeholder: string,
  onChange: (inputedValues: string) => void,
  value?: string,
}

function ProjectSearchInput(props: Props) {
  const {
    onChange, value, placeholder,
  } = props;
  
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const labelHandler = (event: React.MouseEvent<HTMLLabelElement>) => {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLLabelElement).style.cursor = 'text';
    setInputHovered(true);
  };

  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm flex-grow">
        <input
          value={value}
          onChange={(e)=>{onChange(e.target.value);}}
          className={classNames(
            inputHovered ? 'bg-cgray-200 ' : 'bg-cgray-100', 
            'peer pt-2 pb-2 pl-3 border-b placeholder:text-cgray-400 block w-full bg-cgray-100 text-cgray-600 sm:text-sm sm:leading-7 hover:bg-cgray-200 focus:placeholder:text-transparent focus:border-cblue-500 focus:ring-0 focus:bg-cgray-200 focus:outline-none',
          )}
          //@ts-ignore
          label={placeholder}
          placeholder={placeholder || ''}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          ref={inputRef}
        />
        <label
          htmlFor={placeholder}
          onMouseEnter={(e) => labelHandler(e)}
          onMouseLeave={() => setInputHovered(false)}
          //@ts-ignore
          onClick={() => { inputRef.current.focus(); }}
          className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute -top-1 left-3 text-cblue-500 text-2xs sm:leading-7 peer-placeholder-shown:text-sm peer-placeholder-shown:text-transparent peer-placeholder-shown:top-3 peer-placeholder-shown:leading-7 transition-all peer-focus:-top-1 peer-focus:text-cblue-500 peer-focus:text-2xs')}
        >
          {placeholder}
        </label>
        <div
          className='flex z-10 absolute inset-y-0 right-0 mr-3 items-center'
          aria-hidden="true"
          onClick={() => setInputFocused(true)} 
        >
          {
            value 
              ?
              <IconX 
                className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'h-6 w-6 cursor-pointer')} 
                aria-hidden="true" 
                onClick={()=>onChange('')} 
              />
              :
              <IconSearch 
                className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'h-6 w-6 cursor-pointer')} 
                aria-hidden="true" 
                onClick={() => {
                  setInputFocused(true);
                  inputRef.current?.focus();
                }} 
              />
          }
        </div>
      </div>
    </>
  );
  return input;
}

export default ProjectSearchInput;