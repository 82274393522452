import { useTranslation } from 'react-i18next';
import { ProjectType } from '../../__generated__/graphql';

interface Props {
  project: ProjectType,
  handleClick: (project: ProjectType) => void,
}

export default function ProjectCard(props: Props) {
  const { project, handleClick  } = props;
  const { t } = useTranslation();

  return (
    <div 
      className="flex w-full items-center justify-between space-x-6 p-6 group"
      onClick={() => handleClick(project)}
    >
      <div className="flex-1 truncate">
        <div className="flex items-center space-x-3">
          <h3 className="truncate text-sm font-medium text-cgray-600">{project?.nameLabel}</h3>
        </div>
        <p className="mt-1 truncate text-sm text-cgray-400">{project?.quotations?.length} {project?.quotations?.length === 1 ? `${t('quotation')}` : `${t('quotations')}`}</p>
      </div>
    </div>
  );
}
