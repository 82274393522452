import { Transition } from '@headlessui/react';
import { IconCircleCheck, IconInnerShadowLeft, IconCircleX } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { ReactComponent as IconRobot } from '../assets/icons/Robot.svg';

interface Props {
  isLoading?: boolean,
  isError?: boolean,
}

const ICON_STATES = {
  ROBOT: 'ROBOT',
  SPINNER: 'SPINNER',
  CHECK: 'CHECK',
  FAILURE: 'FAILURE',
};

function RobotIndicator(props: Props) {
  const { isLoading, isError } = props;
  const [iconState, setIconState] = useState(ICON_STATES.ROBOT);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    }

    if (isLoading) {
      setIconState(ICON_STATES.SPINNER);
    } 

    if (!isLoading && !initialRender) {
      if (!isError) {
        setIconState(ICON_STATES.CHECK);
      } else {
        setIconState(ICON_STATES.FAILURE);
      }
      setTimeout(() => setIconState(ICON_STATES.ROBOT), 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isError]);

  return (
    <div className="w-8 h-8 relative">
      <Transition 
        show={iconState === ICON_STATES.ROBOT}
        as="div"
        enter="transition ease-in-out duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <IconRobot className="w-8 h-8 fill-cblue-500 absolute top-0 left-0" />
      </Transition>

      <Transition 
        show={iconState === ICON_STATES.SPINNER}
        as="div"
        enter="transition ease-in-out duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
       >
          <IconInnerShadowLeft className="w-8 h-8 text-cblue-500 animate-spin absolute top-0 left-0" />
      </Transition>

      <Transition 
        show={iconState === ICON_STATES.CHECK}
        as="div"
        enter="transition ease-in-out duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <IconCircleCheck className="w-8 h-8 text-cblue-500 absolute top-0 left-0" />
      </Transition>

      <Transition 
        show={iconState === ICON_STATES.FAILURE}
        as="div"
        enter="transition ease-in-out duration-500"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <IconCircleX className="w-8 h-8 text-cred-500 absolute top-0 left-0" />
      </Transition>
    </div>
  );
}
export default RobotIndicator;