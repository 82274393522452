import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error404() {
  const { t } = useTranslation();

  return (
    <ErrorPageTempate
      label={t('Error: Not Found.')}
      code={404}
    />
  );
}

export default Error404;
