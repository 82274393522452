import { useAppDispatch, useAppSelector } from '../helpers/reduxHooks';
import { ReduxAlert } from '../layout/alerts';
import { discardAlert } from '../redux/alertSlice';

export default function ReduxAlertWrapper() {
  const alert = useAppSelector((state) => state.alert.alert);
  const dispatch = useAppDispatch();

  if (alert) {
    return (
      <ReduxAlert
        key={alert.id}
        alertType={alert.alertType}
        isOpen={alert.show}
        messages={alert.messages}
        toggle={() => {
          dispatch(
            discardAlert(),
          );
        }}
      />
    );
  }
  return (<></>);
}
