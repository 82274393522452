import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_SERVICE_DESCRIPTION } from '../../../../../api/mutations/quotations/service';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames } from '../../../../../helpers/utils';

interface Props {
  label?: boolean,
  refetchQueries: InternalRefetchQueriesInclude,
  description: string,
  id: ID,
}

interface FormValues {
  description: string,
  service: ID,
  quotation: ID,
}

export default function DescriptionInput(props: Props) {
  const { description, id, label, refetchQueries } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();

  const fieldName = 'description';

  const validationSchema = yup.object({
    [fieldName]: yup.string()
      .required(t('Required')),
  });

  const initialValues: FormValues = {
    description: description,
    service: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur } = useQuotationItemInputHandler({
    mutation: UPDATE_SERVICE_DESCRIPTION,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  return (
    <div
      className={classNames(inputFocused ? 'bg-cgray-200 border-b border-cblue-500' : '', 'pl-2 py-px flex flex-col rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
    >
      {label && (
        <label
          className="text-2xs text-cgray-400 cursor-text"
          onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
        >
          {t('Description')}
        </label>
      )}
      <input
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        className={classNames(!label ? 'min-h-[40px] ' : '', 'w-full border-none rounded-t ring-0 outline-none bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200')}
        ref={inputRef}
        onFocus={() => setInputFocused(true)}
        onBlur={() => handleBlur()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
            (e.target as HTMLInputElement).blur();
          }
        }}
      />
    </div>
  );
}
