import React, { useRef, useState } from 'react';
import { classNames } from '../../helpers/utils';

interface Props {
  id: string,
  name: string,
  type: string,
  autoComplete: string,
  label: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  errorMessage: string,
  hasSuffix: boolean,
  suffixValue: string,
  disabled: boolean,
  value?: string | number,
  defaultValue?: string | number,
  placeholder: string,
}

function Input(props: Props) {
  const {
    id, name, type, autoComplete, label, onChange, errorMessage,
    hasSuffix, suffixValue, value, defaultValue, disabled, placeholder,
  } = props;
  const hasError = !!errorMessage || false;
  
  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const inputRef = useRef(null);

  const labelHandler = (event: React.MouseEvent<HTMLLabelElement>) => {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLLabelElement).style.cursor = 'text';
    setInputHovered(true);
  };

  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          onChange={onChange}
          aria-invalid={hasError}
          aria-describedby={id}
          disabled={disabled || undefined}
          className={classNames(
            inputHovered ? 'bg-cgray-200' : 'bg-cgray-100', 
            'peer pt-2 pb-0 placeholder:text-cgray-400 block w-full border-0 border-b-[1px] border-transparent bg-cgray-100 text-cgray-600 sm:text-sm sm:leading-7 hover:bg-cgray-200 focus:placeholder:text-transparent focus:border-0 focus:border-b-[1px] focus:border-cblue-500 focus:ring-0 focus:bg-cgray-200',
          )}
          //@ts-ignore
          label={label}
          placeholder={placeholder || ''}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          ref={inputRef}
        />
        <label
          htmlFor={label}
          onMouseEnter={(e) => labelHandler(e)}
          onMouseLeave={() => setInputHovered(false)}
          //@ts-ignore
          onClick={() => { inputRef.current.focus(); }}
          className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute -top-1 left-3 text-cblue-500 text-2xs sm:leading-7 peer-placeholder-shown:text-sm peer-placeholder-shown:text-transparent peer-placeholder-shown:top-3 peer-placeholder-shown:leading-7 transition-all peer-focus:-top-1 peer-focus:text-cblue-500 peer-focus:text-2xs label')}
        >
          {label}
        </label>
        {hasSuffix && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-cgray-1000 sm:text-sm" id="price-currency">
              {suffixValue}
            </span>
          </div>
        )}
      </div>
      {hasError && (
        <p className="mt-2 text-sm text-red-500" id={id}>
          {errorMessage}
        </p>
      )}
    </>
  );
  return input;
}

export default Input;
