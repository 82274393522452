
import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { QuotationNoteType, UserType } from '../../../__generated__/graphql';
import { DELETE_QUOTATION_NOTE_MUTATION, QUOTATION_NOTE_MUTATION } from '../../../api/mutations/quotations/note';
import { classNames } from '../../../helpers/utils';
import DeleteNoteInline from './DeleteNoteInline';


interface Props {
  user: UserType;
  note: QuotationNoteType
  quotationId: string;
  handleClose: () => void;
  refetchQueries: InternalRefetchQueriesInclude
}

const UpdateNoteForm = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  const { user, quotationId, note, handleClose, refetchQueries } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { t } = useTranslation();

  const [noteMutation] = useMutation(QUOTATION_NOTE_MUTATION, {
    refetchQueries: refetchQueries,
  });

  const [deleteNoteMutation] = useMutation(DELETE_QUOTATION_NOTE_MUTATION, {
    refetchQueries: refetchQueries,
  });

  const schema = yup.object({
    body: yup.string().required('Requried'),
  });

  const {
    handleSubmit,
    handleChange,
    values: formikValues,
    resetForm,
  } = useFormik({
    initialValues: {
      body: note.body,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const variables = {
        quotation: quotationId,
        body: values.body,
        id: note.id,
        author: user.id,
      };
      noteMutation({
        variables,
      });
      resetForm();
      handleClose();
    },
  });

  useEffect(() => {
    if (formikValues.body.length === 0) {
      setShowDeleteModal(true);
    }
  }, [formikValues.body, deleteNoteMutation]);


  return (
    <div className={classNames(showDeleteModal ? 'relative flex-auto' : 'mt-6 w-full flex gap-x-3')}>

      {showDeleteModal
        ?
        <DeleteNoteInline
          noteId={note.id}
          refetchQueries={refetchQueries}
          handleCancel={() => {resetForm(); setShowDeleteModal(false);}}
          handleClose={() => setShowDeleteModal(false)} />
        : (
          <form className="relative flex-auto">
            <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-cgray-300 focus-within:ring-2 focus-within:ring-cblue-500">
              <textarea
                ref={ref}
                rows={2}
                name="body"
                className="block w-full resize-none border-0 bg-transparent py-1.5 text-cgray-900 placeholder:text-cgray-400 focus:ring-0 sm:text-sm sm:leading-6"
                placeholder={t('Add your note here')}
                value={formikValues.body}
                onChange={handleChange}
                onBlur={() => handleSubmit()} />
            </div>
          </form>
        )
      }
    </div>

  );
});

export default UpdateNoteForm;
