import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TOGGLE_GROUP_MANUAL_PRICE } from '../../../../../../../api/mutations/quotations/group';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import { Switch } from '../../../../../../../layout/fields';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';

interface Props {
  hasManualPrice: boolean,
  groupId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
}

export default function ToggleManualPriceForm(props: Props) {
  const { hasManualPrice, groupId, refetchQueries } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [
    setManualPriceMutation,
  ] = useMutation(TOGGLE_GROUP_MANUAL_PRICE, {
    refetchQueries: refetchQueries,
    onCompleted: () => { dispatch(setIsLoadingUpdate(false)); },
  });

  const handleChange = () => {
    dispatch(setIsLoadingUpdate(true));
    setManualPriceMutation({
      variables: {
        group: groupId as string,
      },
    });
  };


  return (
    <div className="flex items-center">
      <Switch
        className='flex items-center gap-2 shrink-0'
        value={hasManualPrice}
        onChange={handleChange}
        label={t('Set manual Price?')}
      />
    </div>
  );
}
