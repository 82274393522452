import { CreateProjectForm } from '../components/createProject';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { Navbar } from '../layout';


export default function CreateProject() {
  return (
    <div className="flex flex-wrap min-h-screen">
      <div className="w-full">
        <Navbar />
        <div className="pt-1">
          <ReduxAlertWrapper />
          <main>
            <CreateProjectForm />
          </main>
        </div>
      </div>
    </div>
  );
}
