import { IconArrowDown, IconCornerDownRight } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { useAppSelector, useAppDispatch } from '../../../../helpers/reduxHooks';
import { setPasteSearchResultsBelowSelectedItem } from '../../../../redux/quotationSlice';

export default function PasteArrowIndicator() {
  const pasteSearchResultsBelowSelectedItem = useAppSelector(state => state.quotation.pasteSearchResultsBelowSelectedItem);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const content = pasteSearchResultsBelowSelectedItem ? t('Item will be pasted below selected item') : t('Item will be pasted inside selected group');

  return (
    <div className='absolute top-3.5 left-6'>
      <Tippy content={content}>
        {pasteSearchResultsBelowSelectedItem && selectedItem?.itemType === ItemTypeChoices.Group
          ? <IconArrowDown 
              className="text-cblue-500 cursor-pointer" 
              onClick={(e)=>{
                e.stopPropagation();
                dispatch(setPasteSearchResultsBelowSelectedItem(false));
              }} 
            /> 
          : (pasteSearchResultsBelowSelectedItem && selectedItem?.itemType !== ItemTypeChoices.Group) 
            ?
            <IconArrowDown className="text-cgray-500" />
            : 
            <IconCornerDownRight 
              className="text-cblue-500 cursor-pointer" 
              onClick={(e)=>{
                e.stopPropagation();
                dispatch(setPasteSearchResultsBelowSelectedItem(true));
              }} 
            />
        }
      </Tippy>
    </div>

  );


}
