import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { classNames } from '../../../../../../helpers/utils';
import HoveredContextMenu from '../../../../../search/searchMultiple/list/HoveredContextMenu';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import GroupDetailsReadonly from '../../../../list/row/details/groupDetails/ROGroupDetails';

interface Props {
  collapsed?: boolean;
  item: FlattenedItem;
  indentationWidth: number;
  isCollapsable?: boolean;
  deepestUncollapsedLevel: number;
}

export default function GroupItem(props: Props) {
  const { indentationWidth, collapsed, isCollapsable, item, deepestUncollapsedLevel } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
      onMouseEnter={() => setIsItemHovered(item.id.toString())}
      onMouseLeave={() => setIsItemHovered(null)}
    >
      <div
        className={classNames(styles.TreeItem, 'bg-white', 'relative')}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
            '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
            '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className={classNames('flex shrink-0', isCollapsable ? 'justify-between' : 'justify-end')}
          style={{ width: `${getItemWidth(deepestUncollapsedLevel, indentationWidth, item.depth)}px` }}
        >
          {isCollapsable && (
            <div >
              <IconChevronDown
                className={classNames(
                  styles.Collapse,
                  collapsed && styles.collapsed,
                  collapsed ? '-rotate-90' : 'rotate-0',
                  'transition-all duration-200 cursor-default ease-in-out ml-2 text-cgray-400',
                )}
              />
            </div>
          )}
        </div>
        <div className='w-14 shrink-0 py-3.5 flex items-center justify-between'>
          <TreeItemIcon
            item={item}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
        </div>
        <>
          <div className="w-20 shrink-0"></div>
          <div className="w-24 shrink-0"></div>
          <div className="w-32 shrink-0"></div>
          <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 40} placement="top">
            <div className="w-80 shrink-0 truncate pr-3">{item?.group?.description}</div>
          </Tippy>
          {isItemHoveredId === item.id && (
            <HoveredContextMenu
              item={item}
              isItemHoveredId={isItemHoveredId}
              setIsItemHoveredId={setIsItemHovered}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
            />
          )}
        </>
      </div>
      {showDetails && (
        <GroupDetailsReadonly
          groupId={item.group?.id}
          isGroupQuotation={item.group?.__typename === 'QuotationGroupType'}    
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={deepestUncollapsedLevel}
        />
      )}
    </li>
  );
}
