import { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import { QuotationGroupFeature, setSelectedGroupFeature } from '../../../../../../../redux/quotationSlice';
import NumberFeatureValueInput from '../../../../fields/group/NumberFeatureValueInput';

export default function GroupFeatureNumericValue() {
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (!isNaN(newValue)) {
      const payload = {
        ...selectedGroupFeature,
        value: newValue,
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  };

  useEffect(() => {
    if (selectedGroupFeature?.value === null || selectedGroupFeature?.value === undefined) {
      const payload = {
        ...selectedGroupFeature,
        value: 0,
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  }, [selectedGroupFeature, dispatch]);

  return (
    <NumberFeatureValueInput
      value={selectedGroupFeature?.value as number}
      onChange={handleChange}
    />
  );
}
