import { gql } from '../../__generated__/gql';

export const CREATE_GROUP_TEMPLATE = gql(`
  mutation createGroupTemplate(
    $item: ID!
  )
  {
    createGroupTemplate(
      input: {
        item: $item
      }
    )
    {
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const REPLACE_GROUP_WITH_TEMPLATE = gql(`
mutation replaceGroupWithTemplate(
  $groupTemplateItem: ID!,
  $quotationItem: ID!,
  $quotation: ID!,
  ){
    replaceGroupWithTemplate(
      input: {
        groupTemplateItem: $groupTemplateItem
        quotationItem: $quotationItem
        quotation: $quotation
      }
    )
  { response
    errors{
      field
      messages
    }
  }
}
`);