import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { IconCircleFilled } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import { WageType } from '../../../../../__generated__/graphql';
import { UPDATE_PRODUCT_SALES_WAGE } from '../../../../../api/mutations/quotations/product';
import { SALES_WAGES_QUERY } from '../../../../../api/queries/settings';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import PopoutInput from '../../../../../layout/fields/PopoutInput';
import { setIsLoadingUpdate } from '../../../../../redux/quotationSlice';

interface Props {
  productId: ID,
  salesWage?: WageType,
  quotationId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
  label: string,
  className?: string,
}

interface MutationVariables {
  product: string,
  salesWage?: string
  quotation: string,
}

export default function SalesWageSelect(props: Props) {
  const { productId, salesWage, quotationId, refetchQueries, label, className } = props;
  const [selectedWage, setSelectedWage] = useState(salesWage);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tippyContent = (salesWage && salesWage?.isCurrent) 
    ? salesWage.wageGroupLabel 
    : !salesWage 
      ? t('Select Sales Wage')
      : `${salesWage?.wageGroupLabel}: ${t('obsolete value')} `;

  const {
    data: salesWagesData,
  } = useQuery(SALES_WAGES_QUERY);

  const [
    updateProductSalesWageMutation,
    {
      loading: updateProductSalesWageLoading,
    },
  ] = useMutation(UPDATE_PRODUCT_SALES_WAGE, {
    refetchQueries,
  });
  
  const options = salesWagesData && salesWagesData.salesWages && salesWagesData.salesWages.response ?
    salesWagesData.salesWages.response.map((wage) => (
      { value: wage?.id, label: wage?.label }) as SelectOption)
    : [];

  const handleChange = (selectedOption: SelectOption) => {
    const variables: MutationVariables = {
      product: productId as string,
      quotation: quotationId as string,
    };
    if (selectedOption) {
      variables.salesWage = selectedOption.value as string;
    }
    updateProductSalesWageMutation({ variables });
  };

  useEffect(() => {
    setSelectedWage(salesWage);
  }, [salesWage]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(updateProductSalesWageLoading));
  }, [dispatch, updateProductSalesWageLoading]);

  return (
    <Tippy content={tippyContent} placement="left" >
      <div className='flex h-full'>
        <div className="h-full grow">
          <PopoutInput
            value={options.find(option => option.value === selectedWage?.id)}
            options={options}
            onChange={handleChange as (value?: SingleValue<SelectOption>) => void}
            label={label}
            isClearable
            buttonLabel={salesWage?.costLabel || t('Select Sales Wage')}
            className={className}
          />
        </div>
        { salesWage && !salesWage?.isCurrent &&
            <IconCircleFilled className='w-2 h-2 text-cyellow-500 self-start'/>
          }
      </div>
    </Tippy>
  );
}
