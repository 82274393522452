import { IconInnerShadowLeft } from '@tabler/icons-react';
import { classNames } from '../helpers/utils';

type Props = {
  className?: string;
  color?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

function LoadingIndicator({ className, color, size = 'md' }: Props) {
  // Assigning a default color if not provided
  const colorClass = color ? `text-${color}` : 'text-cblue-500';

  // Map sizes to Tailwind's width and height classes
  const sizeClasses = {
    xs: 'w-4 h-4',
    sm: 'w-6 h-6',
    md: 'w-8 h-8',
    lg: 'w-10 h-10',
    xl: 'w-12 h-12',
  };

  return (
    <div className={classNames(className || 'flex', colorClass, sizeClasses[size])}>
      <IconInnerShadowLeft className={classNames(colorClass, sizeClasses[size], 'animate-spin')} />
    </div>
  );
}

export default LoadingIndicator;
