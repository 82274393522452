import { gql } from '../../__generated__/gql';

export const UPDATE_PRODUCT_SETTINGS_NOTES = gql(`
  mutation updateProductSettingsNotes(
    $notes: String!,
    $productSettings: ID!,
    ){
      updateProductSettingsNotes(
      input: {
        notes: $notes,
        productSettings: $productSettings,
      }
    )
  { 
    response
    errors{
      field
      messages
    }
  }
  }
`);
