import { useTranslation } from 'react-i18next';
import SelectFeatureForm from '../list/row/details/groupDetails/groupFeature/SelectFeatureForm';

interface Props {
  handleClose: () => void,
}

export default function SelectFeatureModal(props: Props) {
  const { handleClose } = props;
  const { t } = useTranslation();

  return (
    <div className='flex flex-col justify-between items-center rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 mb-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Select Feature')}</div>
      <div className='pl-8 mb-8 w-full'>{t('Select feature for current Group')}</div>
      <SelectFeatureForm handleClose={handleClose} />
    </div>
  );
}
