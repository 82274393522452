import { useMutation } from '@apollo/client';
import { IconRefresh } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_PRODUCT_PRICE_SUGGESTION } from '../../../../../../api/mutations/quotations/product';
import { PRODUCT_PRICE_SUGGESTION_QUERY } from '../../../../../../api/queries/quotations/product';
import { useAppDispatch } from '../../../../../../helpers/reduxHooks';
import { setSuccessAlert } from '../../../../../../redux/alertSlice';


interface Props {
  productId: ID,
}

export default function RegenerateButton(props: Props) {
  const { productId } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [
    updateProductPriceSuggestionMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(UPDATE_PRODUCT_PRICE_SUGGESTION, {
    refetchQueries: [{
      query: PRODUCT_PRICE_SUGGESTION_QUERY,
      variables: {
        product: productId as string,
      },
    }],
  });

  const handleButtonClick = () => {
    if (!mutationLoading) {
      updateProductPriceSuggestionMutation(
        {
          variables: { product: productId as string },
        },
      );
    }
  };

  useEffect(() => {
    if (mutationData
      && mutationData.updateProductPriceSuggestion
      && mutationData.updateProductPriceSuggestion.response) {
      dispatch(setSuccessAlert(
        [`${t('Product Price Suggestion was updated')}`],
      ));
    }
  }, [mutationData, dispatch, t]);

  return (
    <Tippy content={t('Regenerate suggestion')} >
      <div onClick={handleButtonClick} className='cursor-pointer'>
        <IconRefresh className='text-cblue-500 w-5' />
      </div>
    </Tippy>
  );
}
