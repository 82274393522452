import type { UniqueIdentifier } from '@dnd-kit/core';
import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties } from 'react';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { iOS } from '../../../../helpers/dnd/utilities';
import { FlattenedItem } from '../../../shared/dnd/types';
import { GroupItem } from './row/group/GroupItem';
import { ProductItem } from './row/product/ProductItem';
import { ServiceItem } from './row/service/ServiceItem';

interface Props {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  item: FlattenedItem;
  id: UniqueIdentifier;
  depth: number;
  indentationWidth: number;
  indicator?: boolean;
  onCollapse?(): void;
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true;

export default function QuotationListItem(props: Props) {
  const {
    childCount, clone, collapsed, item, id, indentationWidth, depth,
    indicator, onCollapse } = props;
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  switch (item.itemType) {
    case ItemTypeChoices.Product:
      return (
        <>
          <ProductItem
            ref={setDraggableNodeRef}
            wrapperRef={setDroppableNodeRef}
            style={style}
            ghost={isDragging}
            disableSelection={iOS}
            disableInteraction={isSorting}
            clone={clone}
            item={item}
            depth={depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            handleProps={{
              ...attributes,
              ...listeners,
            }}
          />
        </>
      );
    case ItemTypeChoices.Group:
      return (
        <GroupItem
          childCount={childCount}
          collapsed={collapsed}
          onCollapse={onCollapse}
          item={item}
          depth={depth}
          indentationWidth={indentationWidth}
          ref={setDraggableNodeRef}
          wrapperRef={setDroppableNodeRef}
          style={style}
          ghost={isDragging}
          disableSelection={iOS}
          disableInteraction={isSorting}
          clone={clone}
          indicator={indicator}
          handleProps={{
            ...attributes,
            ...listeners,
          }}
        />
      );
    case ItemTypeChoices.Service:
      return (
        <ServiceItem
          ref={setDraggableNodeRef}
          wrapperRef={setDroppableNodeRef}
          style={style}
          ghost={isDragging}
          disableSelection={iOS}
          disableInteraction={isSorting}
          clone={clone}
          item={item}
          depth={depth}
          indentationWidth={indentationWidth}
          indicator={indicator}
          handleProps={{
            ...attributes,
            ...listeners,
          }}
        />
      );
    default:
      return <></>;
  }
}
