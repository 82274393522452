import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../helpers/dnd/utilities';
import RobotIndicator from '../../../layout/RobotIndicator';

interface Props {
  indentationWidth: number;
  deepestUncollapsedLevel: number;
}

export default function SuggestionsListHeader(props: Props) {
  const { indentationWidth, deepestUncollapsedLevel } = props;
  const { t } = useTranslation();

  return (
    <div>
      <div className=" w-full flex items-center gap-6 bg-cblue-100 border-b-2 border-cblue-500 pt-2 pb-2 mb-3 font-bold text-sm text-cgray-600">
        <div
          style={{ width: `${getItemWidth(deepestUncollapsedLevel, indentationWidth, 0)}px` }}
          className='shrink-0'
        ></div>
        <div className='w-14 flex items-center justify-start shrink-0'>
          <RobotIndicator />
        </div>
        <div className="w-20 shrink-0">{t('Unit')}</div>
        <div className="w-24 shrink-0">{t('Supplier')}</div>
        <div className="w-32 shrink-0">{t('Product ID')}</div>
        <div className="w-80 shrink-0">{t('Description')}</div>
        <div className="w-8 shrink-0"></div>
      </div>
    </div>
  );
}
