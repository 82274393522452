import { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { SearchFeatureType } from '../../../../__generated__/graphql';
import { useDebounceArray } from '../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import RangeInput from '../../../../layout/fields/RangeInput';
import { setFeature, unsetFeature } from '../../../../redux/searchSlice';
import { prepareFeature } from './helpers';

interface Props {
  feature: SearchFeatureType,
}

export default function RangeFeature(props: Props) {
  const { feature } = props;
  //@ts-ignore
  const roundedMinValue = Math.floor(Number(feature?.values[0].valueFloat));
  //@ts-ignore
  const roundedMaxValue = Math.ceil(Number(feature?.values[1].valueFloat));
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const selectedFeature = selectedFeatures.find(item => item.id === feature.id);
  const userValue = selectedFeature?.userValue as number[] || [roundedMinValue, roundedMaxValue];
  const [minValue, setMinValue] = useState(userValue[0]);
  const [maxValue, setMaxValue] = useState(userValue[1]);
  const isChecked = selectedFeatures?.some(item => item.id === feature.id);
  const rangeValues = useMemo(() => [minValue, maxValue], [minValue, maxValue]);
  const debouncedRangeValue = useDebounceArray(rangeValues, 1500);
  const dispatch = useAppDispatch();

  const handleRangeSelect = (selectedValue: number[]) => {
    setMinValue(selectedValue[0]);
    setMaxValue(selectedValue[1]);
  };

  function handleCheckboxChange(
    e: ChangeEvent<HTMLInputElement>,
    currentFeature: SearchFeatureType,
  ) {
    if (e.target.checked) {
      dispatch(setFeature(prepareFeature(currentFeature)));
    } else {
      dispatch(unsetFeature(currentFeature.id as string));
    }
  }

  useEffect(() => {
    if (isChecked) {
      dispatch(setFeature({
        id: feature.id as string,
        userValue: debouncedRangeValue as number[],
        code: feature.code as string,
        featureType: feature.featureType as string,
      }));
    }
  }, [debouncedRangeValue, dispatch, feature, isChecked]);

  return (
    <>
      <div className="relative w-4 ml-2">
        {isChecked && (
          <div className="absolute inset-y-0 -left-2 w-0.5 bg-cblue-500" />
        )}
        <input
          type="checkbox"
          className="absolute left-0 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-cblue-500 focus:ring-cblue-500 grow"
          checked={isChecked}
          onChange={(e) => handleCheckboxChange(e, feature)}
        />
      </div>
      <div className='w-full'>
      <RangeInput
        label={feature.localizedDescription as string}
        values={rangeValues}
        min={roundedMinValue}
        max={roundedMaxValue}
        abbreviation={
          (feature.unit && feature.unit.abbreviation)
            ? feature.unit.abbreviation
            : ''}
        onChange={handleRangeSelect}
        isDisabled={!isChecked}
      />
      <div className="flex items-center justify-between gap-2">
        <input 
          type="text" 
          name="minValueInput" 
          value={minValue}
          onChange={(e) => handleRangeSelect([Number(e.target.value), maxValue])}
          className={classNames(!isChecked ? 'border-cgray-400 caret-cgray-400 focus:border-cgray-400' : 'border-cblue-500 caret-cblue-500 focus:border-cblue-500', 'overflow-hidden w-16 pt-0 pb-0 pl-0 pr-0 text-center text-sm text-cgray-400 border-0 border-b-2 focus:outline-none focus:ring-0')}
        />
        <div className="text-cgray-300">—</div>
        <input 
          type="text" 
          name="maxValueInput" 
          value={maxValue}
          onChange={(e) => handleRangeSelect([minValue, Number(e.target.value)])}
          className={classNames(!isChecked ? 'border-cgray-400 caret-cgray-400 focus:border-cgray-400' : 'border-cblue-500 caret-cblue-500 focus:border-cblue-500', 'overflow-hidden w-16 pt-0 pb-0 pl-0 pr-0 text-center text-sm text-cgray-400 border-0 border-b-2 focus:outline-none focus:ring-0')}
        />
      </div>
      </div>
    </>
  );
}
