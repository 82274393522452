import { IconPdf } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { setQuotationPDFName, setShowGeneratePDFModal } from '../../../redux/quotationSlice';

interface Props {
  quotationName: string;
}


export default function GeneratePDF(props: Props) {
  const { quotationName } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleOpenGeneratePDF = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    dispatch(setQuotationPDFName(quotationName));
    dispatch(setShowGeneratePDFModal(true));
  };

  return (
    <div 
      className="flex gap-x-2 px-4 py-2"
      onClick={(event) => handleOpenGeneratePDF(event)}
    >
      <IconPdf className={'h-5 w-5 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center font-bold rounded cursor-pointer'} />
      {t('Generate PDF version')}
    </div>
  );
}
