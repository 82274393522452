import { useMemo } from 'react';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import {
  flattenTree,
  removeChildrenOf,
} from '../../../../helpers/dnd/utilities';
import type { FlattenedItem } from '../../../shared/dnd/types';
import GroupItem from './row/group/GroupItem';
import ProductItem from './row/product/ProductItem';
import ServiceItem from './row/service/ServiceItem';

interface Props {
  defaultItems: FlattenedItem[];
  indentationWidth?: number;
  deepestUncollapsedLevel: number
}

export default function SuggestionsList(props: Props) {
  const { indentationWidth = 50, defaultItems, deepestUncollapsedLevel } = props;

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(defaultItems);
    const collapsedItems = flattenedTree.reduce<any>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );
    return removeChildrenOf(
      flattenedTree,
      collapsedItems,
    );
  }, [defaultItems]);

  return (
    <>
      {flattenedItems.map((item) => (
        <div key={item.id}>
          {item.itemType === ItemTypeChoices.Product && (
            <ProductItem
              indentationWidth={indentationWidth}
              item={item}
              deepestUncollapsedLevel={deepestUncollapsedLevel}
            />
          )}
          {item.itemType === ItemTypeChoices.Group && (
            <GroupItem
              deepestUncollapsedLevel={deepestUncollapsedLevel}
              collapsed={Boolean(item.collapsed && item.children.length)}
              indentationWidth={indentationWidth}
              item={item}
              isCollapsable={!!item.children.length}
            />
          )}
          {item.itemType === ItemTypeChoices.Service && (
            <ServiceItem
              indentationWidth={indentationWidth}
              item={item}
              deepestUncollapsedLevel={deepestUncollapsedLevel}
            />
          )}
        </div>
      ))}
    </>
  );
}
