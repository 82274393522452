import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_SERVICE_NOTES } from '../../../../../api/mutations/quotations/service';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';

export interface Props {
  notes: string | undefined,
  id: ID,
  refetchQueries: InternalRefetchQueriesInclude,
}

interface FormValues {
  notes: string,
  service: ID,
  quotation: ID,
}

export default function NotesInput(props: Props) {
  const { notes, id, refetchQueries } = props;
  const { quotationId } = useParams();
  const fieldName = 'notes';

  const validationSchema = yup.object({
    [fieldName]: yup.string(),
  });

  const initialValues: FormValues = {
    notes: notes as string,
    service: id,
    quotation: quotationId as ID,
  };

  const { handleChange, values, handleBlur } = useQuotationItemInputHandler({
    mutation: UPDATE_SERVICE_NOTES,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
  });

  return (
    <textarea
      name={fieldName}
      value={values[fieldName]}
      onChange={handleChange}
      className='resize-none overflow-ellipsis w-full h-full p-0 text-sm flex items-start border-none ring-0 outline-none bg-transparent shadow-none drop-shadow-none focus:border-none focus:ring-0 focus:outline-none'
      onBlur={() => handleBlur()}
      rows={5}
    />
  );
}
