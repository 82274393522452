import type { InternalRefetchQueriesInclude } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_QUOTATION_ITEM_POSITION } from '../../../../../api/mutations/quotations/item';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames } from '../../../../../helpers/utils';

interface Props {
  position: string | undefined;
  id: ID;
  refetchQueries: InternalRefetchQueriesInclude,
}

interface FormValues {
  position: string | undefined,
  item: ID,
  quotation: ID,
}

export default function PositionInput(props: Props) {
  const { position, id, refetchQueries } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const fieldName = 'position';

  const validationSchema = yup.object({
    [fieldName]: yup.string()
      .matches(
        /^[0-9]+(\.[0-9]+)*$/, 
        t('Position should contain only positive numbers and dots'),
      )
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    position: position,
    item: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError } = useQuotationItemInputHandler({
    mutation: UPDATE_QUOTATION_ITEM_POSITION,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  return (
    <Tippy content={values[fieldName]} placement="right" disabled={values[fieldName].length < 10}>
      <div
        className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'border-b py-px h-full flex items-center justify-between rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
        onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
      >
        <input
          name={fieldName}
          value={values[fieldName]}
          onChange={handleChange}
          className="px-0.5 w-20 h-10 flex items-center justify-end rounded-t bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:outline-none focus:border-none text-sm text-right text-ellipsis"
          style={{ direction: inputFocused ? 'ltr' : 'rtl' }}
          ref={inputRef}
          onFocus={() => setInputFocused(true)}
          onBlur={() => handleBlur()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
              (e.target as HTMLInputElement).blur();
            }
          }}
        />
      </div>
    </Tippy>
  );
}
