import { useQuery } from '@apollo/client';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ClassType } from '../../../../__generated__/graphql';
import {
  FEATURES_PRESET_QUERY,
} from '../../../../api/queries/etimReleases';
import { CURRENT_USER_QUERY } from '../../../../api/queries/users';
import { useDebounceValue } from '../../../../helpers/customHooks';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { IconE } from '../../../../layout/icons';
import { setClass, setFeature } from '../../../../redux/searchSlice';
import FavouriteButton from './FavouriteButton';

interface Props {
  etimClass: ClassType,
  selectedGroupId: string,
}

export function EtimClass(props: Props) {
  const { etimClass, selectedGroupId } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const checkedClass = useAppSelector(state => state.search.selectedETIMClassId);
  const searchClassesInput = useAppSelector(state => state.search.searchClassesInput);
  const debouncedSearch = useDebounceValue(searchClassesInput, 200);
  const searchInput = debouncedSearch ? debouncedSearch.toString() : undefined;
  const {
    data: presetData,
  } = useQuery(FEATURES_PRESET_QUERY, {
    variables: {
      etimClass: etimClass.id,
    },
  });

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const handleChangeClasses = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    // Add id's of selected classes to redux state, to use it in FilterFeatures component
    if (checked) {
      dispatch(setClass(value));
      if (presetData && presetData.featuresPreset && presetData.featuresPreset.response) {
        presetData.featuresPreset.response.map( presetItem => {
          dispatch(setFeature({
            id: presetItem?.etimFeature?.id,
            userValue: presetItem?.value,
            code: presetItem?.etimFeature?.code as string,
            featureType: presetItem?.etimFeature?.featureType as string,
          }));
        });
      }
    } else {
      dispatch(setClass(''));
    }
    (document.activeElement as HTMLElement).blur();
  };

  const user = userData && userData.user;

  const isClassChecked = checkedClass === etimClass?.id;

  return (
    <div key={etimClass?.id} className="flex items-center justify-between relative">
      {isClassChecked &&
        <div className="absolute inset-y-0 -left-2 w-0.5 bg-cblue-500" />
      }
      <div className={classNames(isClassChecked ? '' : 'hover:bg-cblue-200', 'w-6 h-6 rounded-full flex items-center justify-center shrink-0')}>
        <input
          id={`filter-${selectedGroupId}-${etimClass?.id}`}
          name={`${selectedGroupId}[]`}
          value={etimClass?.id}
          onChange={handleChangeClasses}
          type="radio"
          checked={isClassChecked}
          className="h-4 w-4 bg-transparent border-cblue-500 text-cblue-500 focus:ring-cblue-500"
        />
      </div>
      <label
        htmlFor={`filter-${selectedGroupId}-${etimClass?.id}`}
        className="flex-grow ml-1 text-sm text-cgray-500"
      >
        {etimClass?.isExtension && 
        <Tippy content={t('Client extension')}>
          <div className='inline-flex'>
            <IconE
              className={'w-4 h-4 inline-flex items-center text-2xs mr-1 border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} 
            />
          </div>
        </Tippy>
        }
        {etimClass?.localizedDescription}
      </label>
      {user?.isSuperuser && (
        <FavouriteButton
          selectedGroupId={selectedGroupId}
          selectedClassId={etimClass?.id}
          isFavourite={!!etimClass?.isFavourite}
          searchInput={searchInput}
        />
      )}
    </div>
            
  );
}
