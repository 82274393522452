import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error400() {
  const { t } = useTranslation();

  return (
    <ErrorPageTempate
      label={t('Error: Bad Request.')}
      code={400}
    />
  );
}

export default Error400;
