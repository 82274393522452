import { Disclosure, Transition } from '@headlessui/react';
import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { EtimGroupType } from '../../../../__generated__/graphql';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { IconE } from '../../../../layout/icons';
import { setGroup, unsetGroup } from '../../../../redux/searchSlice';
import EtimClass from './EtimClasses';

interface Props {
  group: EtimGroupType,
}

export default function EtimGroup(props: Props) {
  const { group } = props;
  const dispatch = useAppDispatch();
  const isGroupSelected = useAppSelector(state => state.search.selectedETIMGoupsId).find(item => item === group.id);
  const { t } = useTranslation();

  const handleChange = (open: boolean) => {
    // "open" state gets changed a bit after the click so we uses reverse state here to make it work
    if (!open) {
      dispatch(setGroup(group.id));
    } else {
      dispatch(unsetGroup(group.id));
    }
  };

  return (
    <Disclosure as="div" defaultOpen={!!isGroupSelected} key={group.id} className="py-1 border-b-2 border-cgray-300">
      {({ open }) => (
        <>
          <h3>
            <Disclosure.Button
              onClick={() => handleChange(open)}
              className="flex w-full items-center justify-start text-start text-sm text-cblue-500 grow-0"
            >
              <span
                className={classNames(!!isGroupSelected
                  ? 'rotate-0' : '-rotate-90',
                'transition-all duration-100 ease-in-out flex items-center')}
              >
                <span>
                  {open ? (
                    <IconChevronDown className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <IconChevronDown className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </span>
              {group?.isExtension &&  
                <Tippy content={t('Client extension')}>
                  <div className='inline-flex'>       
                    <IconE
                      className={'w-4 h-4 inline-flex items-center text-2xs mr-1 border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} 
                    />
                  </div>
                </Tippy>
              }
              <span className="flex-1 min-w-0 font-medium text-cgray-500 break-all">{group.localizedDescription}</span>
            </Disclosure.Button>
          </h3>

          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-105"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Disclosure.Panel className="mt-1 mb-2">
              <EtimClass selectedGroupId={group.id} />
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
