import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { isMacOs } from 'react-device-detect';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ItemTypeChoices } from '../../../../__generated__/graphql';
import { COPY_QUOTATION_ITEM, MOVE_QUOTATION_ITEM_MUTATION } from '../../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { recursiveSearch, recursiveSearchParent, recursiveSearchParentSibling, recursiveSearchSiblingChildren } from '../../../../helpers/utils';
import { discardAlert, setErrorAlert, setSuccessAlert } from '../../../../redux/alertSlice';
import { addOpenedDeatilsItems, openCreateGroupModal, openCreateServiceModal, 
  removeOpenedDeatilsItems, setCopiedItem, setCreateNewItemInside, setIsCreateItemOverlayOpen, setIsLoadingUpdate, 
  setItemToCollapse, setItemToSinglePasteBelow, setItemToUncollapse, setNewProductOrder, setSelectedItem, 
  setShowDeleteModal, unsetCopiedItem, setItemToCut, unsetItemToCut } from '../../../../redux/quotationSlice';
import { setDestinationProjectId, setDestinationQuotationId, setRedirectToQuotation } from '../../../../redux/searchSlice';
import { FlattenedItem } from '../../../shared/dnd/types';

// Platform-specific key combinations
const copyKey = isMacOs ? 'mod+c' : 'ctrl+c';
const pasteKey = isMacOs ? 'mod+v' : 'ctrl+v';
const deleteKey = isMacOs ? 'backspace' : 'delete';
const cutKey = isMacOs ? 'Cmd+x' : 'ctrl+x';

// Universal key combinations
const createItemBelowKey = 'i+ArrowDown';
const createItemInsideKey = 'i+ArrowRight';
const createServiceBelowKey = 's+ArrowDown';
const createServiceInsideKey = 's+ArrowRight';
const createProductBelowKey = 'p+ArrowDown';
const createProductInsideKey = 'p+ArrowRight';
const createGroupBelowKey = 'g+ArrowDown';
const createGroupInsideKey = 'g+ArrowRight';
const downKey = 'ArrowDown';
const upKey = 'ArrowUp';
const rightKey = 'ArrowRight';
const leftKey = 'ArrowLeft';
const uncollapseKey = 'shift+ArrowRight';
const collapseKey = 'shift+ArrowLeft';

export default function HotKeysHandler() {

  const { projectId, quotationId } = useParams();
  const copiedItem = useAppSelector(state => state.quotation.copiedItem);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const itemToCut = useAppSelector(state => state.quotation.itemToCut);
  const treeItems = useAppSelector(state => state.quotation.treeItems);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const areEntityKeysNotPressed = () => (!isHotkeyPressed('p') && !isHotkeyPressed('s') && !isHotkeyPressed('i') && !isHotkeyPressed('g'));

  const handleAddItem = () => {
    if (projectId && quotationId) {
      dispatch(setRedirectToQuotation(true));
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(selectedItem as FlattenedItem));
      navigate('/search/single/');
    }
  };

  const arrowDownHandler = () => {
    // selected item's properties may change (e.g. "collapsed"), so we need to use actual one 
    const updatedSelectedItem = recursiveSearch(treeItems as FlattenedItem[], selectedItem?.parentId as ID, selectedItem?.order as number);
    dispatch(setSelectedItem(updatedSelectedItem as FlattenedItem));
    // 1 - if selected item not collapsed and has children - select the first child   
    if (updatedSelectedItem?.children.length && !updatedSelectedItem?.collapsed) {
      const result = recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.id as ID, 0);
      if (result) dispatch(setSelectedItem(result as FlattenedItem));
    // 2 - if selected item has no children or collapsed, but has next siblings - select the next sibling
    } else if (!!recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID, updatedSelectedItem?.order as number + 1)) {
      const result = recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID, updatedSelectedItem?.order as number + 1);
      if (result) dispatch(setSelectedItem(result as FlattenedItem));
    // 3 - if selected item has no children (or collapsed) and has no next siblings - select next sibling of his parent
    } else if (!recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID, updatedSelectedItem?.order as number + 1)) {
      const parent = recursiveSearchParent(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID);
      const result = recursiveSearchParentSibling(parent, treeItems as FlattenedItem[]);      
      if (result) dispatch(setSelectedItem(result as FlattenedItem));
    }    
  };

  const arrowUpHandler = () => {
    // selected item's properties may change (e.g. "collapsed"), so we need to use actual one 
    const updatedSelectedItem = recursiveSearch(treeItems as FlattenedItem[], selectedItem?.parentId as ID, selectedItem?.order as number);
    dispatch(setSelectedItem(updatedSelectedItem as FlattenedItem));
    const prevSibling = recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID, updatedSelectedItem?.order as number - 1);
    // 1 - if selected item has previous uncollapsed sibling with children - select the last child of previous siblings
    if (!!prevSibling && prevSibling?.children?.length) {
      const result = recursiveSearchSiblingChildren(prevSibling, treeItems as FlattenedItem[]);
      if (result) dispatch(setSelectedItem(result as FlattenedItem));
      // 2 - if selected item has previous siblings without children - select the previous sibling
    } else if (!!prevSibling && !prevSibling?.children?.length) {
      const result = recursiveSearch(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID, updatedSelectedItem?.order as number - 1);
      if (result) dispatch(setSelectedItem(result as FlattenedItem));
      // 3 - if selected item has no previous sibling - select its parent
    } else if (!prevSibling) {
      const parent = recursiveSearchParent(treeItems as FlattenedItem[], updatedSelectedItem?.parentId as ID);
      if (parent) dispatch(setSelectedItem(parent as FlattenedItem));
    }   
  };

  useHotkeys(createItemBelowKey, () => {
    if (selectedItem) {
      dispatch(setCreateNewItemInside(false));
      handleAddItem();
    }
  });

  useHotkeys(createItemInsideKey, () => {
    if (selectedItem && selectedItem.itemType === ItemTypeChoices.Group ) {
      dispatch(setCreateNewItemInside(true));
      handleAddItem();
    }
  });

  useHotkeys(createServiceBelowKey, () => {
    if (selectedItem && selectedItem.parentId) {
      dispatch(setCreateNewItemInside(false));
      dispatch(openCreateServiceModal( { targetOrder: (selectedItem?.order as number) + 1 }));
    }
  });

  useHotkeys(createServiceInsideKey, () => {
    if (selectedItem && selectedItem.itemType === ItemTypeChoices.Group ) {
      dispatch(setCreateNewItemInside(true));
      dispatch(openCreateServiceModal({ targetOrder: selectedItem?.children.length }));
    }
  });

  useHotkeys(createProductBelowKey, () => {
    if (selectedItem && projectId && quotationId && selectedItem.parentId) {
      dispatch(setItemToSinglePasteBelow(selectedItem));
      dispatch(setCreateNewItemInside(false));
      dispatch(setNewProductOrder((selectedItem?.order as number) + 1));
      dispatch(discardAlert());
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(selectedItem));
      dispatch(setIsCreateItemOverlayOpen(true));
    }
  });

  useHotkeys(createProductInsideKey, () => {
    if (selectedItem && projectId && quotationId && selectedItem.itemType === ItemTypeChoices.Group ) {
      dispatch(setCreateNewItemInside(true));
      dispatch(setNewProductOrder(selectedItem?.children.length));
      dispatch(discardAlert());
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      dispatch(setItemToSinglePasteBelow(selectedItem));
      dispatch(setIsCreateItemOverlayOpen(true));
    }
  });

  useHotkeys(createGroupBelowKey, () => {
    if (selectedItem) {
      dispatch(setCreateNewItemInside(false));
      dispatch(openCreateGroupModal({ targetOrder: (selectedItem?.order as number) + 1 }));
    }
  });
  
  useHotkeys(createGroupInsideKey, () => {
    if (selectedItem && selectedItem.itemType === ItemTypeChoices.Group ) {
      dispatch(setCreateNewItemInside(true));
      dispatch(openCreateGroupModal({ targetOrder: selectedItem?.children.length }));
    }
  });

  useHotkeys(copyKey, () => {
    if (selectedItem) {
      dispatch(setCopiedItem(selectedItem));
      dispatch(unsetItemToCut());
    }
  });

  useHotkeys(deleteKey, () => {
    if (selectedItem) {
      dispatch(setShowDeleteModal(true));
    }
  });

  useHotkeys(cutKey, () => {
    if (selectedItem) {
      dispatch(setItemToCut(selectedItem));
      dispatch(unsetCopiedItem());
    }
  });

  useHotkeys(downKey, () => {    
    if (selectedItem && areEntityKeysNotPressed()) {
      arrowDownHandler();
    }
  });

  useHotkeys(upKey, () => {
    if (selectedItem && areEntityKeysNotPressed()) {
      arrowUpHandler();
    }
  });

  useHotkeys(rightKey, () => {   
    if (selectedItem && areEntityKeysNotPressed()) {
      dispatch(addOpenedDeatilsItems(selectedItem.id));
    }
  });

  useHotkeys(leftKey, () => {    
    if (selectedItem && areEntityKeysNotPressed()) {
      dispatch(removeOpenedDeatilsItems(selectedItem.id));
    }
  });

  useHotkeys(uncollapseKey, () => {    
    if (selectedItem) {
      dispatch(setItemToUncollapse(selectedItem.id));
    }
  });
  
  useHotkeys(collapseKey, () => {
    if (selectedItem) {
      dispatch(setItemToCollapse(selectedItem.id));
    }
  });

  const [
    copyQuotationItem,
    {
      loading: copyQuotationItemLoading,
      data: copyQuotationItemData,
    },
  ] = useMutation(COPY_QUOTATION_ITEM, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  const [
    moveQuotationItem,
    {
      loading: moveQuotationItemLoading,
      data: moveQuotationItemData,
    },
  ] = useMutation(MOVE_QUOTATION_ITEM_MUTATION, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  useHotkeys(pasteKey, () => {
    if (itemToCut) {
      if (!selectedItem?.parentId 
        && (itemToCut.itemType === ItemTypeChoices.Product || itemToCut.itemType === ItemTypeChoices.Service)) {
        dispatch(setErrorAlert([t('Only Groups are allowed to be pasted at the root level')]));
        return;
      }
      dispatch(discardAlert());
      moveQuotationItem(
        {
          variables: {
            order: selectedItem?.order as number + 1,
            parent: selectedItem?.parentId as string,
            items: [itemToCut.id as string],
            quotation: quotationId as string,
          },
          onError: () => {
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
      return;
    }

    if (copiedItem) {
      dispatch(discardAlert());
      copyQuotationItem(
        {
          variables: {
            items: [copiedItem.id as string],
            order: selectedItem?.order as number + 1,
            parent: selectedItem?.parentId as string,
            quotation: quotationId as string,
          },
          onError: () => {
            dispatch(setIsLoadingUpdate(false));
          },
        },
      );
    }
  });

  useEffect(() => {
    dispatch(setIsLoadingUpdate(copyQuotationItemLoading || moveQuotationItemLoading));
  }, [dispatch, copyQuotationItemLoading, moveQuotationItemLoading]);

  useEffect(() => {
    if (
      (copyQuotationItemData && copyQuotationItemData.copyQuotationItems && copyQuotationItemData.copyQuotationItems.response) 
      || (moveQuotationItemData && moveQuotationItemData.moveQuotationItems && moveQuotationItemData.moveQuotationItems.response)) {
      dispatch(setSuccessAlert(
        [`${t('Item was successfully pasted')}`],
      ));
      dispatch(unsetCopiedItem());
      dispatch(unsetItemToCut());
    }
  }, [copyQuotationItemData, dispatch, moveQuotationItemData, t]);

  useHotkeys('escape', () => {
    dispatch(unsetCopiedItem());
    dispatch(unsetItemToCut());
  });

  return (
    <></>
  );
}
