interface Config {
  API_HOST?: string;
  API_URL?: string;
  ROOT_URL?: string;
  GENERATE_PDF_URL?: string;
  GENERATE_XLSX_URL?: string;
}

export let language = 'de';

const config: Config = {};

switch (window.location.hostname) {
  case 'hundt.calcutron.de':
    config.API_HOST = 'https://api.hundt.calcutron.de';
    break;
  case 'test.calcutron.de':
    config.API_HOST = 'https://api.test.calcutron.de';
    break;
  default:
    config.API_HOST = 'http://localhost:8000';
    language = 'en';
    break;
}

config.API_URL = `${config.API_HOST}/graphql/`;
config.ROOT_URL = window.location.origin;
config.GENERATE_PDF_URL = `${config.API_HOST}/quotations/pdf/`;
config.GENERATE_XLSX_URL = `${config.API_HOST}/quotations/item/xlsx/`;

export default config;

export const INLINE_RECORDS_SIZE = 200;

