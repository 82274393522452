import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import DefaultServiceDetailsReadOnly from '../../../../../quotation/list/row/details/RODefaultServiceDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
}

function ServiceItem(props: Props) {
  const { indentationWidth, item } = props;
  const [showDetails, setShowDetails] = useState(false);
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
          className={classNames(styles.TreeItem, 'bg-cblue-200, relative')}
          style={
            {
              '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
              '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--justifyContent': 'start',
            } as React.CSSProperties
          }
          >

          <div
            className='flex justify-end'
            style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
          >
          </div>
          <div className='w-14 py-3.5 flex items-center justify-between'>
            <div className='w-5'></div>
            <TreeItemIcon 
              item={item} 
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
          </div>
          <div className="w-20"></div>
          <div className="w-24"></div>
          <div className="w-32"></div>
          <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{item?.service?.description}</div>
        </div>
        {showDetails && (
          <DefaultServiceDetailsReadOnly
            item={item}
            indentationWidth={indentationWidth}
            depth={item.depth}
            maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
          />
        )}
      </li>
  );
}

export default ServiceItem;
