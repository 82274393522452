import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { ReactComponent as IconRobot } from '../assets/icons/Robot.svg';

//@ts-ignore
export default function AuthLayout({ children }) {
  if (Cookies.get('jwt_token')) {
    return <Navigate to="/" />;
  }
  // We duplicate alert areas here to cover botha lauyouts
  // (for large screens and other)
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <IconRobot className="h-24 w-24 mx-auto fill-cblue-500" />
            <div className="mt-6">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
