import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { IconCircleFilled } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import { WageType } from '../../../../../__generated__/graphql';
import { UPDATE_SERVICE_WAGE } from '../../../../../api/mutations/quotations/service';
import { SERVICE_WAGES_QUERY } from '../../../../../api/queries/settings';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import PopoutInput from '../../../../../layout/fields/PopoutInput';
import { setIsLoadingUpdate } from '../../../../../redux/quotationSlice';

interface Props {
  refetchQueries: InternalRefetchQueriesInclude,
  serviceWage: WageType | undefined,
  id: ID,
  quotationId: ID,
  label?: string,
}

export default function ServiceWageSelect(props: Props) {
  const { serviceWage, id, refetchQueries, quotationId, label } = props;
  const [selectedWage, setSelectedWage] = useState(serviceWage);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const tippyContent = serviceWage?.isCurrent ? serviceWage.wageGroupLabel : `${serviceWage?.wageGroupLabel}: ${t('obsolete value')} `;

  const { data: serviceWagesData } = useQuery(SERVICE_WAGES_QUERY);

  const [
    updateServiceWageMutation,
    {
      loading: updateServiceWageLoading,
    },
  ] = useMutation(UPDATE_SERVICE_WAGE, { refetchQueries });

  const options = serviceWagesData && serviceWagesData.serviceWages && serviceWagesData.serviceWages.response
    ? serviceWagesData.serviceWages.response.map((wage) => (
      { value: wage?.id, label: wage?.label }) as SelectOption)
    : [];

  const handleChange = (selectedOption: SelectOption) => {
    updateServiceWageMutation({
      variables: {
        serviceWage: selectedOption.value as string,
        service: id as string,
        quotation: quotationId as string,
      },
    });
  };

  useEffect(() => {
    setSelectedWage(serviceWage);
  }, [serviceWage]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(updateServiceWageLoading));
  }, [dispatch, updateServiceWageLoading]);

  return (
    <Tippy content={tippyContent} placement="left" >
      <div className='flex h-full'>
        <div className="h-full grow">
          <PopoutInput
            value={options.find(option => option.value === selectedWage?.id)}
            options={options}
            onChange={handleChange as (value?: SingleValue<SelectOption>) => void}
            label={label}
            buttonLabel={serviceWage?.costLabel as string || t('Select Service Wage')}
          />
        </div>
        {!serviceWage?.isCurrent &&
          <IconCircleFilled className='w-2 h-2 text-cyellow-500 self-start' />
        }
      </div>
    </Tippy>
  );
}