import { UserType } from '../../../__generated__/graphql';


interface Props {
  user: UserType;
}

const UserAvatar = (props: Props) => {
  const { user } = props;

  const userAvatarLetters = (user?.firstName && user?.lastName)
    ? user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
    : user?.email.slice(0, 2).toUpperCase();

  return (
    <>
        <div className='shadow-lg h-10 w-10 bg-cblue-100 flex items-center justify-center text-sm rounded-full'>
          {userAvatarLetters}
        </div>
    </>
  );
};

export default UserAvatar;
