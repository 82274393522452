import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation, useQuery } from '@apollo/client';
import { IconCircleFilled } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { SingleValue } from 'react-select';
import { WageType } from '../../../../../__generated__/graphql';
import { UPDATE_PRODUCT_MANUFACTURING_WAGE } from '../../../../../api/mutations/quotations/product';
import { MANUFACTURING_WAGES_QUERY } from '../../../../../api/queries/settings';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import PopoutInput from '../../../../../layout/fields/PopoutInput';
import { setIsLoadingUpdate } from '../../../../../redux/quotationSlice';

interface Props {
  productId: ID,
  manufacturingWage: WageType,
  quotationId: ID,
  refetchQueries: InternalRefetchQueriesInclude,
  label?: string,
  className?: string,
}

export default function ManufacturingWageSelect(props: Props) {
  const { productId, manufacturingWage, quotationId, refetchQueries, label, className } = props;
  const [selectedWage, setSelectedWage] = useState(manufacturingWage);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    data: manufacturingWagesData,
  } = useQuery(MANUFACTURING_WAGES_QUERY);

  const [
    updateProductManufacturingWageMutation,
    {
      loading: updateProductManufacturingWageLoading,
    },
  ] = useMutation(UPDATE_PRODUCT_MANUFACTURING_WAGE, {
    refetchQueries: refetchQueries,
  });

  const tippyContent = manufacturingWage.isCurrent ? manufacturingWage.wageGroupLabel : `${manufacturingWage.wageGroupLabel}: ${t('obsolete value')} `;

  const options = manufacturingWagesData && manufacturingWagesData.manufacturingWages && manufacturingWagesData.manufacturingWages.response
    ? manufacturingWagesData.manufacturingWages.response.map((manWage) => (
      { value: manWage?.id, label: manWage?.label }) as SelectOption)
    : [];

  const handleChange = (selectedOption: SelectOption) => {
    updateProductManufacturingWageMutation({
      variables: {
        manufacturingWage: selectedOption.value as string,
        product: productId as string,
        quotation: quotationId as string,
      },
    });
  };

  useEffect(() => {
    setSelectedWage(manufacturingWage);
  }, [manufacturingWage]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(updateProductManufacturingWageLoading));
  }, [dispatch, updateProductManufacturingWageLoading]);

  return (
    <Tippy content={tippyContent} placement="left" >
      <div className='flex h-full'>
        <div className='grow h-full'>
          <PopoutInput
            value={options.find(option => option.value === selectedWage?.id)}
            options={options}
            onChange={handleChange as (value?: SingleValue<SelectOption>) => void}
            label={label}
            buttonLabel={manufacturingWage.costLabel as string}
            className={className}
          />
        </div>
        { !manufacturingWage.isCurrent &&
          <IconCircleFilled className='w-2 h-2 text-cyellow-500 self-start'/>
        }
      </div>
    </Tippy>

  );
}
