import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { QUOTATION_PDF_QUERY, QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { LoadingIndicator } from '../../../layout';
import GeneratePDFModal from './GeneratePDFModal';

interface Props {  
  handleClose: () => void,
}

export default function GeneratePDFModalWrapper(props: Props) {
  const { handleClose } = props;
  const { quotationId } = useParams();


  const {
    data: quotationPDFData,
  } = useQuery(QUOTATION_PDF_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
  });

  const {
    data: quotationData,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: quotationId as string,
    },
    skip: !quotationId,
  });
  
  if (quotationData && quotationPDFData) {
    return <GeneratePDFModal 
      handleClose={handleClose}
      quotationPDFData={quotationPDFData}
      quotationData={quotationData}
    />;
  } else {
    return <LoadingIndicator className="w-full flex h-56 justify-center items-center" />;
  }
}