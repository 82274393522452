import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_ITEM_QUANTITY } from '../../../../../api/mutations/quotations/item';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames } from '../../../../../helpers/utils';

interface Props {
  quantity: number | undefined;
  id: ID;
  refetchQueries: InternalRefetchQueriesInclude,
  label?: boolean,
}

interface FormValues {
  item: ID,
  quantity: number,
  quotation: ID,
}

export default function QuantityInput(props: Props) {
  const { quantity, id, refetchQueries, label } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const fieldName = 'quantity';

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .positive(t('Number must be positive'))
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    quantity: quantity as number,
    item: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError } = useQuotationItemInputHandler({
    mutation: UPDATE_ITEM_QUANTITY,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  return (
    <div
    className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'border-b  relative h-full flex items-center justify-center rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
    >
      {label && (
        <label
          htmlFor={fieldName}
          className=" absolute top-0 left-2 text-2xs text-cgray-400 cursor-text"
          onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
        >
          {t('Qty')}
        </label>
      )}
      <input
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        className="px-0.5 w-11 h-10 flex justify-end items-end rounded-t bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:outline-none focus:border-none text-sm text-right whitespace-nowrap overflow-hidden overflow-ellipsis"
        ref={inputRef}
        onFocus={() => setInputFocused(true)}
        onBlur={() => handleBlur()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
            (e.target as HTMLInputElement).blur();
          }
        }}
      />
    </div>
  );
}
