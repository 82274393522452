import { useTranslation } from 'react-i18next';
import { classNames } from '../../helpers/utils';
import LoadingIndicator from '../LoadingIndicator';

interface Props {
  mutationLoading: boolean,
  submitButtonText: string,
  cancelButtonText: string,
  handleClose: () => void,
  onSubmit?: () => void,
  wrapperClassName?: string,
  isSubmitButtonDisabled?: boolean,
}

function ModalButtons(props: Props) {
  const { mutationLoading, submitButtonText, cancelButtonText, handleClose, onSubmit, wrapperClassName, isSubmitButtonDisabled } = props;
  const { t } = useTranslation();

  return (
    <div className={wrapperClassName || 'mt-4 flex items-center justify-end w-full gap-x-8'}>
      <button
        onClick={() => !mutationLoading && handleClose()}
        type="button"
        className={classNames('w-1/5 h-10 flex justify-center items-center px-4 py-2 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500', 
          mutationLoading ? 'cursor-wait bg-cgray-300 text-white border-none' : 'hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300',
        )}
      >
        {t(cancelButtonText)}
      </button>
      <button
        disabled={isSubmitButtonDisabled || mutationLoading}
        type="submit"
        onClick={onSubmit ? () => onSubmit() : undefined}
        className={classNames('w-1/5 h-10 relative flex justify-center items-center px-4 py-2 rounded text-sm font-medium uppercase text-white bg-cblue-500 transition duration-150', 
          mutationLoading 
            ? 'cursor-wait bg-cgray-300 text-white' 
            : isSubmitButtonDisabled 
              ? 'bg-cgray-300'
              : 'hover:bg-cblue-700 hover:opacity-90 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100')}
      >
        {mutationLoading ? <LoadingIndicator color="white" className="flex justify-center items-center absolute w-full h-full top-0 right-0" /> : t(submitButtonText)}
      </button>
    </div>
  );
}

export default ModalButtons;
