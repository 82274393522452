import { IconChevronDown } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import ROGroupDetails from '../../../../../quotation/list/row/details/groupDetails/ROGroupDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';

interface Props {
  collapsed?: boolean;
  item: FlattenedItem;
  indentationWidth: number;
  onCollapse?(): void;
}

export default function GroupItem(props: Props) {
  const {
    indentationWidth, collapsed, onCollapse,
    item } = props;
  const [showDetails, setShowDetails] = useState(false);

  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
        className={classNames(styles.TreeItem, 'bg-cblue-200, relative')}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
            '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
            '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className={classNames('flex', onCollapse ? 'justify-between' : 'justify-end')}
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
        >
          {onCollapse && (
            <div onClick={onCollapse}>
              <IconChevronDown
                className={classNames(
                  styles.Collapse,
                  collapsed && styles.collapsed,
                  collapsed ? '-rotate-90' : 'rotate-0',
                  'transition-all duration-200 ease-in-out cursor-pointer ml-2 text-cblue-500',
                )}
              />
            </div>
          )}
        </div>
        <div className='w-14 py-3.5 flex items-center justify-between'>
          <div className='w-5'></div>

          <TreeItemIcon
            item={item}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
        </div>
        <>
          <div className="w-20"></div>
          <div className="w-24"></div>
          <div className="w-32"></div>
          <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 60} placement="top">
            <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{item?.group?.description}</div>
          </Tippy>
        </>
      </div>
      {showDetails && item.group && (
        <ROGroupDetails
          groupId={item.group.id}
          isGroupQuotation={item.group?.__typename === 'QuotationGroupType'}   
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
        />
      )}
    </li>
  );
}
