
interface Props {
  onClick?: () => void;
  className?: string;
}

export default function IconT(props: Props) {
  const { onClick, className } = props;

  return (
    <div 
      onClick={onClick}
      className={className}
    >
      <span>T</span>
    </div>
  );
}