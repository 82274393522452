import { gql } from '../../__generated__/gql';


export const SETTINGS_QUERY = gql(`
  query settings{
    settings{
      response{
        currencyCode
			  currencySymbol
      }
      errors{
        field
        messages
      }
    }
  }
`);


export const MANUFACTURING_WAGES_QUERY = gql(`
  query manufacturingWages{
    manufacturingWages{
      response{
        id
        costLabel
        wageGroup
        label
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const SALES_WAGES_QUERY = gql(`
  query salesWages{
    salesWages{
      response{
        id
        costLabel
        wageGroup
        label
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const POSITION_TYPES_QUERY = gql(`
  query positionTypes{
    positionTypes{
      response{
        value
        label
      }
      errors{
        field
        messages
      }
    }
  }
`);

export const SERVICE_WAGES_QUERY = gql(`
query serviceWages{
  serviceWages{
    response{
      id
      costLabel
      isCurrent
      label
      wageGroup
      wageGroupLabel
    }
    errors{
      field
      messages
    }
  }
}
`);
