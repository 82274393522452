import { gql } from '../../__generated__/gql';

export const LOGIN_MUTATION = gql(`
  mutation TokenAuth($email: String!, $password: String!){
      tokenAuth(email: $email, password: $password){
        token
        refreshToken
      }
  }
`);

export const REFRESH_JWT_MUTATION = gql(`
  mutation RefreshToken($refreshToken: String!){
    refreshToken(refreshToken: $refreshToken)
    {
      token
      refreshToken
    }
  }
  `);

export const REVOKE_REFRESH_TOKEN_MUTATION = gql(`
  mutation RevokeToken($refreshToken: String!){
    revokeToken(refreshToken: $refreshToken)
    {
      revoked
    }
  }
  `);
