import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../helpers/reduxHooks';
import CreateGroupForm from '../list/createForms/CreateGroupForm';

interface Props {
  handleClose: () => void,
}

export default function CreateGroupModal(props: Props) {
  const { handleClose } = props;
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const targetOrder = useAppSelector(state => state.quotation.newGroupOrder);
  const createNewItemInside = useAppSelector(state => state.quotation.createNewItemInside);
  const { t } = useTranslation();

  return (
      <div className='flex flex-col justify-between items-center rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
        <div className='pl-8 mb-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Create New Group')}</div>
        {createNewItemInside ? (
          <div className='pl-8 mb-8 w-full'>{t('This action will create new group inside current Group')}</div>
        ) : (
          <div className='pl-8 mb-8 w-full'>{t('This action will create new group in current Quotation')}</div>
        )}
        <CreateGroupForm
          handleClose={handleClose}
          targetOrder={targetOrder}
          parentId={createNewItemInside ? selectedItem?.id : selectedItem?.parentId}
        />
      </div>
  );
}
