import type { UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import type { FlattenedItem, TreeItem } from '../../components/shared/dnd/types';

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth);
}

export function getProjection(
  items: FlattenedItem[],
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
) {
  const overItemIndex = items.findIndex(({ id }) => id === overId);
  const activeItemIndex = items.findIndex(({ id }) => id === activeId);
  const activeItem = items[activeItemIndex];
  const newItems = arrayMove(items, activeItemIndex, overItemIndex);
  const previousItem = newItems[overItemIndex - 1];
  const nextItem = newItems[overItemIndex + 1];
  const dragDepth = getDragDepth(dragOffset, indentationWidth);
  const projectedDepth = activeItem.depth + dragDepth;
  const maxDepth = getMaxDepth({
    previousItem,
  });
  const minDepth = getMinDepth({ nextItem });
  let depth = projectedDepth;

  if (projectedDepth >= maxDepth) {
    depth = maxDepth;
  } else if (projectedDepth < minDepth) {
    depth = minDepth;
  }

  return { depth, maxDepth, minDepth, parentId: getParentId() };

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.id;
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find((item) => item.depth === depth)?.parentId;

    return newParent ?? null;
  }
}

function getMaxDepth({ previousItem }: { previousItem: FlattenedItem }) {
  let depth = 0;
  if (previousItem) {
    depth = previousItem.canHaveChildren === false
      ? previousItem.depth
      : previousItem.depth + 1;
  }
  return depth;
}

function getMinDepth({ nextItem }: { nextItem: FlattenedItem }) {
  if (nextItem) {
    return nextItem.depth;
  }

  return 0;
}

function flatten(
  items: TreeItem<FlattenedItem>[],
  parentId: UniqueIdentifier | null = null,
  depth = 0,
): FlattenedItem[] {
  return items.reduce<any>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index },
      ...flatten(item.children, item.id, depth + 1),
    ];
  }, []);
}

export function flattenTree(items: TreeItem<FlattenedItem>[]): FlattenedItem[] {
  return flatten(items);
}

export function buildTree(flattenedItems: FlattenedItem[]): TreeItem<FlattenedItem>[] {
  const root: TreeItem<FlattenedItem> = { id: 'root', children: [] };
  const nodes: Record<string, TreeItem<FlattenedItem>> = { [root.id]: root };
  const items = flattenedItems.map((item) => ({ ...item, children: [] }));

  for (const item of items) {
    const { id, children } = item;
    const parentId = item.parentId ?? root.id;
    const parent = nodes[parentId] ?? findItem(items, parentId);
    item.index = parent.children.length;
    nodes[id] = { id, children };
    parent.children.push(item);
  }

  return root.children;
}

export function findItem(items: TreeItem<FlattenedItem>[], itemId: UniqueIdentifier) {
  return items.find(({ id }) => id === itemId);
}

export function findItemDeep(
  items: TreeItem<FlattenedItem>[],
  itemId: UniqueIdentifier,
): TreeItem<FlattenedItem> | undefined {
  for (const item of items) {
    const { id, children } = item;

    if (id === itemId) {
      return item;
    }

    if (children.length) {
      const child = findItemDeep(children, itemId);

      if (child) {
        return child;
      }
    }
  }

  return undefined;
}

export function removeItem(items: FlattenedItem[], id: UniqueIdentifier) {
  const newItems = [];

  for (const item of items) {
    if (item.id === id) {
      continue;
    }

    if (item.children.length) {
      item.children = removeItem(item.children, id) as FlattenedItem[];
    }

    newItems.push(item);
  }

  return newItems;
}

export function setProperty<T extends keyof FlattenedItem>(
  items: FlattenedItem[],
  idsToProcess: UniqueIdentifier[],
  property: T,
  setter: (value: FlattenedItem) => boolean,
) :FlattenedItem[] {
  // map through items instead of modifying them directly
  return items.map(item => {
    // if this is the item we're looking for
    if (idsToProcess.includes(item.id)) {
      // return a new object with the modified property
      return {
        ...item,
        [property]: setter(item[property] as any),
      };
    }

    // if the item has children
    if (item.children.length) {
      // return a new object with the modified children
      return {
        ...item,
        children: setProperty(item.children, idsToProcess, property, setter) as FlattenedItem[],
      };
    }

    // if the item wasn't modified, return it as is
    return item;
  });
}

function countChildren(items: TreeItem<FlattenedItem>[], count = 0): number {
  return items.reduce((acc, { children }) => {
    if (children.length) {
      return countChildren(children, acc + 1);
    }

    return acc + 1;
  }, count);
}

export function getChildCount(items: TreeItem<FlattenedItem>[], id: UniqueIdentifier) {
  const item = findItemDeep(items, id);

  return item ? countChildren(item.children) : 0;
}

export function removeChildrenOf(
  items: FlattenedItem[],
  ids: UniqueIdentifier[],
) {
  const excludeParentIds = [...ids];

  return items.filter((item) => {
    if (item.parentId && excludeParentIds.includes(item.parentId)) {
      if (item.children.length) {
        excludeParentIds.push(item.id);
      }
      return false;
    }

    return true;
  });
}


export function findDeepestUncollapsedLevel(arr: FlattenedItem[], currentDepth = 0) {
  // Look for a deepest Tree branch level that has children and not collapsed
  let maxDepth = currentDepth;
  for (const item of arr) {
    // Check if item can have children and if collapsed attribute is not present or false
    if (item.canHaveChildren && (item.collapsed === undefined || item.collapsed === false)) {
      // Update maxDepth if currentDepth is greater
      maxDepth = Math.max(maxDepth, currentDepth);
      // If there are children, recurse into them
      if (item.children && item.children.length > 0) {
        maxDepth = Math.max(maxDepth, findDeepestUncollapsedLevel(item.children, currentDepth + 1));
      }
    }
  }
  return maxDepth;
}

export function getItemWidth(maximumQuotationUncollapsedDepth: number, indentationWidth: number, depth: number) {
  return (maximumQuotationUncollapsedDepth + 1) * indentationWidth - indentationWidth * depth;
}