import Tippy from '@tippyjs/react';
import React, { CSSProperties, Dispatch, SetStateAction, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WageType } from '../../../../../__generated__/graphql';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames, getListRowStyles } from '../../../../../helpers/utils';
import { setSelectedItem } from '../../../../../redux/quotationSlice';
import { Handle } from '../../../../shared/dnd/Item';
import styles from '../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../shared/dnd/types';
import { PositionInput, QuantityInput } from '../../fields/item';
import {
  DescriptionInput,
  ProfitSurchargeInput,
  ServiceTimeInput,
  ServiceWageSelect,
} from '../../fields/service';
import DefaultServiceDetailsEditable from '../details/DefaultServiceDetailsEditable';
import EstimateServiceDetails from '../details/EstimateServiceDetails';
import HoveredContextMenu from '../HoveredContextMenu';
import WrapperRowService from './WrapperRowService';

interface Props {
  clone?: boolean;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  ghost?: boolean;
  disableSelection?: boolean;
  disableInteraction?: boolean;
  depth: number;
  handleProps?: object;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
  hasDndContext?: boolean,
}

const DefaultRowService = forwardRef<HTMLDivElement, Props>(
  (
    {
      style, clone, depth, indentationWidth, indicator, item, showDetails, setShowDetails,
      wrapperRef, ghost, disableSelection, disableInteraction, handleProps, hasDndContext,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const copiedItem = useAppSelector(state => state.quotation.copiedItem);
    const selectedItem = useAppSelector(state => state.quotation.selectedItem);
    const itemToCut = useAppSelector(state => state.quotation.itemToCut);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dndGroupId = useAppSelector(state => state.quotation.dndGroupId);
    const dispatch = useAppDispatch();
    const { quotationId } = useParams();
    const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    const productPropsWrapper = {
      style, clone, depth, indentationWidth, indicator, ref,
      wrapperRef, ghost, disableSelection, disableInteraction,
    };

    const isEstimateMode = useAppSelector(state => state.quotation.isEstimateMode);

    return (
      <WrapperRowService {...productPropsWrapper} >
        <>
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            style={getListRowStyles(style, item, copiedItem, selectedItem, itemToCut, showDetails) as React.CSSProperties}
            onMouseEnter={() => setIsItemHovered(item.id.toString())}
            onMouseLeave={() => setIsItemHovered(null)}
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
            >
            </div>
            <div className='w-14 py-3.5 flex justify-between'>
              <TreeItemIcon
                item={item}
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
              {!!dndGroupId && !!hasDndContext && (
                <Handle {...handleProps} />
              )}
            </div>
            <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
              <PositionInput
                position={item.position}
                id={item.id}
                refetchQueries={
                  [{
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  }]
                }
              />
              <div className="w-16"></div>
              <QuantityInput
                quantity={item.quantity}
                id={item.id}
                refetchQueries={
                  [{
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  }]
                }
              />
              <div className="w-32"></div>
              <div className="w-96 3xl:w-[450px] 4xl:w-[800px] ">
                <DescriptionInput
                  refetchQueries={[{
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  }]}
                  description={item.service?.description as string}
                  id={item.service?.id}
                />
              </div>
              <div className="w-20">
                <ServiceWageSelect
                  serviceWage={item.service?.serviceWage as WageType}
                  id={item.service?.id}
                  quotationId={quotationId as ID}
                  refetchQueries={
                    [{
                      query: QUOTATION_QUERY,
                      variables: {
                        quotation: quotationId as string,
                      },
                    }]
                  }
                />
              </div>
              <Tippy content={t('Service time, per item')} placement="top" >
                <div className="w-12 h-10 relative">
                  <ServiceTimeInput
                    serviceTime={item.service?.serviceTime as number}
                    id={item.service?.id}
                    refetchQueries={
                      [{
                        query: QUOTATION_QUERY,
                        variables: {
                          quotation: quotationId as string,
                        },
                      }]
                    }
                  />
                </div>
              </Tippy>
            </div>
            <div className='flex items-center justify-between 3xl:grow'>
              <div className="w-16">
                <ProfitSurchargeInput
                  serviceId={item?.service?.id as ID}
                  profitSurcharge={item.service?.profitSurcharge}
                  quotationId={quotationId as ID}
                  refetchQueries={[
                    {
                      query: QUOTATION_QUERY,
                      variables: {
                        quotation: quotationId as string,
                      },
                    },
                  ]}
                />
              </div>
              <div className="w-16"></div>
              <div className="w-20"></div>
              <div className="pl-1 w-28">{item.service?.serviceComputation?.netSalesPricePieceLabel}</div>
              <div className="pl-1 w-28">{item.service?.serviceComputation?.totalSalesPriceLabel}</div>
            </div>
            {isItemHoveredId === item.id && (
              <HoveredContextMenu
                item={item}
                isItemHoveredId={isItemHoveredId}
                setIsItemHoveredId={setIsItemHovered}
                showDetails={showDetails}
                setShowDetails={setShowDetails}
              />
            )}
            {/* <div className="w-8 flex items-center"></div> */}
          </div>
          {(showDetails && !isEstimateMode) && (
            <DefaultServiceDetailsEditable
              item={item}
              indentationWidth={indentationWidth}
              depth={depth}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth}
            />
          )}
          {(showDetails && isEstimateMode) && (
            <EstimateServiceDetails
              serviceId={item.service?.id}
              indentationWidth={indentationWidth}
              depth={depth}
            />
          )}
        </>
      </WrapperRowService>
    );
  },
);

export default DefaultRowService;
