import { IconSearch, IconX } from '@tabler/icons-react';
import { useRef, useState } from 'react';
import type { GroupBase, InputAction, InputProps } from 'react-select';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useAppDispatch } from '../../helpers/reduxHooks';
import { classNames } from '../../helpers/utils';
import { setSearchHelp } from '../../redux/searchSlice';


interface Props {
  placeholder?: string,
  isMulti: boolean,
  onInput: (inputedValues: SelectOption[]) => void,
  value?: SelectOption[],
  isDisabled: boolean,
}

function ClearIndicator(props: any) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.ClearIndicator {...props}>
      <IconX className="mr-2 h-5 w-5 text-cgray-400 hover:text-cblue-500 cursor-pointer" />
    </components.ClearIndicator>
  );
}

function Input(props: JSX.IntrinsicAttributes & InputProps<unknown, boolean, GroupBase<unknown>>) {
  return (
    <components.Input {...props} 
      style={{
        color: '#282828',
        caretColor: '#005DA3',
        background: '0px center',
        opacity: 1,
        width: '200px',
        gridArea: '1 / 2 / auto / auto',
        minWidth: '2px',
        border: '0px',
        margin: '0px',
        outline: '0px',
        padding: '0px',
        boxShadow: 'none',
      }} 
    />
  );
}

function SearchInput(props: Props) {
  const { placeholder, isMulti, onInput, value, isDisabled } = props;
  const [selectFocused, setSelectFocused] = useState(false);
  const [inputText, setInputText] = useState('');
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  const searchIconClickHandler = () => {
    if (ref && ref.current) {
      const input = ref.current as HTMLElement;
      input.focus();
    }
    if (inputText !== '') {
      onInput([...(value as []), { value: inputText, label: inputText }] as SelectOption[]);
    }
  };
  
  const inputChangeHandler = (action: InputAction, prevInputValue: string) => {
    clearTimeout(timer);
    const handler = setTimeout(() => {
      dispatch(setSearchHelp(true));
    }, 1000);
    setTimer(handler);
    if (action === 'input-blur') setInputText(prevInputValue);
  };
 
  return (
    <div className="relative w-full">
      <div
        className={classNames(value?.length ? 'hidden' : 'flex', 'z-20 absolute inset-y-0 right-0 mr-3 items-center')}
        aria-hidden="true"
        onClick={searchIconClickHandler}
      >
        <IconSearch className={classNames(selectFocused ? 'text-cblue-500' : 'text-cgray-400', 'h-6 w-6 cursor-pointer')} aria-hidden="true" onClick={searchIconClickHandler} />
      </div>
      <CreatableSelect
        ref={ref}
        isDisabled={isDisabled}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator,
          Input,
          Menu: () => null,
        }}
        placeholder={placeholder}
        isMulti={!!isMulti}
        onChange={(inputedValues) => onInput(inputedValues as SelectOption[])}
        value={value}
        onInputChange={(_newValue, { action, prevInputValue })=>inputChangeHandler(action, prevInputValue)}
        blurInputOnSelect={false}
        className="mt-1"
        onFocus={() => setSelectFocused(true)}
        onBlur={() => setSelectFocused(false)}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            fontSize: '14px',
            backgroundColor: isFocused ? '#e9e9e9' : '#f4f4f4',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: 0,
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#e9e9e9',
              cursor: 'text',
            },
          }),
          placeholder: (styles) => ({
            ...styles,
            color: '#b2b2b2',
            fontWeight: 500,
            fontSize: 14,
          }),
          container: (styles) => ({
            ...styles,
            marginTop: 0,
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            padding: 0,
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingBottom: 0,
            boxSizing: 'border-box',
          }),
          multiValue: (styles) => ({
            ...styles,
            margin: '6px 1px 0 0',
            borderRadius: '4px',
            border: 'solid 1px',
            borderColor: '#e4e4e7',
            backgroundColor: 'white',
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            color: '#005DA3',
            padding: '1px 3px 1px',
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            padding: 0,
            color: '#b2b2b2',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#caddeb',
            primary50: '#caddeb',
          },
        })}
      />
      <div className={classNames(
        selectFocused ? 'bg-cblue-500' : 'bg-transparent',
        'absolute bottom-0 left-0 h-px w-full',
      )}/>
      {!!value?.length && (
        <label
          className={classNames(selectFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute top-0 left-2.5 text-2xs cursor-default')}
        >
          {placeholder}
        </label>
      )}
    </div>
  );
}

export default SearchInput;