import { useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Item, arrayToTree } from 'performant-array-to-tree';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateItemType } from '../../../__generated__/graphql';
import { TEMPLATE_SUGGESTIONS } from '../../../api/queries/groupTemplates';
import { flattenTree } from '../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../../layout';
import { setIsGroupSuggestionOpened } from '../../../redux/quotationSlice';
import { FlattenedItem } from '../../shared/dnd/types';
import SuggestionsListWrapper from './list/SuggestionsListWrapper';
import SuggestionsListHeader from './SuggestionsListHeader';

function GroupSuggestionOverlay() {
  const isGroupSuggestionOpened = useAppSelector(state => state.quotation.isGroupSuggestionOpened);
  const groupItemIdForSuggestion = useAppSelector(state => state.quotation.groupItemIdForSuggestion);
  const dispatch = useAppDispatch();
  const [deepestUncollapsedLevel, setDeepestUncollapsedLevel] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const {
    data: suggestionsData,
    loading: suggestionsLoading,
  } = useQuery(TEMPLATE_SUGGESTIONS, {
    variables: {
      item: groupItemIdForSuggestion,
      page: page,
    },
    skip: !groupItemIdForSuggestion,
  });

  const suggestionsResponse = suggestionsData?.templateSuggestions?.response;

  useEffect(() => {
    if (suggestionsResponse) {
      let allItems: Item[] = [];
      suggestionsResponse?.objects?.map(item => {
        allItems = [...allItems, ...(item?.items as [])];
      });
      const res = arrayToTree(allItems, { dataField: null });
      const flattenedTree = flattenTree(res as FlattenedItem[]);
      setDeepestUncollapsedLevel(Math.max(...flattenedTree.map(item => item.depth)));
    }
  }, [suggestionsResponse]);


  return (
    <Transition.Root show={isGroupSuggestionOpened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-40"
        onClose={() => dispatch(setIsGroupSuggestionOpened(false))}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-4/5 inset-y-0 right-0 max-w-full flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsGroupSuggestionOpened(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex gap-8 flex-col justify-start items-center h-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <div
                      className='shrink-0 pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'
                    >
                      {t('Group Templates Suggestions')}
                    </div>
                    <div className='w-full pl-8'>
                      <span>{t('The following group template(s) can be used instead of the current group:')}</span>
                    </div>
                    <div className='w-full px-10 flex flex-col gap-4'>
                        <SuggestionsListHeader
                          indentationWidth={50}
                          deepestUncollapsedLevel={deepestUncollapsedLevel}
                        />
                        
                      {suggestionsLoading && <LoadingIndicator className="w-full h-96 flex justify-center items-center" /> }
                      {
                        suggestionsResponse?.objects?.map(item =>
                          <SuggestionsListWrapper
                            objectsData={item?.items as TemplateItemType[]}
                            deepestUncollapsedLevel={deepestUncollapsedLevel}
                            key={item?.id}
                          />,
                        )
                      }
                      {
                        suggestionsResponse?.totalObjectsQuantity &&
                        <div className="h-14">
                          <Pagination
                            page={suggestionsResponse.page as number}
                            hasNext={suggestionsResponse.hasNext as boolean}
                            hasPrev={suggestionsResponse.hasPrev as boolean}
                            elidedPageRange={suggestionsResponse.elidedPageRange as string[]}
                            onPageChange={(e:number) => setPage(e)}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default GroupSuggestionOverlay;
