import i18n from 'i18next';
import TimeAgo from 'react-timeago';
//@ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
//@ts-ignore
import germanStrings from 'react-timeago/lib/language-strings/de';

const germanFormatter = buildFormatter(germanStrings);

interface Props {
  date: string,
}

function CustomTimeAgo(props: Props) {
  // We should consider the fact that language value can have multiple values with one
  // meaning like "en" an "en-EN".
  // It matters not though while only German is supposed to be used in production
  const { date } = props;
  switch (i18n.language) {
    case 'en':
      return <TimeAgo title="" date={date} />;
    default:
      return <TimeAgo title="" date={date} formatter={germanFormatter} />;
  }
}

export default CustomTimeAgo;
