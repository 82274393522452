import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../helpers/reduxHooks';
import { RobotIndicator } from '../../../layout';

interface Props {
  isQuotationHeader: boolean;
  indentationWidth: number;
  isLoading?: boolean;
}

export default function ListHeader(props: Props) {
  const { isQuotationHeader, indentationWidth, isLoading } = props;
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);
  const maximumQuotationUncollapsedDepth = useAppSelector(statusbar => statusbar.quotation.maximumQuotationUncollapsedDepth);
  const maximumUncollapsedDepth = isQuotationHeader ? maximumQuotationUncollapsedDepth : maximumSearchUncollapsedDepth;
  const { t } = useTranslation();

  return (
    <div className="flex text-sm">
      <div className="w-full flex items-center gap-6 bg-cblue-100 border-b-2 border-t-2 border-cblue-500 pt-2 pb-2 mb-3 font-medium text-sm text-cgray-600">
        <div
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, 0)}px` }}
        ></div>
        <div className='w-14 items-center justify-center'>
          <RobotIndicator isLoading={isLoading} />
        </div>
        <div className="w-12">{t('Supplier')}</div>
        <div className="w-32">{t('Product ID')}</div>
        <div className={isQuotationHeader ? 'w-[300px] 3xl:w-[360px] 4xl:w-[440px] truncate' : 'w-[400px] 3xl:w-[550px] 4xl:w-[800px] truncate'}>{t('Description')}</div>
      </div>
    </div>
  );
}
