import { useQuery } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import {
  IconX,
} from '@tabler/icons-react';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectEventType, QuotationNoteType, UserType } from '../../../__generated__/graphql';
import { PROJECT_LOGS_QUERY, PROJECT_LOG_RECORDS_SCOPE_QUERY } from '../../../api/queries/projects';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../../layout';
import { setIsProjectLogOverlayOpen } from '../../../redux/quotationSlice';
import CreateNoteForm from '../projectLog/CreateNoteForm';
import EventItem from '../projectLog/EventItem';
import NoteItem from '../projectLog/NoteItem';
import RecordsScopeSelect from '../projectLog/RecordsScopeSelect';


export default function ProjectLogOverlay() {
  const isProjectLogOverlayOpen = useAppSelector(state => state.quotation.isProjectLogOverlayOpen);
  const dispatch = useAppDispatch();
  const { projectId, quotationId } = useParams();
  const [page, setPage] = useState(1);
  const [scope, setScope] = useState<undefined | string>();

  const {
    data: scopeData, loading: scopeLoading,
  } = useQuery(PROJECT_LOG_RECORDS_SCOPE_QUERY);

  const scopeOptions = scopeData && scopeData.projectLogRecordsScope && scopeData.projectLogRecordsScope.response
    ? scopeData.projectLogRecordsScope.response.map((type) => (
      { value: type?.value, label: type?.label }) as SelectOption)
    : [];

  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const user = userData && userData.user;


  const {
    data: logsData,
    loading: logsLoading,
    refetch: logsRefetch,
  } = useQuery(PROJECT_LOGS_QUERY, {
    variables: {
      project: projectId as string,
      page: page,
      projectLogRecordsScope: scope,
    },
    skip: !projectId || !scope,
  });

  const logsResponse = logsData && logsData.projectLogs && logsData.projectLogs.response;
  const logs = logsResponse?.objects;

  const refetchQueriesOnUpdate = [
    {
      query: PROJECT_LOGS_QUERY,
      variables: {
        project: projectId, page: page, projectLogRecordsScope: scope,
      },
    },
  ];

  useEffect(() => {
    if (scopeOptions && scopeOptions.length && !scope) {
      setScope(scopeOptions[0].value as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeOptions]);

  useEffect(() => {
    // Fetch data on each overlay display
    if (isProjectLogOverlayOpen) {
      logsRefetch();
    }
  }, [isProjectLogOverlayOpen, logsRefetch]);

  return (
    <Transition.Root show={isProjectLogOverlayOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 overflow-hidden z-40 text-sm" onClose={() => dispatch(setIsProjectLogOverlayOpen(false))}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-2/5 inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsProjectLogOverlayOpen(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="px-4 pb-4 grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex justify-start items-start flex-col h-full w-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <CreateNoteForm
                      user={user as UserType}
                      quotationId={quotationId as string}
                      refetchQueries={refetchQueriesOnUpdate}
                    />
                    <div className='mt-4 w-32 ml-auto'>
                      <RecordsScopeSelect
                        value={scopeOptions.find(item => item.value === scope)}
                        options={scopeOptions}
                        handleChange={(e) => setScope(e.value as string)}
                        isLoading={scopeLoading}
                      />
                    </div>
                    <div className="flow-root w-full mt-4 h-full overflow-y-auto">
                      {logs ? (
                        <ul role="list" className="-mb-8">
                          {logs.map((item, itemIdx) => {
                            const typedItem = item as ProjectEventType | QuotationNoteType;
                            return (
                              <li key={typedItem.id}>
                                <div className="relative pb-8">
                                  {itemIdx !== logs.length - 1 ? (
                                    <span className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                  ) : null}
                                  <div className="relative flex items-start space-x-3">
                                    {typedItem.__typename === 'QuotationNoteType' && (
                                      <NoteItem
                                        user={user as UserType}
                                        item={typedItem}
                                        projectId={projectId as string}
                                        quotationId={quotationId as string}
                                        refetchQueries={refetchQueriesOnUpdate}
                                      />
                                    )}
                                    {typedItem.__typename === 'ProjectEventType' && (
                                      <EventItem
                                        item={typedItem}
                                        projectId={projectId as string}
                                        closeProjectLogs={() => dispatch(setIsProjectLogOverlayOpen(false))}
                                      />
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        logsLoading && <LoadingIndicator />
                      )}
                    </div>
                    <div className='flow-root w-full mt-8'>
                      <Pagination
                        page={logsResponse?.page as number}
                        elidedPageRange={logsResponse?.elidedPageRange as string[]}
                        hasNext={logsResponse?.hasNext as boolean}
                        hasPrev={logsResponse?.hasPrev as boolean}
                        onPageChange={(e) => setPage(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
