import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ProjectType } from '../../__generated__/graphql';
import { PROJECT_QUERY } from '../../api/queries/projects';
import { LoadingIndicator } from '../../layout';
import EditProjectDetailsForm from './EditProjectDetailsForm';

export default function EditProjectDetailsFormWrapper() {
  const { projectId } = useParams();  

  const {
    data: projectsData,
  } = useQuery(PROJECT_QUERY, {
    variables: {
      project: projectId as string,
    },
  });

  if (projectsData?.project?.response) {
    return (
      <EditProjectDetailsForm projectsData={projectsData.project.response as ProjectType} />
    );
  } else {
    return <LoadingIndicator className="w-full h-96 flex justify-center items-center" />;
  }
}
