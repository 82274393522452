import { useMutation } from '@apollo/client';
import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { DELETE_QUOTATION_NOTE_MUTATION } from '../../../api/mutations/quotations/note';

interface Props {
  handleClose: () => void;
  handleCancel: () => void;
  noteId: string;
  refetchQueries: InternalRefetchQueriesInclude;
}

export default function DeleteNoteInline(props: Props) {
  const { handleClose, refetchQueries, noteId, handleCancel } = props;
  const { t } = useTranslation();


  const [deleteNoteMutation] = useMutation(DELETE_QUOTATION_NOTE_MUTATION, {
    refetchQueries: refetchQueries,
  });

  const handleConfirm = () => {
    const variables = {
      note: noteId,
    };

    deleteNoteMutation({
      variables,
      onError: () => {
        handleClose();
      },
      onCompleted: () => handleClose(),
    },
    );

  };

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <div className="flex items-center sm:truncate sm:tracking-tight">
          <IconTrash className="h-6 w-6 text-red-400 mr-2" aria-hidden="true" />
          <div className='text-cgray-500'>{t('Delete this Note?')}</div>
        </div>
      </div>
      <div className="flex gap-x-4 justify-end mr-2">
        <button
          type="button"
          onClick={() => handleCancel()}
          className="flex justify-center py-2 px-2 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
        >
          {t('Cancel')}
        </button>
        <button
          type="button"
          onClick={handleConfirm}
          className="flex justify-center py-2 px-4 rounded text-sm font-medium uppercase text-white bg-cred-500 hover:bg-cred-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
        >
          {t('Delete')}
        </button>
      </div>
    </div>
  );

}
