import { useDroppable } from '@dnd-kit/core';
import { IconDragDrop2 } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../../helpers/utils';

export default function NoRecordsArea() {
  const { setNodeRef, isOver } = useDroppable({
    id: 'NoRecordsArea',
  });
  const { t } = useTranslation();

  return (
    <div
      ref={setNodeRef}
      className="flex items-center justify-center w-full"
    >
        <label className={classNames(isOver ? 'bg-cgray-200' : 'bg-cgray-100', 'flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg')}>
            <div className="flex items-center justify-center pt-5 pb-6 gap-x-4">
                <IconDragDrop2 className='w-16 h-16 text-cblue-500' />
                <div className='flex flex-col'>
                <p className="mb-2 text-cgray-500 dark:text-gray-400">{t('There are no records at the moment.')}</p>
                <p className="text-cgray-500 dark:text-gray-400">{t('Drag records here to attach them to this Quotation.')}</p>
                </div>
            </div>
        </label>
    </div> 
  );
}
