import { IconPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { FlattenedItem } from '../../shared/dnd/types';

interface Props {
  item: FlattenedItem,
  index: number,
  array: FlattenedItem[],
}

export default function GroupTotalSalesPrice(props: Props) {
  const { item, index, array } = props;
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex relative pr-2">
      <div className='min-h-[37px] flex justify-between items-center gap-2 grow'>
        <div className="font-bold">
          <span>{t('Total Sales Price')}</span>
        </div>
        <p className='text-cgray-400'>
          {item.group?.groupComputation?.totalSalesPriceLabel}
        </p>
      </div>
      {
        index !== array.length - 1 &&
        <IconPlus className='w-4 h-4 self-end absolute -bottom-2 right-4'/>
      }
    </div>
  );
}
