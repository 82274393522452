import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../helpers/reduxHooks';
import CreateServiceForm from '../list/createForms/CreateServiceForm';

interface Props {
  handleClose: () => void,
}

export default function CreateServiceModal(props: Props) {
  const { handleClose } = props;
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const targetOrder = useAppSelector(state => state.quotation.newServiceOrder);
  const createNewItemInside = useAppSelector(state => state.quotation.createNewItemInside);
  const { t } = useTranslation();

  return (
    <div className='flex flex-col justify-between items-center rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 mb-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>
        {t('Create New Service')}
      </div>
      {(!selectedItem?.parentId && !createNewItemInside) ? (
        <>
          <div className='mb-8'>{t('It is forbidden to add services to the root level')}</div>
          <button
            type="button"
            onClick={() => handleClose()}
            className="w-1/5 mb-8 flex justify-center py-2 px-4 border border-cblue-500 rounded text-sm font-medium uppercase text-cblue-500 hover:bg-cblue-200 focus:bg-cblue-300 active:bg-cblue-300"
          >
            {t('Cancel')}
          </button>
        </>
      ) : (
        <>
          {createNewItemInside ? (
            <div className='pl-8 mb-8 w-full'>{t('This action will create new service item inside current Group')}</div>
          ) : (
            <div className='pl-8 mb-8 w-full'>{t('This action will create new service item in the current Quotation')}</div>
          )}
            <CreateServiceForm
              handleClose={handleClose}
              targetOrder={targetOrder}
              parentId={createNewItemInside ? selectedItem?.id : selectedItem?.parentId}
            />
        </>
      )}
    </div>
  );
}
