import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import { Projects, SearchMultiple, SearchPaste, SearchSingle } from './pages';
import { Login } from './pages/auth';
import CreateProject from './pages/CreateProject';
import EditProject from './pages/EditProject';
import {
  Error400, Error401, Error403, Error404, Error500, Error503,
} from './pages/errorPages';
import Quotation from './pages/Quotation';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/projects" element={<Projects />} />
        <Route path="/search/multiple" element={<SearchMultiple />} />
        <Route path="/search/single" element={<SearchSingle />} />
        <Route path="/search/multiple/paste" element={<SearchPaste />} />
        <Route path="/project/create" element={<CreateProject />} />
        <Route path="/project/:projectId/edit" element={<EditProject />} />
        <Route path="/quotation/" element={<Quotation />} />
        <Route path="/quotation/:projectId/" element={<Quotation />} />
        <Route path="/quotation/:projectId/:quotationId" element={<Quotation />} />
        <Route path="/authentication/login" element={<Login />} />
        <Route path="/400" element={<Error400 />} />
        <Route path="/401" element={<Error401 />} />
        <Route path="/403" element={<Error403 />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/500" element={<Error500 />} />
        <Route path="/503" element={<Error503 />} />
        <Route path="*" element={<Navigate replace to="/projects/" />} />
      </Routes>
    </Router>
  );
}

export default App;
