import { useQuery } from '@apollo/client';
import { IconPencil } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProjectType } from '../__generated__/graphql';
import { PAGINATED_PROJECTS_QUERY } from '../api/queries/projects';
import ProjectManagerSelect from '../components/projects/fields/ProjectManagerSelect';
import ProjectSearchInput from '../components/projects/fields/ProjectSearchInput';
import SelectProjectStatus from '../components/projects/fields/SelectProjectStatus';
import ProjectCard from '../components/projects/ProjectCard';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { useDebounceValue } from '../helpers/customHooks';
import { PaginationData, createPaginationData } from '../helpers/PaginationUtils';
import { useAppDispatch } from '../helpers/reduxHooks';
import { LoadingIndicator, Navbar, Pagination } from '../layout';
import { discardAlert, setErrorAlert } from '../redux/alertSlice';

export default function Projects() {
  const { t } = useTranslation();
  const [projectSearchInputValue, setProjectSearchInputValue] = useState<string>('');
  const debouncedSearchInput = useDebounceValue(projectSearchInputValue, 200);
  const [projectPage, setProjectPage] = useState<number>(1);
  const [projectStatus, setProjectStatus] = useState<SelectOption>();
  const [managers, setManagers] = useState<SelectOption[]>([]);
  const dispatch = useAppDispatch();

  const {
    data: projectsData,
    loading: loadingData,
  } = useQuery(PAGINATED_PROJECTS_QUERY, {
    variables: {
      searchInput: debouncedSearchInput as string,
      page: projectPage,
      projectStatus: projectStatus?.value as string,
      managers: managers.map(manager => manager.value as string),
    },
    fetchPolicy: 'network-only',
  });
  
  const navigate = useNavigate();

  const handleProjectClick = (project: ProjectType) => {   
    if (project.quotations?.length) {
      dispatch(discardAlert());
      const latestQuotationId = project?.quotations[0]?.id || '';
      navigate(`/quotation/${project?.id}/${latestQuotationId}`);
    } else {
      dispatch(setErrorAlert([t('Quotation not found')]));
    }
  };
  const handleEditClick = (project: ProjectType) => {   
    navigate(`/project/${project?.id}/edit`);
  };

  const paginationData = createPaginationData(projectsData?.paginatedProjects?.response as PaginationData);
  
  return (
    <div className="relative flex flex-wrap min-h-screen">
      <div className="w-full">
        <Navbar />
        <div className='pt-5 px-4 flex gap-5 w-full items-center'>
          <div className='w-56 h-11'>
            <SelectProjectStatus
              projectStatus={projectStatus}
              setProjectStatus={setProjectStatus}
            />
          </div>
          <div className='flex-shrink h-11'>
            <ProjectManagerSelect
              managers={managers}
              setManagers={setManagers}
            />
          </div>
          <ProjectSearchInput 
            onChange={inputedValues=>setProjectSearchInputValue(inputedValues)} 
            placeholder={`${t('Search')}`} 
            value ={projectSearchInputValue} 
          />

        </div>
        <div className="pt-5">
          <ReduxAlertWrapper />
          <main className="ml-4 mr-4 flex flex-col justify-between gap-10">
            {
              !loadingData
                ?
              <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {projectsData?.paginatedProjects?.response?.objects?.length 
                  ? 
                  projectsData.paginatedProjects.response.objects.map((project) => (
                  <li 
                    key={project?.id} 
                    className="col-span-1 divide-y divide-cgray-200 rounded-lg bg-white shadow cursor-pointer flex"
                  >
                    <ProjectCard project={project as ProjectType} handleClick={handleProjectClick} />
                    <Tippy content={t('Edit project')} placement="top">
                      <div 
                        className="flex items-center justify-center w-12 h-full group !border-t-0 border-l-2 border-cgray-500"
                        onClick={()=>handleEditClick(project as ProjectType)}
                      >
                        <IconPencil className="fill-white group-hover:fill-cblue-500 stroke-cblue-500" />
                      </div>
                    </Tippy>
                  </li>
                  ))
                  :
                  <div className="col-span-3 pt-64 flex items-center justify-center text-3xl text-cgray-400">
                    {t('No results found')}
                  </div>
                }
              </ul>
                :
              <LoadingIndicator className="w-full h-96 flex justify-center items-center" />
            }
            {
            paginationData?.elidedPageRange?.length > 1 &&
            <div className="h-14">
              <Pagination
                page={paginationData.page}
                hasNext={paginationData.hasNext}
                hasPrev={paginationData.hasPrev}
                elidedPageRange={paginationData.elidedPageRange}
                onPageChange={(e:number) => setProjectPage(e)}
              />
            </div>
          }
          </main>
        </div>
      </div>
    </div>
  );
}
