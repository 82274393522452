import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationsQuotationPdfVersionTypeChoices } from '../../../../../__generated__/graphql';

interface Props {
  isLoadingUpdate: boolean;
  layoutType: string | undefined;
  setLayoutType: React.Dispatch<SetStateAction<QuotationsQuotationPdfVersionTypeChoices | undefined>>;
  
}

export const SelectLayoutPDF = (props: Props) => {
  const { isLoadingUpdate, layoutType, setLayoutType } = props;

  const { t } = useTranslation();

  return (
    <div className='w-full px-8 mb-8'>
      <div className="border-2 border-cgray-200 divide-y divide-cgray-200 overflow-hidden rounded-lg bg-cgray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
        <div 
          onClick={() => !isLoadingUpdate && setLayoutType(QuotationsQuotationPdfVersionTypeChoices.Basic)}
          className={`rounded-tl-lg rounded-tr-lg sm:rounded-tr-none group relative p-6 hover:bg-cgray-100 ${layoutType === QuotationsQuotationPdfVersionTypeChoices.Basic ? 'ring-2 ring-cblue-500 ring-inset bg-cgray-100' : 'bg-white '}`}
        >
          <div className="mt-2">
            <h3 className="text-base font-semibold leading-6 text-cgray-900">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                {t('Basic Price and Quantity')}
              </a>
            </h3>
            <p className="mt-2 text-sm text-cgray-500">{t('Price and quantity at the Root Level, quantity at Level 1, and description at Level 2')}</p>
          </div>
        </div>
        <div 
          onClick={() => !isLoadingUpdate && setLayoutType(QuotationsQuotationPdfVersionTypeChoices.Standard)}
          className={`sm:rounded-tr-lg group relative p-6 hover:bg-cgray-100 ${layoutType === QuotationsQuotationPdfVersionTypeChoices.Standard ? 'ring-2 ring-cblue-500 ring-inset bg-cgray-100' : 'bg-white '}`}
        >
          <div className="mt-2">
            <h3 className="text-base font-semibold leading-6 text-cgray-900">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                {t('Standard Price and Quantity')}
              </a>
            </h3>
            <p className="mt-2 text-sm text-cgray-500">{t('Price and quantity at the Root Level and Level 1, quantity at Level 2, and description at Level 3')}</p>
          </div>
        </div>
        <div 
          onClick={() => !isLoadingUpdate && setLayoutType(QuotationsQuotationPdfVersionTypeChoices.Advanced)}
          className={`sm:rounded-bl-lg group relative p-6 hover:bg-cgray-100 ${layoutType === QuotationsQuotationPdfVersionTypeChoices.Advanced ? 'ring-2 ring-cblue-500 ring-inset bg-cgray-100' : 'bg-white '}`}
        >
          <div className="mt-2">
            <h3 className="text-base font-semibold leading-6 text-cgray-900">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                {t('Advanced Price and Quantity')}
              </a>
            </h3>
            <p className="mt-2 text-sm text-cgray-500">{t('Price and quantity at the Root Level, Level 1, and Level 2, quantity at Level 3, and description at Level 4')}</p>
          </div>
        </div>
        <div 
          onClick={() => !isLoadingUpdate && setLayoutType(QuotationsQuotationPdfVersionTypeChoices.Comprehensive)}
          className={`rounded-bl-lg rounded-br-lg sm:rounded-bl-none group relative p-6 hover:bg-cgray-100 ${layoutType === QuotationsQuotationPdfVersionTypeChoices.Comprehensive ? 'ring-2 ring-cblue-500 ring-inset bg-cgray-100' : 'bg-white '}`}
        >
          <div className="mt-2">
            <h3 className="text-base font-semibold leading-6 text-cgray-900">
              <a href="#" className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                {t('Comprehensive Price and Quantity')}
              </a>
            </h3>
            <p className="mt-2 text-sm text-cgray-500">{t('Price and quantity at the Root Level, Level 1, Level 2, and Level 3, quantity at Level 4, and description at Level 5')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};