import { classNames } from '../../helpers/utils';

interface Props {
  onClick?: () => void,
  className?: string,
}

export default function IconPMultiple(props: Props) {
  const { onClick, className } = props;

  const dynamicSize = className?.split(' ')
    .filter(item => item.includes('h-') || item.includes('w-'))
    .join(' ');

  return (
    <div className="relative mr-5">
      <div
        onClick={onClick}
        className={classNames(className ? className : '', 'z-10 absolute top-0 left-0')}
      >
        <span>P</span>
      </div>
      
      <div className={classNames(dynamicSize ? dynamicSize : '', 'absolute top-[3px] left-[3px] border-2 rounded border-cgray-600')} />
    </div>
  );
}
