import { IconArrowNarrowRight } from '@tabler/icons-react';

interface Props {
  className?: string;
  onClick?: () => void;
}

export default function IconGroupInside(props: Props) {
  const { className, onClick } = props;

  return (
    <div onClick={onClick} className={className}>
      <div className="flex h-full items-center justify-center">
        <div className="h-full">G</div>
        <IconArrowNarrowRight className='w-3' />
      </div>
    </div>
  );
}
