import { gql } from '../../../__generated__/gql';

export const UPDATE_PRODUCT_POSITION_TYPE = gql(`
  mutation updateProductPositionType(
    $positionType: String!
    $product: ID!
    $quotation: ID!
  ){
    updateProductPositionType(
      input: {
        positionType: $positionType
        product: $product
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);


export const UPDATE_SALES_TIME = gql(`
  mutation updateProductSalesTime(
    $salesTime: Int!
    $product: ID!
    $quotation: ID!
    ){
      updateProductSalesTime(
        input: {
          salesTime: $salesTime
          product: $product
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_MANUFACTURING_TIME = gql(`
  mutation updateProductManufacturingTime(
    $manufacturingTime: Int!
    $product: ID!
    $quotation: ID!
    ){
      updateProductManufacturingTime(
        input: {
          manufacturingTime: $manufacturingTime
          product: $product
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_PROFIT_SURCHARGE = gql(`
  mutation updateProductProfitSurcharge(
    $product: ID!
    $profitSurcharge: Decimal!
    $quotation: ID!
  ){
    updateProductProfitSurcharge(
      input: {
        product: $product
        profitSurcharge: $profitSurcharge
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_SPECIFIC_SURCHARGE = gql(`
  mutation updateProductSpecificSurcharge(
    $product: ID!
    $specificSurcharge: Decimal!
    $quotation: ID!
  ){
    updateProductSpecificSurcharge(
      input: {
        product: $product
        specificSurcharge: $specificSurcharge
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_SHIPPING_COST = gql(`
  mutation updateProductShippingCost(
    $product: ID!
    $shippingCost: Decimal!
    $quotation: ID!
  ){
    updateProductShippingCost(
      input: {
        product: $product
        shippingCost: $shippingCost
        quotation: $quotation
      }
    ){ 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_MANUFACTURING_WAGE = gql(`
  mutation updateProductManufacturingWage(
    $manufacturingWage: ID!
    $product: ID!
    $quotation: ID!
    ){
      updateProductManufacturingWage(
        input: {
          manufacturingWage: $manufacturingWage
          product: $product
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_SALES_WAGE = gql(`
  mutation updateProductSalesWage(
    $salesWage: ID
    $product: ID!
    $quotation: ID!
    ){
      updateProductSalesWage(
        input: {
          salesWage: $salesWage
          product: $product
          quotation: $quotation
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const UPDATE_PRODUCT_PRICE_SUGGESTION = gql(`
  mutation updateProductPriceSuggestion(
    $product: ID!
    ){
      updateProductPriceSuggestion(
        input: {
          product: $product
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);

export const CREATE_QUOTATION_PRODUCT = gql(`
  mutation createQuotationProduct(
    $quotation: ID!
    $description: String!
    $order: Int!
    $parent: ID!
    $quantity: Int!
    $price: Decimal!
    ){
      createQuotationProduct(
        input: {
          quotation: $quotation,
          description: $description,
          order: $order,
          parent: $parent,
          quantity: $quantity,
          price: $price,
        }
      )
    { 
      response
      errors{
        field
        messages
      }
    }
  }
`);