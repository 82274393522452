import { useTranslation } from 'react-i18next';
import { prepareOptionsForStatusSelect } from '../../../helpers/utils';
import SelectInput from '../../../layout/fields/SelectInput';


interface Props {
  projectStatus: SelectOption | undefined,
  setProjectStatus: (status: SelectOption | undefined) => void,
}

export default function SelectProjectStatus(props: Props) {
  const { projectStatus, setProjectStatus } = props;
  const { t } = useTranslation();

  const handleChange = (option: SelectOption) => {
    setProjectStatus(option);
  };

  return (
    <SelectInput
      value={projectStatus}
      options={prepareOptionsForStatusSelect()}
      placeholder={t('Project Status')}
      onChange={handleChange}      
      showColorDot
      isClearable
    />
  );
}
