import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  GroupManualSalesPricePieceType,
} from '../../../../../../../__generated__/graphql';
import { UPDATE_GROUP_MANUAL_SALES_PRICE_MUTATION } from '../../../../../../../api/mutations/quotations/group';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import SelectInput from '../../../../../../../layout/fields/SelectInput';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';


interface Props {
  groupManualSalesPricePiece: GroupManualSalesPricePieceType,
  refetchQueries: InternalRefetchQueriesInclude,
  options: SelectOption[],
}


export default function GroupManualPriceTypeSelect(props: Props) {
  const { groupManualSalesPricePiece, refetchQueries, options } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    updateProductManufacturingWageMutation,
  ] = useMutation(UPDATE_GROUP_MANUAL_SALES_PRICE_MUTATION, {
    refetchQueries: refetchQueries,
  });



  const handleChange = (e: SelectOption) => {
    dispatch(setIsLoadingUpdate(true));
    updateProductManufacturingWageMutation(
      {
        variables: {
          value: groupManualSalesPricePiece.value,
          groupManualSalesPricePiece: groupManualSalesPricePiece.id as string,
          priceType: e.value as string,
        },
      },
    );
  };

  return (
    <SelectInput
      value={options.find(option => option.value === groupManualSalesPricePiece.priceType)}
      options={options}
      placeholder={t('Price type')}
      onChange={handleChange}
    />
  );
}
