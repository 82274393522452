import { IconMinus, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../helpers/reduxHooks';
import { classNames } from '../../../helpers/utils';
import { FlattenedItem } from '../../shared/dnd/types';
import AddArrowWrapper from '../list/row/details/AddArrowWrapper';
import GroupName from './GroupName';
import GroupTotalSalesPrice from './GroupTotalSalesPrice';
import { getSubTotalRowStyles } from './utils';

interface Props {
  items: FlattenedItem[],
  totalSalesPriceLabel: string,
}

export default function ROQuotationSummary(props: Props) {
  const { items, totalSalesPriceLabel } = props;
  const { t } = useTranslation();
  const showFullSummary = useAppSelector(state => state.quotation.showFullSummary);

  return (
    <div 
      id="styled-scroll" 
      className={classNames(showFullSummary && 'mt-4', 'px-2.5 py-[5px] flex items-center justify-between rounded overflow-x-auto')}
    >
      {
        showFullSummary
          &&
          <div className='flex w-full h-full text-sm'>
            <AddArrowWrapper nestingLevel={items.length > 11 ? 1 : 2}>
              <div className="w-full flex gap-y-2 border border-cgray-400">
                <div className='w-full flex flex-col'>
                  {items.map((item, index, array) => (
                    item.group && (
                      <GroupName 
                        key={item.id as number} 
                        index={index} 
                        item={item} 
                        array={array} 
                        arrowNestingLevel={items.length === 1 ? 3 : 4} 
                      />
                    )
                  ))}
                </div>
                <div className='w-full flex flex-col'>
                  {items.map((item, index, array) => (
                    item.group && (
                      <div key={item.id} className='flex w-full h-full'>
                        <AddArrowWrapper nestingLevel={items.length === 1 ? 3 : 4}>
                          <div className={getSubTotalRowStyles(index, array)}>
                            <div>
                              <div className='flex font-bold gap-x-1'>
                                <span>{t('Net Sales Price/Piece')}</span>
                              </div>
                              <div>
                                <span className='text-cgray-400'>
                                  {item.group?.groupComputation?.netSalesPricePieceLabel}
                                </span>
                              </div>
                            </div>
                            <div className='h-full flex items-center  gap-2'>
                              <IconMinus className='w-4' />
                              <div className="relative px-2 w-24 h-full flex items-center justify-end rounded-t border-l border-cgray-400">
                                <p className='absolute top-0 left-2 text-2xs text-cgray-400'>{t('Discount')}</p>
                                <p className='text-end'>{item.group?.discount}<span className='text-cgray-400'> %</span></p>
                              </div>
                            </div>
                          </div>
                        </AddArrowWrapper>
                      </div>
                    )
                  ))}
                </div>
                <div className='w-full flex flex-col'>
                  {items.map((item, index, array) => (
                    item.group && (
                      <div key={item.id} className='flex w-full h-full'>
                        <AddArrowWrapper nestingLevel={items.length === 1 ? 3 : 4}>
                          <div className={getSubTotalRowStyles(index, array)}>
                            <div>
                              <div className='flex font-bold gap-x-1'>
                                <span>{t('Net Sales Price/Piece including discount')}</span>
                              </div>
                              <div>
                                <span className='text-cgray-400'>
                                  {item.group?.groupComputation?.netSalesPricePieceIncludingDiscountLabel}
                                </span>
                              </div>
                            </div>
                            <div className='h-full flex items-center justify-between gap-2'>
                              <IconX className='w-4' />
                              <div className="relative px-2 w-14 h-full flex items-center justify-end rounded-t border-l border-cgray-400">
                                <p className='absolute top-0 left-2 text-2xs text-cgray-400'>{t('Qty')}</p>
                                <p className='text-end'>{item.quantity}</p>
                              </div>
                            </div>
                          </div>
                        </AddArrowWrapper>
                      </div>
                    )
                  ))}
                </div>
                <div className='w-full flex flex-col'>
                  {items.map((item, index, array) => (
                    item.group &&
                    <GroupTotalSalesPrice 
                      key={item.id as number} 
                      index={index} 
                      item={item} 
                      array={array} 
                    />
                  ))}
                </div>
              </div>
            </AddArrowWrapper>

            <div className={classNames(items.length > 11 ? '-ml-5' : '-ml-4', ' pl-8 pr-2 min-w-[320px] flex items-center border border-cgray-400 rounded')}>
              <div className='w-full flex flex-col gap-2'>
                <div className='w-full flex items-center justify-between font-bold text-lg'>
                  <p>{t('Total')}</p>
                  <p className='text-cgray-400'>{totalSalesPriceLabel}</p>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
}
