export interface PaginationData {
  page: number,
  hasNext: boolean,
  hasPrev: boolean,
  elidedPageRange: string[],
}

export const createPaginationData = (data: PaginationData) => {
  return {
    page: data?.page as number,
    hasNext: data?.hasNext as boolean,
    hasPrev: data?.hasPrev as boolean,
    elidedPageRange: data?.elidedPageRange as string[],
  };
};
  