import { IconClock } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { ProjectEventType, ProjectsProjectEventEventTypeChoices } from '../../../__generated__/graphql';
import colorsConfig from '../../../colorsConfig';
import { toTitleCase } from '../../../helpers/utils';
import TimeAgo from '../../../layout/TimeAgo';

interface Props {
  item: ProjectEventType;
  projectId: string;
  closeProjectLogs: () => void;
}

const getDotStyle = (item: ProjectEventType) => {
  if (item.eventType === ProjectsProjectEventEventTypeChoices.ProductionUnitStatusUpdated) {
    return { fill: item.quotationProductionUnitStatus?.color };
  } else {
    const colorMap = {
      'cgray-300': ProjectsProjectEventEventTypeChoices.Other,
      'cred-300': ProjectsProjectEventEventTypeChoices.RevisionRequired,
      'cblue-500': [
        ProjectsProjectEventEventTypeChoices.ProposalSent,
        ProjectsProjectEventEventTypeChoices.ProjectCreated,
      ],
      'cgreen-500': ProjectsProjectEventEventTypeChoices.ProjectApproved,
      'cblue-700': ProjectsProjectEventEventTypeChoices.ProjectCompleted,
      'cyellow-500': ProjectsProjectEventEventTypeChoices.ProjectProductionStarted,
      'cred-500': ProjectsProjectEventEventTypeChoices.ProjectRejected,
      'cblue-300': ProjectsProjectEventEventTypeChoices.QuotationCreated,
    };
    for (const [colorKey, eventTypes] of Object.entries(colorMap)) {
      if (Array.isArray(eventTypes) && eventTypes.includes(item.eventType)) {
        //@ts-ignore
        return { fill: colorsConfig[colorKey] };
      } else if (eventTypes === item.eventType) {
        //@ts-ignore
        return { fill: colorsConfig[colorKey] };
      }
    }
  
    return null; // or a default color
  }
};

const EventItem = (props: Props) => {
  const { item, projectId, closeProjectLogs } = props;
  const navigate = useNavigate();

  const dotStyle = getDotStyle(item);

  const handleQuotationClick = (quotationId: string) => {
    navigate(`/quotation/${projectId}/${quotationId}/`);
    closeProjectLogs();
  };
  // We have to explicitly write all tailwind classes like this
  // to Tailwind GIT climpiler be able to parse those classes.

  const createdByString = item.triggeredBy?.fullName ? `by ${item.triggeredBy.fullName}` : '';
  return (

    <>
      <div>
        <div className="relative px-1">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-cgray-100 ring-8 ring-white">
            <div className="shadow-lg flex h-8 w-8 items-center justify-center rounded-full bg-cblue-100">
              <svg
                className='h-4 w-4'
                style={dotStyle || undefined}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <div className="text-sm text-cgray-500">
          {item.eventType === ProjectsProjectEventEventTypeChoices.ProductionUnitStatusUpdated
            ? (
              `${item?.quotationGroup?.description} changed Production status to ${item.quotationProductionUnitStatus?.title}`
            )
            : (
              toTitleCase(item.eventType)
            )
          }
          {' '}
          {createdByString}
          {' on '}
          <span
            onClick={() => handleQuotationClick(item.quotation?.id)}
            className="cursor-pointer rounded bg-cblue-100 px-2 py-1 text-sm font-semibold text-cblue-500 shadow-sm"
          >
            {item.quotation?.name}
          </span>

          <div className="mt-0.5 text-xs text-cgray-400 flex">
            <IconClock className="h-4 w-4 mr-1 text-cgray-400" aria-hidden="true" />
            <TimeAgo date={item.createdAt} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EventItem;
