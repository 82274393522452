import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { classNames } from '../../../../../../helpers/utils';
import HoveredContextMenu from '../../../../../search/searchMultiple/list/HoveredContextMenu';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import DefaultProductDetailsReadOnly from '../../../../list/row/details/RODefaultProductDetails';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
  deepestUncollapsedLevel: number;
}

function ProductItem(props: Props) {
  const { indentationWidth, item, deepestUncollapsedLevel } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
      onMouseEnter={() => setIsItemHovered(item.id.toString())}
      onMouseLeave={() => setIsItemHovered(null)}
    >
      <div
          className={classNames(styles.TreeItem, 'bg-white', 'relative')}
          style={
            {
              '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
              '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--justifyContent': 'start',
            } as React.CSSProperties
          }
          >

          <div
            className='shrink-0 flex justify-end'
            style={{ width: `${getItemWidth(deepestUncollapsedLevel, indentationWidth, item.depth)}px` }}
          >
          </div>
          <div className='w-14 shrink-0 py-3.5 flex items-center justify-between'>
            <TreeItemIcon 
              item={item} 
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
          </div>
          <div className="w-20 shrink-0">{item.product?.unit}</div>
          <div className="w-24 shrink-0">{item.product?.supplierLabel}</div>
          <div className="w-32 shrink-0">{item.product?.externalId}</div>
          <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 40} placement="top">
            <div className="w-80 shrink-0 truncate pr-3">{item?.product?.description}</div>
          </Tippy>
          {isItemHoveredId === item.id && (
              <HoveredContextMenu 
                item={item} 
                isItemHoveredId={isItemHoveredId} 
                setIsItemHoveredId={setIsItemHovered} 
                showDetails={showDetails} 
                setShowDetails={setShowDetails} 
              />
          )}
        </div>
        {showDetails && (
          <DefaultProductDetailsReadOnly
            externalProductId={item?.product?.externalId as ID}
            catalogId={item?.product?.catalogId as ID}
            indentationWidth={indentationWidth}
            depth={item.depth}
            maximumUncollapsedDepth={deepestUncollapsedLevel}
          />
        )}
      </li>
  );
}

export default ProductItem;
