import { useMutation } from '@apollo/client';
import { Menu, Transition } from '@headlessui/react';
import { IconDotsVertical, IconPlus, IconRefresh } from '@tabler/icons-react';
import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RESET_QUOTATION_POSITIONS } from '../../../api/mutations/quotations/quotation';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { classNames } from '../../../helpers/utils';
import { IconG, IconPMultiple } from '../../../layout/icons';
import { discardAlert } from '../../../redux/alertSlice';
import {
  openCreateGroupModal,
  setIsLoadingUpdate,
  setShowCreateQuotationModal,
} from '../../../redux/quotationSlice';
import {
  setDestinationProjectId,
  setDestinationQuotationId,
  setRedirectToQuotation,
} from '../../../redux/searchSlice';
import GeneratePDF from './GeneratePDF';

interface Props {
  quotationName: string,
  isQuotationReadOnly: boolean,
}
export default function ContextMenu(props: Props) {
  const { isQuotationReadOnly, quotationName } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId, quotationId } = useParams();
  const { t } = useTranslation();

  const targetOrder = useAppSelector(state => state.quotation.quotationItemsTreeLength);

  const [
    resetQuotationPositionsMutation,
    {
      loading: resetQuotationPositionsLoading,
    },
  ] = useMutation(RESET_QUOTATION_POSITIONS, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  useEffect(() => {
    dispatch(setIsLoadingUpdate(resetQuotationPositionsLoading));
  }, [dispatch, resetQuotationPositionsLoading]);

  const handleAddProduct = () => {
    if (projectId && quotationId) {
      dispatch(setRedirectToQuotation(true));
      dispatch(setDestinationQuotationId(quotationId));
      dispatch(setDestinationProjectId(projectId));
      navigate('/search/multiple/');
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="flex items-center rounded-full text-cblue-500 hover:text-cblue-700  focus:outline-none focus:ring-2 focus:ring-cblue-300 focus:ring-offset-2 focus:ring-offset-cgray-100">
        <span className="sr-only">{t('Open options')}</span>
        <IconDotsVertical className="h-5 w-5" aria-hidden="true" id="iconDotsVertical" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-72 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-cgray-100 text-cgray-800' : 'text-cgray-600',
                    'flex gap-x-2 px-4 py-2 text-sm cursor-pointer',
                  )}
                  onClick={() => {
                    dispatch(discardAlert());
                    dispatch(setShowCreateQuotationModal(true));
                  }
                  }
                >
                  <IconPlus className={'h-5 w-5 shrink-0 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center font-bold rounded cursor-pointer'} />
                  {t('Create copy of current Quotation')}
                </div>
              )}
            </Menu.Item>
            {!isQuotationReadOnly && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-cgray-100 text-cgray-800' : 'text-cgray-600',
                        'flex gap-x-2 px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => { 
                        dispatch(discardAlert());
                        handleAddProduct(); 
                      }}
                    >
                      <IconPMultiple className={'h-5 w-5 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center font-bold rounded'} />
                      {t('Add Multiple Products')}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-cgray-100 text-cgray-800' : 'text-cgray-600',
                        'flex gap-x-2 px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => {
                        dispatch(discardAlert());
                        dispatch(openCreateGroupModal(
                          { targetOrder: targetOrder },
                        ));
                      }}
                    >
                      <IconG className={'h-5 w-5 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center font-bold rounded cursor-pointer'} />
                      {t('Create Group at the Bottom')}
                    </div>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-cgray-100 text-cgray-800' : 'text-cgray-600',
                        'flex gap-x-2 px-4 py-2 text-sm cursor-pointer',
                      )}
                      onClick={() => {
                        dispatch(discardAlert());
                        resetQuotationPositionsMutation({ variables: { quotation: quotationId as string } });
                      }}
                    >
                      <IconRefresh className={'h-5 w-5 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center font-bold rounded cursor-pointer'} />
                      {t('Reset Items Positions')}
                    </div>
                  )}
                </Menu.Item>
              </>
            )}
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-cgray-100 text-cgray-800' : 'text-cgray-600',
                    'text-sm cursor-pointer',
                  )}
                >
                  <GeneratePDF quotationName={quotationName}/>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
