import { IconSearch, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { setSearchClassesInput } from '../../../../redux/searchSlice';


export default function EtimSearchInput() {
  const [inputFocused, setInputFocused] = useState(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const searchClassesInput = useAppSelector(state => state.search.searchClassesInput);

  return (
    <div className="relative flex w-full">
      {!searchClassesInput?.length ? (
        <div
          className="absolute inset-y-0 right-0 mr-2 flex items-center pointer-events-none"
          aria-hidden="true"
        >
          <IconSearch className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'h-5 w-5')} aria-hidden="true" />
        </div>
      ) : (
        <div
          className="absolute inset-y-0 right-0 mr-2 flex items-center"
          aria-hidden="true"
        >
          <IconX
            className="h-5 w-5 text-cgray-400 hover:text-cblue-500 cursor-pointer"
            onClick={() => dispatch(setSearchClassesInput(''))}
            aria-hidden="true"
          />
        </div>
      ) }
      <input
        type="text"
        name="search-class"
        id="search-class"
        className="peer pl-4 pt-2.5 pb-1.5 pr-10 w-full rounded-tl-sm rounded-tr-sm sm:text-sm text-cgray-600 leading-6 caret-cblue-500 placeholder:text-cgray-400 border-0 border-b-[1px] border-transparent bg-cgray-100 hover:bg-cgray-200 focus:border-0 focus:border-b-[1px] focus:border-cblue-500 focus:ring-0 focus:outline-0 focus:bg-cgray-200"
        placeholder={t('Search Class')}
        value={searchClassesInput}
        onChange={(e) => dispatch(setSearchClassesInput(e.target.value))}
        onFocus={() => setInputFocused(true)}
        onBlur={() => setInputFocused(false)}
      />
      <label 
        htmlFor="search-class" 
        className={classNames(inputFocused 
          ? 'text-cblue-500' : 'text-cgray-400',
        'absolute top-0.5 left-4 text-2xs peer-placeholder-shown:hidden')}
      >
        {t('Search Class')}
      </label>
    </div>
  );
}
