import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { Handle } from '../../../../../shared/dnd/Item';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import DefaultProductDetailsCompact from '../../../quotationList/row/product/DefaultProductDetailsCompact';

export interface Props {
  clone?: boolean;
  handleProps?: any;
  indentationWidth: number;
  item: FlattenedItem;
  ghost?: boolean,
}

function ProductItem(props: Props) {
  const { clone, handleProps, indentationWidth, item, ghost } = props;
  const [showDetails, setShowDetails] = useState(false);
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  return (
    <li
      className={classNames(
        styles.Wrapper,
        styles.indicator,
        clone && styles.clone,
        ghost && styles.ghost,
      )}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
        className={styles.TreeItem}
        ref={handleProps?.ref}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
            '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
            '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className='flex justify-end'
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
        ></div>
        <div className='w-14 py-3.5 flex justify-between'>
          <TreeItemIcon 
            item={item} 
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
          <Handle {...handleProps} />
        </div>
        <div className="w-12">{item.product?.supplierLabel}</div>
        <div className="w-32">{item.product?.externalId}</div>
        <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 70} placement="top">
          <div className="w-[400px] 3xl:w-[550px] 4xl:w-[800px] truncate">{item?.product?.description}</div>
        </Tippy>
      </div>
      {showDetails && (
        <DefaultProductDetailsCompact
          externalProductId={item.product?.externalId as ID}
          catalogId={item?.product?.catalogId as ID}
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
        />
      )}
    </li>
  );
}

export default ProductItem;