/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQuery } from '@apollo/client';
import type {
  Announcements,
  CollisionDetection,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  Modifier,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { nanoid } from 'nanoid';
import { Item, arrayToTree } from 'performant-array-to-tree';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { MultipleItemType, ItemTypeChoices } from '../__generated__/graphql';
import { ADD_QUOTATION_ITEMS_MUTATION, MOVE_QUOTATION_ITEM_MUTATION } from '../api/mutations/quotations/item';
import {
  QUOTATION_QUERY,
} from '../api/queries/quotations/quotation';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { ListHeader, NoRecordsArea } from '../components/search/searchPaste';
import { QuotationList, QuotationListItem } from '../components/search/searchPaste/quotationList';
import SearchHeader from '../components/search/searchPaste/SearchHeader';
import SearchList from '../components/search/searchPaste/searchList/row/SearchList';
import { dropAnimationConfig } from '../components/shared/dnd/config';
import type { FlattenedItem, SensorContext, TreeItem } from '../components/shared/dnd/types';
import { SearchType } from '../constants';
import { sortableTreeKeyboardCoordinates } from '../helpers/dnd/keyboardCoordinates';
import {
  buildTree,
  findItemDeep,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
} from '../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../helpers/reduxHooks';
import { handleQuotationGroupCollapse, prepareItemForMutation, collapseGroups } from '../helpers/utils';
import { LoadingIndicator, Navbar } from '../layout';
import { setErrorAlert } from '../redux/alertSlice';
import { setMaximumSearchUncollapsedDepth } from '../redux/searchSlice';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};


export default function SearchPaste() {
  const pinnedItems = useAppSelector(state => state.search.pinnedItems);
  const lastSearchResults = useAppSelector(state => state.search.lastSearchResults);
  const destinationQuotationId = useAppSelector(state => state.search.QuotationContext.destinationQuotationId);
  const searchType = useAppSelector(state => state.search.searchType);
  const [quotationItems, setQuotationItems] = useState<FlattenedItem[]>([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [projected, setProjected] = useState<any>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const flattenItems = useCallback((items: TreeItem<FlattenedItem>[]) => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<any>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    );
  }, [activeId]);

  // filter out duplicates
  const uniqueItems: FlattenedItem[] = [...pinnedItems, ...lastSearchResults].reduce((acc: FlattenedItem[], item) => {
    if (!acc.some((accItem) => accItem.id === item.id)) {
      acc.push(item);
    }
    return acc;
  }, []);

  const [searchItems, setSearchItems] = useState<FlattenedItem[]>(collapseGroups(uniqueItems as FlattenedItem[]));

  // Create items to display in both sections of SearchPaste page.
  const flattenedSearchItems = flattenItems(searchItems as FlattenedItem[]);
  const flattenedQuotationItems = flattenItems(quotationItems as FlattenedItem[]);

  const indentationWidth = 25;

  useEffect(() => {
    const deepestSearchUncollapsedLevel = Math.max(...flattenedSearchItems.map(item => item.depth));
    dispatch(setMaximumSearchUncollapsedDepth(deepestSearchUncollapsedLevel));
  }, [flattenedSearchItems, dispatch]);

  const [
    moveMutation,
    {
      loading: moveMutationLoading,
    },
  ] = useMutation(
    MOVE_QUOTATION_ITEM_MUTATION,
    {
      refetchQueries: [
        {
          query: QUOTATION_QUERY,
          variables: {
            quotation: destinationQuotationId as string,
          },
        },
      ],
      // make sure all queries included in refetchQueries are completed before the mutation is considered complete.
      awaitRefetchQueries: true,
    },
  );

  const [
    addItemMutation,
    {
      loading: addProductMutationLoading,
    },
  ] = useMutation(
    ADD_QUOTATION_ITEMS_MUTATION,
    {
      refetchQueries: [
        {
          query: QUOTATION_QUERY,
          variables: {
            quotation: destinationQuotationId as string,
          },
        },
      ],
      // make sure all queries included in refetchQueries are completed before the mutation is considered complete.
      awaitRefetchQueries: true,
    },
  );

  const {
    data: quotationData,
    loading: quotationLoading,
  } = useQuery(QUOTATION_QUERY, {
    variables: {
      quotation: destinationQuotationId as string,
    },
    skip: !destinationQuotationId,
    fetchPolicy: 'network-only',
  });

  const sensorContext: SensorContext = useRef({
    items: flattenedQuotationItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, true, indentationWidth),
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    }),
  );

  let activeItem = null;
  if (activeId) {
    activeItem = flattenedQuotationItems.find(({ id }) => id === activeId);
    if (!activeItem) {
      activeItem = searchItems.find(({ id }) => id === activeId);
    }
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier,
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      let clonedItems: FlattenedItem[] = [];
      let activeItem = searchItems.find(item => item.id === activeId);
      if (!activeItem) {
        activeItem = flattenedQuotationItems.find(item => item.id === activeId);
      }
      if (searchItems.map(item => item.id).includes(activeId)
        && !flattenedQuotationItems.map(item => item.id).includes(activeId)) {
        clonedItems = JSON.parse(
          JSON.stringify([...flattenedQuotationItems, activeItem]),
        );
      } else {
        clonedItems = JSON.parse(JSON.stringify(flattenedQuotationItems));
      }
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        if (!nextItem) {
          return;
        }
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }

  function handleQuotationItemsCollapse(idsToProcess: UniqueIdentifier[]) {
    setQuotationItems((flattenedQuotationItems) =>
      handleQuotationGroupCollapse(flattenedQuotationItems, idsToProcess),
    );
  }

  function handleSearchItemsCollapse(idsToProcess: UniqueIdentifier[]) {
    setSearchItems((flattenedSearchItems) =>
      handleQuotationGroupCollapse(flattenedSearchItems, idsToProcess),
    );
  }

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    let activeItem = flattenedQuotationItems.find(({ id }) => id === activeId);
    if (!activeItem) {
      activeItem = searchItems.find(({ id }) => id === activeId);
    }
    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }
    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ active, over, delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
    let activeItem = searchItems.find(item => item.id === active.id);
    if (!activeItem) {
      activeItem = flattenedQuotationItems.find(item => item.id === active.id);
    }
    const projectionList = activeItem ? [...flattenedQuotationItems, activeItem] : flattenedQuotationItems;

    const projectedUpdate =
      activeId && over
        ? getProjection(
          projectionList,
          active?.id,
          over?.id,
          offsetLeft,
          indentationWidth,
        )
        : null;

    setProjected(projectedUpdate);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    resetState();
    let isPasted = false;

    if (projected && over) {
      const { depth, parentId } = projected;
      let clonedItems: FlattenedItem[] = [];
      let activeItem = searchItems.find(item => item.id === active.id);
      if (!activeItem) {
        activeItem = flattenedQuotationItems.find(item => item.id === active.id);
      }
      if (searchItems.map(item => item.id).includes(active.id)
        && !flattenedQuotationItems.map(item => item.id).includes(active.id)) {
        clonedItems = JSON.parse(
          JSON.stringify([...flattenedQuotationItems, activeItem]),
        );
        isPasted = true;
      } else {
        clonedItems = JSON.parse(JSON.stringify(flattenedQuotationItems));
      }

      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      let parent = clonedItems.find(({ id }) => id === parentId);

      // if the targeted item is not group, set the nearest group (parent of current target item) as parent.
      if (parent?.itemType !== ItemTypeChoices.Group) {
        parent = clonedItems.find(({ id }) => id === parent?.parentId);
      }

      // if in Quotation there is only empty group, set this group as parent
      if (!parent && flattenedQuotationItems.length === 1 && flattenedQuotationItems[0].itemType === ItemTypeChoices.Group) {
        parent = clonedItems.find(({ itemType }) => itemType === ItemTypeChoices.Group);
      }

      if (activeItem?.itemType !== ItemTypeChoices.Group && !parent) {
        dispatch(setErrorAlert([t('Only Groups are allowed to be pasted at the root level')]));
        return;
      }

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId: parent?.id as UniqueIdentifier || null };

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      //This item will be used to send update event to Back End.
      const updatedItem = findItemDeep(newItems, activeTreeItem.id) as FlattenedItem;
      setQuotationItems(newItems as FlattenedItem[]);
      if (isPasted) {
        const items: { catalog?: string; externalId?: string; itemType: MultipleItemType; item?: string; }[] = [];
        items.push(prepareItemForMutation(updatedItem, searchType === SearchType.QUOTATION));
        
        addItemMutation({
          variables: {
            items,
            order: updatedItem?.index as number,
            parent: updatedItem?.parentId as string,
            quotation: destinationQuotationId as string,
          },
        });
        // Regenerate search results records ids to allow multiple instance copy
        const updatedIdsRecords = searchItems.map(item => ({ ...item, id: nanoid() }));
        setSearchItems(updatedIdsRecords);
      } else {
        moveMutation(
          {
            variables: {
              items: [updatedItem?.id as string],
              order: updatedItem?.index as number,
              parent: updatedItem?.parentId as string,
              quotation: destinationQuotationId as string,
            },
          },
        );
      }
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function collisionDetectionStrategy(args: any) {
    // This function checks that draggable is actually placed over droppable area. 
    const pointerIntersections = pointerWithin(args);
    const intersections =
      pointerIntersections.length > 0
        ? // If there are droppables intersecting with the pointer, return those
        pointerIntersections
        : rectIntersection(args);
    const overId = getFirstCollision(intersections, 'id');

    if (overId != null) {
      return [{ id: overId }];
    }
  }

  useEffect(() => {
    // Update local items each time we have updated items incoming outside this component.
    if (quotationData) {
      const quotationItems = quotationData.quotation
        && quotationData.quotation.response
        && quotationData.quotation.response.items || [];
      const preparedItems = quotationItems.map(item => {
        return {
          ...item,
          canHaveChildren: item?.itemType !== ItemTypeChoices.Product,
        };
      });

      const itemsTree = preparedItems && arrayToTree(preparedItems as Item[], { dataField: null });
      setQuotationItems(itemsTree as FlattenedItem[]);
    }
  }, [quotationData, flattenItems]);

  // Are all mutations and refetchQueries completed?
  const isLoading = addProductMutationLoading || moveMutationLoading;

  return (
    <div className="flex flex-wrap min-h-screen w-fit min-w-full">
      <div className="w-full">
        <DndContext
          accessibility={{ announcements }}
          sensors={sensors}
          collisionDetection={collisionDetectionStrategy as CollisionDetection}
          measuring={measuring}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <Navbar />
          <div className="pt-5">
            <ReduxAlertWrapper />
            <main className="flex flex-col gap-4 ml-4 mr-4">
              <SearchHeader />
              <div className="grid grid-cols-2 gap-8">
                <div className="pb-3">
                  <ListHeader
                    isQuotationHeader={false}
                    indentationWidth={indentationWidth}
                  />
                  <div className="pb-0.5 h-screen overflow-y-auto overscroll-y-auto">
                    <SearchList
                      items={flattenedSearchItems}
                      handleCollapse={handleSearchItemsCollapse}
                      indentationWidth={indentationWidth}
                    />
                  </div>
                </div>
                <div>
                  {destinationQuotationId ? (
                    <>
                      {!!flattenedQuotationItems.length && (
                        <div className="pb-3">
                          <ListHeader
                            isQuotationHeader={true}
                            indentationWidth={indentationWidth}
                            isLoading={isLoading}
                          />
                          <div className="pb-0.5 h-screen overflow-y-auto overscroll-y-auto">
                            <QuotationList
                              indentationWidth={indentationWidth}
                              items={flattenedQuotationItems}
                              handleCollapse={handleQuotationItemsCollapse}
                              depth={projected?.depth}
                            />
                          </div>
                        </div>
                      )}
                      {!quotationLoading
                        && quotationData
                        && quotationData.quotation
                        && quotationData.quotation.response
                        && !quotationData.quotation.response.items?.length && (
                          <NoRecordsArea />)
                      }
                      {quotationLoading && (
                        <LoadingIndicator className="w-full h-96 flex justify-center items-center" />
                      )}
                    </>
                  ) : (
                    <div
                      className="text-3xl w-full h-96 flex items-center justify-center text-cgray-500"
                    >
                      {t('Please Select the Quotation Project')}
                    </div>
                  )}
                </div>
              </div>
            </main>
          </div>
          {createPortal(
            <DragOverlay
              dropAnimation={dropAnimationConfig}
              modifiers={[adjustTranslate]}
            >
              {activeItem ? (
                <QuotationListItem
                  id={activeItem.id}
                  depth={activeItem.depth}
                  clone
                  childCount={getChildCount(flattenedQuotationItems, activeItem.id) + 1}
                  item={activeItem}
                  indentationWidth={indentationWidth}
                />
              ) : null}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </div>
    </div>
  );
}
