import type { UniqueIdentifier } from '@dnd-kit/core';
import {
  useDraggable,
} from '@dnd-kit/core';
import { ItemTypeChoices } from '../../../../../__generated__/graphql';
import { FlattenedItem } from '../../../../shared/dnd/types';
import GroupItem from '../row/group/GroupItem';
import ProductItem from '../row/product/ProductItem';
import ServiceItem from './service/ServiceItem';

interface Props {
  item: FlattenedItem,
  handleCollapse: (id: UniqueIdentifier[]) => void,
  indentationWidth: number,
  childCount?: number,
  clone?: boolean
  ghost?: boolean
}

export function SearchListItem(props: Props) {
  const { item, handleCollapse, indentationWidth, childCount, clone } = props;
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: item.id,
    data: item,
  });

  return (
    <>
      {item.itemType === ItemTypeChoices.Product && (
        <ProductItem
          item={item}
          clone={clone}
          ghost={isDragging}
          indentationWidth={indentationWidth}
          handleProps={{
            ref: setNodeRef,
            ...attributes,
            ...listeners,
          }}
        />
      )}
      {item.itemType === ItemTypeChoices.Group && (
        <GroupItem
          item={item}
          clone={clone}
          indentationWidth={indentationWidth}
          ghost={isDragging}
          collapsed={Boolean(item.collapsed && item.children.length)}
          childCount={childCount}
          onCollapse={
            item.children.length
              ? () => handleCollapse([item.id])
              : undefined
          }
          handleProps={{
            ref: setNodeRef,
            ...attributes,
            ...listeners,
          }}
        />
      )}
      {item.itemType === ItemTypeChoices.Service && (
        <ServiceItem
          item={item}
          clone={clone}
          ghost={isDragging}
          indentationWidth={indentationWidth}
          handleProps={{
            ref: setNodeRef,
            ...attributes,
            ...listeners,
          }}
        />
      )}
    </>
  );
}
