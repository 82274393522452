import { useTranslation } from 'react-i18next';
import ErrorPageTempate from './ErrorPageTempate';

function Error503() {
  const { t } = useTranslation();
  return (
    <ErrorPageTempate
      label={t('Error: Bad Gateway.')}
      code={503}
    />
  );
}

export default Error503;
