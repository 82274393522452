import { useApolloClient, useLazyQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProjectType, QuotationType, QuotationVersionTypeChoices } from '../../../__generated__/graphql';
import { INLINE_PROJECTS_QUERY, PROJECT_QUERY } from '../../../api/queries/projects';
import { INLINE_RECORDS_SIZE } from '../../../config';
import { SearchType } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../../helpers/utils';
import Switch from '../../../layout/fields/Switch';
import {
  setDestinationProjectId,
  setDestinationQuotationId,
  setRedirectToQuotation,
  unsetDestinationIds,
} from '../../../redux/searchSlice';
import SelectProject from '../../quotation/topBar/SelectProject';
import SelectQuotation from '../../quotation/topBar/SelectQuotation';

export default function SearchHeader() {
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectToQuotation = useAppSelector(state => state.search.redirectToQuotation);
  const destinationProjectId = useAppSelector(state => state.search.QuotationContext.destinationProjectId);
  const destinationQuotationId = useAppSelector(state => state.search.QuotationContext.destinationQuotationId);
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const searchQuotationId = useAppSelector(state => state.search.QuotationContext.searchQuotationId);
  const { t } = useTranslation();

  const searchType = useAppSelector(state => state.search.searchType);
  const client = useApolloClient();
  const query: DocumentNode = INLINE_PROJECTS_QUERY;

  const [getProject, { data: projectData }] = useLazyQuery(PROJECT_QUERY);

  const switchPasteModeOff = () => {
    const originalRedirectToQoutationValue = redirectToQuotation;
    // Reset this value when going off the Paste mode.
    dispatch(setRedirectToQuotation(false));
    dispatch(unsetDestinationIds());
    if (destinationProjectId
      && destinationQuotationId
      && originalRedirectToQoutationValue) {
      dispatch(setRedirectToQuotation(false));
      navigate(`/quotation/${destinationProjectId}/${destinationQuotationId}`);
    } else {
      navigate('/search/multiple/');
    }
  };

  const handleProjectChange = (selectedId: string) => {
    setSelectedProjectId(selectedId);
  };

  useEffect(() => {
    if (selectedProjectId) {
      getProject({
        variables: { project: selectedProjectId },
      });
    }
    const project = projectData?.project?.response;
    // If "Search by Quotation" type was used exclude Quotation from Project quotations list
    // if this quotation it is source quotation for pinned item, on Search by Quotation page.
    let projectQuotations = project && project.quotations ? project.quotations as QuotationType[] : [];
    if (searchType === SearchType.QUOTATION) {
      const preparedProjectQuotations = projectQuotations?.filter(quotation => quotation?.id !== searchQuotationId);
      projectQuotations = preparedProjectQuotations;
    }

    let editableQuotations: QuotationType[] = [];

    if (projectQuotations) {
      editableQuotations = projectQuotations.filter(item => item?.versionType !== QuotationVersionTypeChoices.Readonly);
    }

    if (project && !!editableQuotations?.length) {
      const latestQuotation = editableQuotations[0];
      dispatch(setDestinationProjectId(project?.id));
      dispatch(setDestinationQuotationId(latestQuotation?.id));
    } else if (project) {
      dispatch(setDestinationProjectId(project.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData, selectedProjectId]);

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadProjectOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_PROJECTS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);

    // If "Search by Quotation" type was used, exclude Projects from Destination Project select options
    // if there was only one quotation and this quotation it is source quotation for pinned item, on SearchPaste page.
    let selectProjects;
    let selectOptions = innerOptions;
    if (searchType === SearchType.QUOTATION) {
      selectProjects = innerData.response.objects
        .filter((project: ProjectType) => !(project.quotations?.length === 1
          && project?.quotations?.find(quotation => searchQuotationId === quotation?.id)
        ));

      selectOptions = selectProjects.map((item: ProjectType) => ({ value: item?.id, label: item?.nameLabel as string }));
    }

    return {
      options: data ? selectOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  const handleQuotationChange = (selectedId: string) => {
    dispatch(setDestinationQuotationId(selectedId));
  };

  return (
    <>
      <div className="w-full 2xl:w-1/2 self-end flex items-center gap-4 justify-end pl-0 2xl:pl-4">
        <>
          <div className="w-full flex flex-col">
            <SelectProject
              onChange={handleProjectChange}
              projectId={QuotationContext && QuotationContext.destinationProjectId}
              loadOptions={loadProjectOptions}
            />
          </div>
          {
            QuotationContext && QuotationContext.destinationProjectId &&
            <div
              className="w-60 flex flex-col"
            >
              <SelectQuotation
                onChange={handleQuotationChange}
                projectId={QuotationContext.destinationProjectId}
                quotationId={QuotationContext?.destinationQuotationId}
                hideROQuotations
              />
            </div>
          }
        </>
        <Switch
          value={location.pathname.includes('paste')}
          onChange={switchPasteModeOff}
          className='flex items-center gap-2 shrink-0'
          label={t('Paste mode')}
        />
      </div>
    </>
  );
}
