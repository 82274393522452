import React, { ChangeEvent, useRef, useState } from 'react';
import { classNames } from '../../helpers/utils';

interface Props {
  id: string,
  name: string,
  label: string,
  onChange: (value: string | ChangeEvent<unknown>) => void,
  errorMessage?: string,
  disabled?: boolean,
  value?: string,
  placeholder: string,
  rows: number,
}

function TextArea(props: Props) {
  const {
    id, name, label, onChange, errorMessage,
    value, disabled, placeholder, rows,
  } = props;
  const hasError = !!errorMessage || false;

  const [inputFocused, setInputFocused] = useState(false);
  const [inputHovered, setInputHovered] = useState(false);
  const inputRef = useRef(null);

  const labelHandler = (event: React.MouseEvent<HTMLLabelElement>) => {
    // eslint-disable-next-line no-param-reassign
    (event.target as HTMLLabelElement).style.cursor = 'text';
    setInputHovered(true);
  };

  const input = (
    <>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          id={id}
          name={name}
          //@ts-ignore
          value={value}
          onChange={onChange}
          aria-invalid={hasError}
          aria-describedby={id}
          disabled={disabled || undefined}
          className={classNames(
            inputHovered ? 'bg-cgray-200' : 'bg-cgray-100',
            'peer py-2 placeholder:text-cgray-400 block w-full border-0 border-b-[1px] border-transparent bg-cgray-100 text-cgray-600 sm:text-sm sm:leading-7 hover:bg-cgray-200 focus:placeholder:text-transparent focus:border-0 focus:border-b-[1px] focus:border-cblue-500 focus:ring-0 focus:bg-cgray-200',
          )}
          //@ts-ignore
          label={label}
          placeholder={placeholder || ''}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          ref={inputRef}
          rows={rows}
        />
        <label
          htmlFor={label}
          onMouseEnter={(e) => labelHandler(e)}
          onMouseLeave={() => setInputHovered(false)}
          //@ts-ignore
          onClick={() => { inputRef.current.focus(); }}
          className={classNames(inputFocused ? 'text-cblue-500' : 'text-cgray-400', 'absolute -top-1 left-3 text-cblue-500 text-2xs sm:leading-7 peer-placeholder-shown:text-sm peer-placeholder-shown:text-transparent peer-placeholder-shown:top-3 peer-placeholder-shown:leading-7 transition-all peer-focus:-top-1 peer-focus:text-cblue-500 peer-focus:text-2xs label')}
        >
          {label}
        </label>
      </div>
      {hasError && (
        <p className="mt-2 text-sm text-cred-500" id={id}>
          {errorMessage}
        </p>
      )}
    </>
  );
  return input;
}

export default TextArea;
