import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CREATE_QUOTATION_MUTATION } from '../../../api/mutations/quotations/quotation';
import { QUOTATIONS_QUERY } from '../../../api/queries/quotations/quotation';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import ModalButtons from '../../../layout/fields/ModalButtons';
import { setSuccessAlert } from '../../../redux/alertSlice';

interface Props {  
  handleClose: () => void,
}

export default function CreateQuotationModal(props: Props) {
  const { handleClose } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { projectId, quotationId } = useParams();
  const { t } = useTranslation();

  const [
    createQuotation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(CREATE_QUOTATION_MUTATION, { 
    refetchQueries: [
      { query: QUOTATIONS_QUERY,
        variables: {
          project: projectId,
        },
      },
    ],
  });
    
  const handleCreate = () => {
    if (quotationId) createQuotation({ 
      variables: { 
        quotation: quotationId,
      },
      onError: () => handleClose(),
    });
  };

  useEffect(() => {
    if (mutationData && mutationData.createQuotation && mutationData.createQuotation.response) {
      dispatch(setSuccessAlert(
        [`${mutationData.createQuotation.response.name} quotation was successfully created.`],
      ));
      handleClose();
      navigate(`/quotation/${projectId}/${mutationData.createQuotation.response.id}/`);  
    }
  }, [mutationData, navigate, dispatch, projectId, handleClose]);

  return (
    <div className='flex flex-col justify-between items-center w-full rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
      <div className='pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>{t('Create New Quotation')}</div>
      <div className='w-full pl-8 my-8'>{t('This Action will create the copy of current Quotation')}</div>
      <ModalButtons 
        mutationLoading={mutationLoading} 
        cancelButtonText='Cancel' 
        submitButtonText="Create" 
        handleClose={handleClose} 
        onSubmit={handleCreate}
        wrapperClassName='flex justify-end gap-8 pr-8 pb-8 w-full'
      />
    </div>
  );
}
