import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { RobotIndicator } from '../../../../layout';

interface Props {
  indentationWidth: number;
}

export default function SearchListHeader(props: Props) {
  const { indentationWidth } = props;
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);
  const { t } = useTranslation();

  return (
    <div>
      <div className=" w-full flex items-center gap-6 bg-cblue-100 border-b-2 border-cblue-500 pt-2 pb-2 mb-3 font-bold text-sm text-cgray-600">
        <div
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, 0)}px` }}
        ></div>
        <div className='w-14 flex items-center justify-end'>
          <RobotIndicator />
        </div>
        <div className="w-20">{t('Unit')}</div>
        <div className="w-24">{t('Supplier')}</div>
        <div className="w-32">{t('Product ID')}</div>
        <div className="w-96 3xl:w-[450px] 4xl:w-[800px] truncate">{t('Description')}</div>
        <div className=" w-14">{t('Quantity')}</div>
        <div className="w-8"></div>
      </div>
    </div>
  );
}
