import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AlertType } from '../constants';

interface Alert {
  id: string,
  messages: string[],
  alertType: AlertType,
  show: boolean

}

interface AlertState {
  alert: Alert | null
}

// Define the initial state using that type
const initialState: AlertState = {
  alert: null,
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState: initialState,
  reducers: {
    setErrorAlert: (state, action: PayloadAction<string[]>) => {
      state.alert = {
        id: uuidv4(),
        messages: action.payload || [],
        alertType: AlertType.ERROR,
        show: true,
      };
    },
    setSuccessAlert: (state, action: PayloadAction<string[]>) => {
      state.alert = {
        id: uuidv4(),
        messages: action.payload || [],
        alertType: AlertType.SUCCESS,
        show: true,
      };
    },
    discardAlert: (state) => {
      state.alert = null;
    },
  },
});

export const { setErrorAlert, setSuccessAlert, discardAlert } = alertSlice.actions;

export default alertSlice.reducer;
