import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { CREATE_PROJECT_WITH_ADDRESS_MUTATION } from '../../api/mutations/projects';
import { INLINE_CUSTOMERS_QUERY } from '../../api/queries/customers';
import { INLINE_PROJECTS_QUERY } from '../../api/queries/projects';
import { INLINE_RECORDS_SIZE } from '../../config';
import { useAppDispatch } from '../../helpers/reduxHooks';
import { getInlineOptionsFromQuery } from '../../helpers/utils';
import { Input, TextArea } from '../../layout/fields';
import ModalButtons from '../../layout/fields/ModalButtons';
import { setSuccessAlert } from '../../redux/alertSlice';
import CustomerSelect from '../projects/fields/CustomerSelect';
import ManagerSelect from '../projects/fields/ManagerSelect';
import ProjectTypeSelect from '../projects/fields/ProjectTypeSelect';

interface FormValues {
  customer: SelectOption | undefined,
  externalId: number | undefined,
  name: string,
  notes: string,
  daysOfValidity: number,
  projectType: string | undefined,
  managersIds: string[] | undefined,
  street: string,
  zipCode: string,
  city: string,
  country: string,
}

export default function CreateProjectForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const client = useApolloClient();
  const query: DocumentNode = INLINE_CUSTOMERS_QUERY;

  const [
    projectMutation,
    {
      data: mutationData,
      loading: mutationLoading,
    }] = useMutation(CREATE_PROJECT_WITH_ADDRESS_MUTATION, {
    refetchQueries: [{
      query: INLINE_PROJECTS_QUERY,
      variables: { searchInput: '' },
    }],
  });

  const schema = yup.object({
    customer: yup.object().required(`${t('Requried')}`),
    externalId: yup.number()
      .required(`${t('Requried')}`)
      .max(2147483647, `${t('The maximum number of Project External ID cannot exceed 2.147.483.647')}`)
      .min(1, `${t('The number of Project External ID cannot be negative or equal 0')}`)
      .integer(`${t('Project External ID must contain only digits')}`),
    daysOfValidity: yup.number()
      .max(2147483647, `${t('The maximum number cannot exceed 2.147.483.647')}`)
      .min(1, `${t('The number cannot be negative or equal 0')}`)
      .integer(`${t('Days of validity must contain only digits')}`),
    name: yup.string().trim().required(`${t('Requried')}`),
    notes: yup.string(),
    projectType: yup.string().required(`${t('Requried')}`),
    managersIds: yup.array().of(yup.number()),
    street: yup.string().trim().required(`${t('Requried')}`),
    zipCode: yup.string().trim().max(6, `${t('Zip code must be no more than 6 characters')}`).required(`${t('Requried')}`),
    city: yup.string().trim().required(`${t('Requried')}`),
    country: yup.string().trim().required(`${t('Requried')}`),
  });

  const initialValues: FormValues = {
    customer: undefined,
    externalId: undefined,
    daysOfValidity: 30,
    name: '',
    notes: '',
    projectType: undefined,
    managersIds: undefined,
    street: '',
    zipCode: '',
    city: '',
    country: '',
  };

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    values: formikValues,
    errors: formikErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: (values) => {

      const variables = {
        ...values,
        externalId: values.externalId as number,
        customer: values.customer?.value as string,
        projectType: values.projectType || '',
        daysOfValidity: values.daysOfValidity,
        managers: values.managersIds,
      };
      
      projectMutation(
        {
          variables,
        },
      );
    },
  });

  const {
    data: projectsData,
  } = useQuery(INLINE_PROJECTS_QUERY, {
    variables: {
      searchInput: '',
    },
  });

  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function loadCustomerOptions(search, loadedOptions, { skip }: any) {
    const variables = {
      searchInput: search,
      skip,
    };

    const { data } = await client.query({
      query,
      variables: variables,
      context: {
        debounceKey: 'INLINE_CUSTOMERS_QUERY',
      },
    });
    const innerData = data[Object.keys(data)[0]];
    const innerOptions = getInlineOptionsFromQuery(data);
    
    return {
      options: data ? innerOptions : [],
      hasMore: innerData.response.hasNext,
      additional: {
        skip: skip + INLINE_RECORDS_SIZE,
      },
    };
  }

  useEffect(() => {
    if (mutationData && mutationData.createProjectWithAddress && mutationData.createProjectWithAddress.response) {
      dispatch(setSuccessAlert(
        [`${mutationData.createProjectWithAddress.response.name} ${t("project was successfully created. You can now add products to it's quotation")}`],
      ));
      const project = projectsData?.inlineProjects?.response?.objects?.find((item) => item?.id === mutationData?.createProjectWithAddress?.response?.id);
      if (project && !!project.quotations?.length) {
        const latestQuotation = project.quotations[0];
        navigate(`/quotation/${mutationData.createProjectWithAddress.response.id}/${latestQuotation?.id}/`);
      } else if (project) {
        navigate(`/quotation/${mutationData.createProjectWithAddress.response.id}/`);
      }
    }
  }, [mutationData, navigate, dispatch, projectsData, t]);

  return (
    <form className="p-12" onSubmit={handleSubmit}>
      <div className="rounded-b-xl drop-shadow-xl bg-white w-[900px] mx-auto">
        <div className='pl-12 mb-8 bg-cblue-100 w-[900px] mx-auto h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500 rounded-t-xl'>{t('Create New Project')}</div>
        <div className="flex flex-col gap-8 items-center justify-center border-b border-gray-900/10 pb-12 w-full rounded-b-xl">
          <div className="w-[800px]">
            <h2 className="text-base font-semibold leading-7 text-gray-900">{t('Project details:')}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{t('Project connects customer and quotation data.')}</p>
          </div>

          <div className="flex flex-col gap-6 w-[800px]">                       
            <div className="relative">
              <ProjectTypeSelect setFieldValue={setFieldValue} />
              {touched.projectType && !!formikErrors.projectType && (<div className="mt-2 text-sm text-red-600">{formikErrors.projectType}</div>)}
            </div>

            <div className="relative mt-2">
              <Input
                id="externalId"
                name="externalId"
                type="number"
                defaultValue={formikValues.externalId}
                //@ts-ignore
                errorMessage={touched.externalId && formikErrors.externalId}
                label={t('Project External Id')}
                placeholder={t('Project External Id')}
                required
                onChange={handleChange}
              />
            </div>
            
            <div className="relative mt-2">
              <Input
                id="daysOfValidity"
                name="daysOfValidity"
                type="number"
                defaultValue={formikValues.daysOfValidity}
                //@ts-ignore
                errorMessage={touched.daysOfValidity && formikErrors.daysOfValidity}
                label={t('Days of validity')}
                placeholder={t('Days of validity')}
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="name"
                name="name"
                type="text"
                value={formikValues.name}
                //@ts-ignore
                errorMessage={touched.name && formikErrors.name}
                label={t('Project Name')}
                placeholder={t('Project Name')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="mt-4">
              <CustomerSelect 
                setFieldValue={setFieldValue}
                loadOptions={loadCustomerOptions}
              />
              {touched.customer && !!formikErrors.customer && (<div className="mt-2 text-sm text-red-600">{formikErrors.customer}</div>)}
            </div>

            <div className="relative mt-4">
              <ManagerSelect setFieldValue={setFieldValue} />
              {touched.managersIds && !!formikErrors.managersIds && (<div className="mt-2 text-sm text-red-600">{formikErrors.managersIds}</div>)}
            </div>

            <div className="col-span-full">
              <div className="mt-2">
                <div className="relative mt-2">
                  <TextArea
                    id="notes"
                    name="notes"
                    rows={3}
                    onChange={handleChange}
                    label={t('Notes')}
                    placeholder={t('Notes')}
                  />
                </div>
              </div>
            </div>

            <hr/>
            <div className="w-[800px]">
              <h2 className="text-base font-semibold leading-7 text-gray-900">{t('Project address:')}</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">{t('Equipment installation address.')}</p>
            </div>

            <div className="relative">
              <Input
                id="street"
                name="street"
                type="text"
                defaultValue={formikValues.street}
                //@ts-ignore
                errorMessage={touched.street && formikErrors.street}
                label={t('Street')}
                placeholder={t('Street')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="city"
                name="city"
                type="text"
                defaultValue={formikValues.city}
                //@ts-ignore
                errorMessage={touched.city && formikErrors.city}
                label={t('City')}
                placeholder={t('City')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="country"
                name="country"
                type="text"
                defaultValue={formikValues.country}
                //@ts-ignore
                errorMessage={touched.country && formikErrors.country}
                label={t('Country')}
                placeholder={t('Country')}
                required
                onChange={handleChange}
              />
            </div>

            <div className="relative mt-2">
              <Input
                id="zipCode"
                name="zipCode"
                type="text"
                defaultValue={formikValues.zipCode}
                //@ts-ignore
                errorMessage={touched.zipCode && formikErrors.zipCode}
                label={t('Zip code')}
                placeholder={t('Zip code')}
                required
                onChange={handleChange}
              />
            </div>
          </div>
          <ModalButtons 
            mutationLoading={mutationLoading} 
            cancelButtonText='Cancel' 
            submitButtonText="Save" 
            handleClose={() => navigate('/')} 
            wrapperClassName='mt-6 flex items-center justify-end w-[800px] gap-x-4'
          />
        </div>
      </div>
    </form>

  );
}
