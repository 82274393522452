import type { UniqueIdentifier } from '@dnd-kit/core';
import { IconChevronDown, IconPinned, IconPinnedOff } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { setPinnedItem, unsetPinnedItems } from '../../../../../../redux/searchSlice';
import ROGroupDetails from '../../../../../quotation/list/row/details/groupDetails/ROGroupDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import PasteArrow from '../../../fields/PasteArrow';
import HoveredContextMenu from '../../HoveredContextMenu';

interface Props {
  collapsed?: boolean;
  item: FlattenedItem;
  indentationWidth: number;
  onCollapse?(): void;
  isSearchResult: boolean;
  isPinned: boolean,
  hasPinnedParent: boolean,
}

export default function GroupItem(props: Props) {
  const {
    indentationWidth, collapsed, onCollapse,
    item, isSearchResult, isPinned, hasPinnedParent } = props;
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const pinnedItems = useAppSelector(state => state.search.pinnedItems);
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);

  function getPinnedChildrenIds(
    selectedItem: FlattenedItem,
    PinnedItemsInput: FlattenedItem[]) {
    const pinnedChildrenIds: UniqueIdentifier[] = [];

    // Recursive function to search through children
    function searchChildren(children: FlattenedItem[], nestedPinnedItems: FlattenedItem[]) {
      for (const child of children) {
        if (nestedPinnedItems.some(pinnedItem => pinnedItem.id === child.id)) {
          pinnedChildrenIds.push(child.id);
        }
        searchChildren(child.children, nestedPinnedItems);
      }
    }

    // search through the children of the item
    searchChildren(selectedItem.children, PinnedItemsInput);

    return pinnedChildrenIds;
  }

  function handlesetPinnedItem() {
    const pinnedChildIds = getPinnedChildrenIds(item, pinnedItems);
    if (!!pinnedChildIds.length) {
      dispatch(unsetPinnedItems(pinnedChildIds));
    }
    dispatch(setPinnedItem(item));
  }

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
      onMouseEnter={() => setIsItemHovered(item.id.toString())}
      onMouseLeave={() => setIsItemHovered(null)}
    >
      <div
        className={classNames(styles.TreeItem, isSearchResult ? '' : 'bg-cblue-200', 'relative')}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
            '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
            '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className={classNames('flex', onCollapse ? 'justify-between' : 'justify-end')}
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
        >
          {onCollapse && (
            <div onClick={onCollapse}>
              <IconChevronDown
                className={classNames(
                  styles.Collapse,
                  collapsed && styles.collapsed,
                  collapsed ? '-rotate-90' : 'rotate-0',
                  'transition-all duration-200 ease-in-out cursor-pointer ml-2 text-cblue-500',
                )}
              />
            </div>
          )}
        </div>
        <div className='w-14 py-3.5 flex items-center justify-between'>
          {!isSearchResult && isPinned && !hasPinnedParent && (
            <Tippy content={t('Remove')} placement="left">
              <IconPinnedOff
                className="h-5 w-5 text-cgray-600 cursor-pointer"
                onClick={() => dispatch(unsetPinnedItems([item.id]))}
              />
            </Tippy>
          )}
          {((isSearchResult && isPinned) || (!isPinned && hasPinnedParent)) && (
            <div className='w-5'></div>
          )}
          {!isPinned && !hasPinnedParent && !hasPinnedParent && (
            <Tippy content={t('Select')} placement="left">
              <IconPinned
                className="h-5 w-5 text-cgray-600 cursor-pointer"
                onClick={() => handlesetPinnedItem()}
              />
            </Tippy>
          )
          }
          <TreeItemIcon
            item={item}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
        </div>
        <>
          <div className="w-20 shrink-0"></div>
          <div className="w-24 shrink-0"></div>
          <div className="w-32 shrink-0"></div>
          <Tippy content={item?.group?.description} disabled={!!item?.group?.description && item?.group?.description.length < 40} placement="top">
            <div className="w-80 shrink-0 3xl:w-[400px] 4xl:w-[700px] truncate">{item?.group?.description}</div>
          </Tippy>
          <PasteArrow item={item} />
          {isItemHoveredId === item.id && (
            <HoveredContextMenu
              item={item}
              isItemHoveredId={isItemHoveredId}
              setIsItemHoveredId={setIsItemHovered}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
            />
          )}
        </>
      </div>
      {showDetails && item.group && (
        <ROGroupDetails
          groupId={item.group.id}
          isGroupQuotation={item.group?.__typename === 'QuotationGroupType'}   
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
        />
      )}
    </li>
  );
}
