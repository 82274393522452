import { useQuery } from '@apollo/client';
import type { DocumentNode } from '@apollo/client';
import { ElementType } from 'react';
import {
  InputMaybe,
  SearchFeature,
  SearchResponseObjectType,
  SearchResponsePageType,
} from '../../__generated__/graphql';
import { useDebounceArray } from '../../helpers/customHooks';
import { useAppSelector } from '../../helpers/reduxHooks';
import { PaginationData, createPaginationData } from './../../helpers/PaginationUtils';

interface Props {
  SearchListWrapper: ElementType,
  searchRecordsQuery: DocumentNode,
  optionalSearchValues?: object,
}

export default function SearchListEtimData(props: Props) {
  const { SearchListWrapper, searchRecordsQuery, optionalSearchValues } = props;
  const selectedETIMClassId = useAppSelector(state => state.search.selectedETIMClassId);
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchListPage = useAppSelector(state => state.search.searchListPage);
  const selectedFeatures = useAppSelector(state => state.search.selectedFeatures);
  const searchResultsType = useAppSelector(state => state.search.searchResultsType);

  let searchFeatures: SearchFeature[] = [];
  if (selectedFeatures.length) {
    searchFeatures = selectedFeatures.map(feature => {
      return { code: feature.code, featureType: feature.featureType, userValue: feature.userValue };
    });
  }

  let searchRecordsVariables = {
    page: searchListPage,
    etimClass: selectedETIMClassId,
    searchInput: debouncedSearchInput as InputMaybe<InputMaybe<string> | InputMaybe<string>[]>,
    searchFeatures: searchFeatures,
    searchForType: searchResultsType,
  };

  if (optionalSearchValues) {
    searchRecordsVariables = {
      ...searchRecordsVariables,
      ...optionalSearchValues,
    };
  }

  const {
    data: searchRecordsData,
    loading: searchRecordsLoading,
  } = useQuery(searchRecordsQuery, {
    variables: searchRecordsVariables,
    skip: !selectedETIMClassId,
    fetchPolicy: 'no-cache', // Disable caching for this query because it messes up results
  });
  
  
  let pageData: SearchResponsePageType | undefined = undefined;

  if (searchRecordsData
    && searchRecordsData?.[Object.keys(searchRecordsData)[0]]
    && searchRecordsData?.[Object.keys(searchRecordsData)[0]].response
  ) {
    pageData = searchRecordsData?.[Object.keys(searchRecordsData)[0]].response as SearchResponsePageType;
  }

  return (
    <SearchListWrapper
      objectsData={pageData?.objects as SearchResponseObjectType[]}
      isLoading={searchRecordsLoading}
      paginationData={createPaginationData(pageData as PaginationData)}
      totalObjectsQuantity={pageData?.totalObjectsQuantity as number}
    />
  );
}