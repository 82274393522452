import { IconArrowNarrowDown } from '@tabler/icons-react';

interface Props {
  onClick?: () => void;
  className?: string;
}

export default function IconProductBelow(props: Props) {
  const { onClick, className } = props;

  return (
    <div 
      onClick={onClick}
      className={className}
    >
      <div className="flex h-full items-center justify-center">
        <div className="h-full">P</div>
        <IconArrowNarrowDown className='w-3' />
      </div>   
    </div>
  );
}