/*
Tippyjs is used with wrapper <div></div> to avoid
"Function components cannot be given refs" error
*/
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';
import { ItemTypeChoices } from '../../../__generated__/graphql';
// to apply custom Tippy theme (looks like it`s enough to import it once).
import '../../../assets/css/tippyjs-calcutron.css';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { IconG, IconP, IconS } from '../../../layout/icons';
import type { GroupIconModifiersProps } from '../../../layout/icons/IconG';
import { addOpenedDeatilsItems, removeOpenedDeatilsItems } from '../../../redux/quotationSlice';
import { FlattenedItem } from './types';

function getTooltipText(
  showDetails: boolean,
  groupIconModifiers?: GroupIconModifiersProps): string {
  // Returns "Hide details" prior to anything else if details are opened.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  let tooltip = '';
  if (showDetails) {
    return `${t('Hide details')}`;
  }
  if (!showDetails) {
    tooltip = `${t('Show details')}`;
  }

  if (groupIconModifiers?.representsTemplate) {
    tooltip = `${t('Represents Group Template')}`;
  }

  return tooltip;
}

interface Props {
  item: FlattenedItem,
  showDetails: boolean,
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>,
  groupIconModifiers?: GroupIconModifiersProps
}

export const TreeItemIcon = function (props: Props) {
  const { item, showDetails, setShowDetails, groupIconModifiers } = props;
  const content = getTooltipText(showDetails, groupIconModifiers);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (showDetails) {
      dispatch(removeOpenedDeatilsItems(item.id));
    } else {
      dispatch(addOpenedDeatilsItems(item.id));
    }
    setShowDetails(!showDetails);
  };

  return (
    <Tippy content={content} placement="left">
      <div>
        {item.itemType === ItemTypeChoices.Product && (
          <IconP
            onClick={handleClick}
            className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} />
        )}
        {item.itemType === ItemTypeChoices.Group && (
          <IconG
            {...groupIconModifiers}
            onClick={handleClick}
            className={'relative w-6 h-6 flex items-center justify-center border-2 rounded cursor-pointer bg-cblue-200 font-bold text-cblue-500 border-cblue-500'} />
        )}
        {item.itemType === ItemTypeChoices.Service && (
          <IconS
            onClick={handleClick}
            className={'w-6 h-6 flex items-center border-2 justify-center font-bold rounded cursor-pointer bg-cblue-500 text-white border-cblue-500'} />
        )}
      </div>
    </Tippy>
  );
};
