import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INLINE_CATALOGS_QUERY } from '../../api/queries/etimCatalogs';
import { useAppDispatch, useAppSelector } from '../../helpers/reduxHooks';
import { setSearchETIMCatalogId } from '../../redux/searchSlice';
import SelectInput from './SelectInput';

export default function SelectEtimCatalog() {
  const { t } = useTranslation();
  const [catalogOptions, setCatalogOptions] = useState<SelectOption[]>([]);
  const searchCatalogId = useAppSelector(state => state.search.QuotationContext.searchETIMCatalogId);
  const { data: catalogsData } = useQuery(INLINE_CATALOGS_QUERY);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (catalogsData && catalogsData.inlineCatalogs) {
      setCatalogOptions(catalogsData.inlineCatalogs.map((catalog) => ({ value: catalog?.id, label: catalog?.name as string })));
    }
  }, [catalogsData]);

  return (
    <SelectInput
      value={searchCatalogId ? catalogOptions.find(item => item.value === searchCatalogId) : undefined}
      options={catalogOptions}
      placeholder={t('ETIM Catalog')}
      onChange={(e) => dispatch(setSearchETIMCatalogId(e?.value as string || undefined))}
      isClearable
    />
  );
}