import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UPDATE_GROUP_PRODUCTION_UNIT_STATUS_MUTATION } from '../../../../../../../api/mutations/quotations/group';
import { useProjectChange } from '../../../../../../../helpers/customHooks';
import { useAppDispatch } from '../../../../../../../helpers/reduxHooks';
import SelectInput from '../../../../../../../layout/fields/SelectInput';
import { setIsLoadingUpdate } from '../../../../../../../redux/quotationSlice';


interface Props {
  quotationId: ID,
  groupId: ID,
  productionUnitStatusId?: ID,
  refetchQueries: InternalRefetchQueriesInclude,
  options: SelectOption[],
}


export default function GroupProductionUnitStatusSelect(props: Props) {
  const { quotationId, groupId, productionUnitStatusId, refetchQueries, options } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();

  const handleProjectChange = useProjectChange();

  const [
    updateProductManufacturingWageMutation,
  ] = useMutation(UPDATE_GROUP_PRODUCTION_UNIT_STATUS_MUTATION, {
    refetchQueries: refetchQueries,
    onCompleted: (data) => {
      dispatch(setIsLoadingUpdate(false));
      if (!data.updateGroupProductionUnitStatus?.errors?.length) {
        // No sense to try to update project status or redirect to new quotation
        // if mutation contains errors
        handleProjectChange(projectId as string);
      }
    },
  });


  const handleChange = (e: SelectOption) => {
    dispatch(setIsLoadingUpdate(true));
    updateProductManufacturingWageMutation(
      {
        variables: {
          quotation: quotationId as string,
          group: groupId as string,
          productionUnitStatus: e.value as string,
        },
      },
    );
  };

  if (!options.length) {
    return (
      <div className='p-6 bg-cyellow-200 text-cred-700 rounded shadow'>
        <div>{t('No Statuses for Production Units available.')}</div>
        <div>{t('Please contact your administrator.')}</div>
      </div>
    );
  } else {
    return (
      <SelectInput
        value={options.find(option => option.value === productionUnitStatusId)}
        options={options}
        placeholder={t('Production Unit Status')}
        onChange={handleChange}
      />
    );
  }
}
