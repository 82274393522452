import { IconPinned, IconPinnedOff } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { setPinnedItem, unsetPinnedItems } from '../../../../../../redux/searchSlice';
import DefaultServiceDetailsReadOnly from '../../../../../quotation/list/row/details/RODefaultServiceDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import HoveredContextMenu from '../../HoveredContextMenu';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
  isSearchResult: boolean;
  hasPinnedParent: boolean;
  isPinned: boolean;
}

function ServiceItem(props: Props) {
  const { indentationWidth, item, isSearchResult, hasPinnedParent, isPinned } = props;
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useAppDispatch();
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);
  const [isItemHoveredId, setIsItemHovered] = useState<string | null>(null);
  const { t } = useTranslation();

  return (
    <li
      className={classNames(styles.Wrapper)}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
      onMouseEnter={() => setIsItemHovered(item.id.toString())}
      onMouseLeave={() => setIsItemHovered(null)}
    >
      <div
          className={classNames(styles.TreeItem, isSearchResult ? '' : 'bg-cblue-200', 'relative')}
          style={
            {
              '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
              '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
              '--justifyContent': 'start',
            } as React.CSSProperties
          }
          >

          <div
            className='flex justify-end'
            style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
          >
          </div>
          <div className='w-14 py-3.5 flex items-center justify-between'>
            {!isSearchResult && isPinned && !hasPinnedParent && (
              <Tippy content={t('Remove')} placement="left">
              <IconPinnedOff 
                className="h-5 w-5 text-cgray-600 cursor-pointer"
                onClick={() => dispatch(unsetPinnedItems([item.id]))}
              />
            </Tippy>
            )}
            {((isSearchResult && isPinned) || (!isPinned && hasPinnedParent) || item.parentId) && (
              <div className='w-5'></div>
            )}
            {!isPinned && !hasPinnedParent && !item.parentId && (
              <Tippy content={t('Select')} placement="left">
                <IconPinned 
                  className="h-5 w-5 text-cgray-600 cursor-pointer"
                  onClick={() => dispatch(setPinnedItem(item))}
                />
              </Tippy>
            )
            }
            <TreeItemIcon 
              item={item} 
              setShowDetails={setShowDetails}
              showDetails={showDetails}
            />
          </div>
          <div className="w-20"></div>
          <div className="w-24"></div>
          <div className="w-32"></div>
          <div className="w-[500px] 3xl:w-[650px] 4xl:w-[1000px] truncate">{item?.service?.description}</div>
          {isItemHoveredId === item.id && (
            <HoveredContextMenu 
              item={item} 
              isItemHoveredId={isItemHoveredId} 
              setIsItemHoveredId={setIsItemHovered} 
              showDetails={showDetails} 
              setShowDetails={setShowDetails} 
            />
          )}
        </div>
        {showDetails && (
          <DefaultServiceDetailsReadOnly
            item={item}
            indentationWidth={indentationWidth}
            depth={item.depth}
            maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
          />
        )}
      </li>
  );
}

export default ServiceItem;
