import {
  IconGripVertical,
} from '@tabler/icons-react';
import { forwardRef } from 'react';
import { Action, ActionProps } from '../Action';

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
      >
      <IconGripVertical
        className="h-5 w-5 cursor-pointer text-cgray-500"
      />
      </Action>
    );
  },
);
