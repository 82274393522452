import { IconMinus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { FlattenedItem } from '../../shared/dnd/types';
import { DiscountInput } from '../list/fields/group';
import AddArrowWrapper from '../list/row/details/AddArrowWrapper';
import { getSubTotalRowStyles } from './utils';

interface Props {
  item: FlattenedItem,
  index: number,
  array: FlattenedItem[],
  arrowNestingLevel: number,
}

export default function GroupNetSalesPricePerPiece(props: Props) {
  const { item, index, array, arrowNestingLevel } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();

  return (
    <div className='flex w-full h-full'>
      <AddArrowWrapper nestingLevel={arrowNestingLevel}>
        <div className={getSubTotalRowStyles(index, array)}>
          <div>
            <div className='flex font-bold gap-x-1'>
              <span>{t('Net Sales Price/Piece')}</span>
            </div>
            <div>
              <span className='text-cgray-400'>
                {item.group?.groupComputation?.netSalesPricePieceLabel}
              </span>
            </div>
          </div>
          <div className='h-full flex items-center justify-between gap-2'>
            <IconMinus className='w-4' />
            <div className="max-w-[100px] h-full ">
              <DiscountInput 
                refetchQueries={[{
                  query: QUOTATION_QUERY,
                  variables: {
                    quotation: quotationId as string,
                  },
                }]}
                discount={item.group?.discount}
                id={item.group?.id}
                label
              />
            </div>
          </div>
        </div>
      </AddArrowWrapper>
    </div>
  );
}
