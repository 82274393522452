import { ElementType } from 'react';
import {
  SearchRecordsByDescriptionQuery,
  SearchResponseObjectType,
  SearchResponsePageType,
} from '../../../../__generated__/graphql';
import { PaginationData, createPaginationData } from './../../../../helpers/PaginationUtils';

interface Props {
  SearchListWrapper: ElementType,
  searchRecordsByDescriptionData: SearchRecordsByDescriptionQuery | undefined,
  searchRecordsByDescriptionLoading: boolean,
}

export default function SearchListDescriptionData(props: Props) {
  const { SearchListWrapper, searchRecordsByDescriptionData, searchRecordsByDescriptionLoading } = props;

  let pageData: SearchResponsePageType | undefined = undefined;
  
  if (searchRecordsByDescriptionData
    && searchRecordsByDescriptionData.searchRecordsByDescription
    && searchRecordsByDescriptionData.searchRecordsByDescription.response
  ) {
    pageData = searchRecordsByDescriptionData.searchRecordsByDescription.response as SearchResponsePageType;
  }

  return (
    <SearchListWrapper
      objectsData={pageData?.objects as SearchResponseObjectType[]}
      isLoading={searchRecordsByDescriptionLoading}
      paginationData={createPaginationData(pageData as PaginationData)}
      totalObjectsQuantity={pageData?.totalObjectsQuantity as number}
    />
  );
}
