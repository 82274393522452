import Tippy from '@tippyjs/react';
import { FlattenedItem } from '../../shared/dnd/types';
import AddArrowWrapper from '../list/row/details/AddArrowWrapper';
import { getSubTotalRowStyles } from './utils';

interface Props {
  item: FlattenedItem,
  index: number,
  array: FlattenedItem[],
  arrowNestingLevel: number,
}

export default function GroupName(props: Props) {
  const { item, index, array, arrowNestingLevel } = props;

  return (
    <div className='flex w-full h-full'>
      <AddArrowWrapper nestingLevel={arrowNestingLevel}>
        <div className={getSubTotalRowStyles(index, array)}>
          <div>
            <Tippy content={item.group?.description} disabled={!!item.group?.description && item.group?.description.length < 60} placement="top">
              <div className='flex font-bold gap-x-1'>
                <span className='max-w-[500px] truncate'>{item.group?.description}</span>
              </div>
            </Tippy>
          </div>
        </div>
      </AddArrowWrapper>
    </div>
  );
}
