import { useQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ProjectsProjectProjectStatusChoices } from '../../../__generated__/graphql';
import { UPDATE_PROJECT_STATUS_MUTATION } from '../../../api/mutations/projects';
import { PROJECT_QUERY } from '../../../api/queries/projects';
import { QUOTATIONS_QUERY } from '../../../api/queries/quotations/quotation';
import { CURRENT_USER_QUERY } from '../../../api/queries/users';
import { prepareOptionsForStatusSelect } from '../../../helpers/utils';
import SelectInput from '../../../layout/fields/SelectInput';


interface Props {
  changeQuotation: (selectedId: string) => void;
}


export default function SelectProjectStatus(props: Props) {
  const { changeQuotation } = props;
  const { t } = useTranslation();
  const { projectId, quotationId } = useParams();

  const { data: userData } = useQuery(CURRENT_USER_QUERY);
  const { data: projectData } = useQuery(
    PROJECT_QUERY, { variables:{ project: projectId as string } },
  );
  const user = userData && userData.user;
  const project = projectData && projectData.project && projectData.project.response;

  const options = prepareOptionsForStatusSelect();

  const [
    projectUpdateMutation,
    {
      loading: mutationLoading,
    }] = useMutation(UPDATE_PROJECT_STATUS_MUTATION, { 
    refetchQueries: [
      {
        query: PROJECT_QUERY,
        variables: { project: projectId as string },
      },
      {
        query: QUOTATIONS_QUERY,
        variables: { project: projectId as string },
      },
    ],
  });

  const handleChange = (e: SelectOption) => {
    projectUpdateMutation(
      {
        variables: {
          quotation: quotationId as string,
          projectStatus: e.value as string,
          triggeredBy: user?.id as string, 
        },
        onCompleted: (data) => {
          // Check if the mutation was successful and redirect to
          // latest quotation should be triggered
          if ([
            ProjectsProjectProjectStatusChoices.Approved,
            ProjectsProjectProjectStatusChoices.Rejected,
            ProjectsProjectProjectStatusChoices.Completed,
          ].includes(e.value as ProjectsProjectProjectStatusChoices)) {
            if (data.updateProjectStatus?.response) {
              const latestQuotation = data.updateProjectStatus.response.latestQuotation?.id;
              changeQuotation(latestQuotation);
            }
          }
        },
      },
    );
  };
  
  return (
    <SelectInput
      value={options.find(item => item.value === project?.projectStatus)}
      options={options}
      placeholder={t('Project Status')}
      isLoading={mutationLoading}
      onChange={handleChange}
      showColorDot
    />
  );
}
