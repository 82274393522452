import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EtimProductFeatureType, ProductsSettingsType } from '../../../../../__generated__/graphql';
import {
  PRODUCT_DETAILS_QUERY,
} from '../../../../../api/queries/quotations/details';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { LoadingIndicator } from '../../../../../layout';
import ObsoletableValue from '../../fields/ObsoletableValue';
import { NotesInput } from '../../fields/product/';
import EtimProductFeature from './EtimProductFeature';

interface Props {
  externalProductId: ID,
  catalogId: ID,
  indentationWidth: number;
  depth: number;
  maximumUncollapsedDepth: number;
  productId?: ID,
}

interface QueryVariables {
  externalId: string,
  catalogId: string,
  product?: string,
}

export default function DefaultProductDetails(props: Props) {
  const { externalProductId, indentationWidth, depth, maximumUncollapsedDepth, productId, catalogId } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();

  const queryVariables: QueryVariables = {
    externalId: externalProductId as string,
    catalogId: catalogId as string,
  };

  if (productId) {
    queryVariables.product = productId as string;
  }

  const {
    data: productData,
    loading: productLoading,
  } = useQuery(PRODUCT_DETAILS_QUERY, {
    variables: queryVariables,
  });

  if (productLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!productLoading && productData && productData.productDetails && productData.productDetails.response) {
    const product = productData.productDetails.response;
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>

        <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal">
          <div className="Datanorm-container">
            <div className="mb-2 font-bold text-cgray-600">{t('Datanorm')}</div>
            <div className="Datanorm-inner-container grid grid-cols-6 gap-2">
              <div className=" col-span-2 flex flex-col gap-2">
                <div className="grid grid-cols-3 gap-2">
                  <div className=" col-span-2 pl-2 py-px min-h-[40px] rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('Description')} 1</p>
                    <p className='break-words'>{product?.datanormProduct?.description1}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('Type')}</p>
                    <p className='break-all'>{product?.datanormProduct?.productType}</p>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <div className=" col-span-2 pl-2 py-px min-h-[40px] rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('Description')} 2</p>
                    <p className='break-words'>{product?.datanormProduct?.description2}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] rounded-t border border-cgray-200">
                    <p className=" text-2xs text-cgray-400">{t('GTIN')}</p>
                    <p className=' break-all'>{product?.datanormProduct?.gtin}</p>
                  </div>
                </div>
                <div className='flex flex-col bg-cgray-100 hover:bg-cgray-200 cursor-text h-full pl-2 py-px min-h-[40px] rounded-t border border-cgray-200'>
                  <p className=" text-2xs text-cgray-400">{t('Notes')}</p>
                  <NotesInput
                    productSettings={product.datanormProduct?.productSettings as ProductsSettingsType}
                    refetchQueries={
                      [{
                        query: QUOTATION_QUERY,
                        variables: {
                          quotation: quotationId as string,
                        },
                      },
                      {
                        query: PRODUCT_DETAILS_QUERY,
                        variables: {
                          externalId: externalProductId as string,
                          catalogId: catalogId as string,
                        },
                      },
                      ]
                    }
                  />
                </div>
              </div>
              <div className=" col-span-1">
                <div className="grid grid-cols-2 gap-2">
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price')}</p>
                    <p>{product?.datanormProduct?.priceLabel}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Special Price')}</p>
                    <ObsoletableValue
                      value={product?.datanormProductDetails?.specialPriceLabel}
                      isCurrent={!product?.datanormProductDetails?.specialPriceIsObsolete}
                    />
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price indicator')}</p>
                    <p>{product?.datanormProduct?.priceIndicator}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Match code')}</p>
                    <p className='break-all'>{product?.datanormProduct?.matchCode}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Units per price')}</p>
                    <p>{product?.datanormProduct?.unitsPerPrice}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Order amount')}</p>
                    <p>{product?.datanormProduct?.orderAmount}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Price/Piece')}</p>
                    <p>{product?.datanormProductDetails?.pricePieceLabel}</p>
                  </div>
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Unit')}</p>
                    <p>{product?.datanormProduct?.unit}</p>
                  </div>
                </div>
              </div>
              <div className=" col-span-1">
                <div className="grid grid-cols-2 gap-2">
                  <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                    <p className=" text-2xs text-cgray-400">{t('Group Discount')}</p>
                    <p>{product?.datanormProduct?.discountGroup}</p>
                  </div>
                  <>
                    <div className=" col-span-1 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className=" text-2xs text-cgray-400">{t('Discount')}</p>
                      <ObsoletableValue
                        value={product?.datanormProductDetails?.discount?.label}
                        isCurrent={!product?.datanormProductDetails?.discount?.isObsolete}
                      />
                    </div>
                    <div className=" col-span-2 pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                      <p className=" text-2xs text-cgray-400">{t('Discounted Price/Piece')}</p>
                      <ObsoletableValue
                        value={product?.datanormProductDetails?.discount?.pricePieceLabel}
                        isCurrent={!product?.datanormProductDetails?.discount?.isObsolete}
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="col-span-1">
                <div className="pl-2 pr-2 py-px min-h-[40px] border border-cgray-200 rounded-t">
                  <p className="text-2xs text-cgray-400">{t('Raw Material Surcharge')}</p>
                  <div>
                    {product?.datanormProductDetails?.rawMaterialSurcharges?.map((surcharge) => surcharge?.surchargeCostLabel).join(', ')}
                  </div>
                </div>
              </div>
              <div className=" col-span-1">
                <div className=" pl-2 py-px min-h-[40px] border border-cgray-200 rounded-t flex flex-col justify-between">
                  <p className=" text-2xs text-cgray-400">{t('Net Price/Piece')}</p>
                  <p>{product?.datanormProductDetails?.netPricePieceLabel}</p>
                </div>
              </div>
            </div>
          </div>

          {productData 
          && productData.productDetails 
          && productData.productDetails.response 
          && productData.productDetails.response.etimProduct && (
            <div className="ETIM-container ">
              <div className="mb-2 font-bold text-cgray-600">{t('ETIM')}</div>
              <div className="grid grid-cols-6 gap-2">
                <div className=" col-span-2 flex flex-row gap-2">
                  <div className=" w-full flex flex-col gap-2">
                    <div className="pl-2 py-px rounded-t border border-cgray-200">
                      <p className=" text-2xs text-cgray-400">{t('Short Description')}</p>
                      <p className='break-words'>{product?.etimProduct?.descriptionShort}</p>
                    </div>
                    <div className="pl-2 py-px flex-grow rounded-t border border-cgray-200">
                      <p className=" text-2xs text-cgray-400">{t('Long Description')}</p>
                      <p>{product?.etimProduct?.descriptionLong}</p>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 ">
                  <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-1 flex flex-col gap-2">
                      <div className=" pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
                        <p className="text-2xs text-cgray-400">{t('ETIM Group')}:</p>
                        <p className="break-words">{product?.etimProduct?.etimClass?.group?.localizedDescription}</p>
                      </div>
                      <div className=" pl-2 pr-1 py-px min-h-[42px] rounded-t border border-cgray-200">
                        <p className="text-2xs text-cgray-400">{t('ETIM Class')}:</p>
                        <p className="break-words">{product?.etimProduct?.etimClass?.localizedDescription}</p>
                      </div>
                    </div>
                    <div className=" col-span-5 flex flex-row flex-wrap gap-2">
                      {product?.etimProduct?.productFeatures.map(
                        (item, index: number) =>
                          <EtimProductFeature
                            item={item as EtimProductFeatureType}
                            key={index}
                          />,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (<></>);
  }
}
