import { arrayToTree, Item } from 'performant-array-to-tree';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchResponseObjectType } from '../../../__generated__/graphql';
import { PaginationData } from '../../../helpers/PaginationUtils';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../../layout';
import { setSearchListPage } from '../../../redux/searchSlice';
import { FlattenedItem } from '../../shared/dnd/types';
import SearchList from './list/SearchList';
import SearchListHeader from './list/SearchListHeader';

interface Props {
  objectsData: SearchResponseObjectType[]
  isLoading: boolean
  paginationData?: PaginationData
  totalObjectsQuantity: number | undefined
}

export default function SearchSingleListWrapper(props: Props) {
  const { objectsData, isLoading, paginationData, totalObjectsQuantity } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const itemsTree = objectsData ? arrayToTree(objectsData as Item[], { dataField: null }) : [];
  
  const searchHelp = useAppSelector(state => state.search.searchHelp);
  const indentationWidth = 50;

  useEffect(() => {
    dispatch(setSearchListPage(1));
  }, [dispatch, objectsData]); 
    
  return (
    <>
      <SearchListHeader indentationWidth={indentationWidth} />

      {objectsData && (
        <>
          {!totalObjectsQuantity
            ? (
            <div className="w-full h-screen pb-64 flex items-center justify-center text-3xl text-cgray-400">
              No results found
            </div>
            ) : (
              <SearchList
                indentationWidth={indentationWidth}
                defaultItems={itemsTree as FlattenedItem[]}
                isSearchResult={true}  
              />
            )
          }
          {
            paginationData &&
            <div className="h-14">
              <Pagination
                page={paginationData.page}
                hasNext={paginationData.hasNext}
                hasPrev={paginationData.hasPrev}
                elidedPageRange={paginationData.elidedPageRange}
                onPageChange={(e:number) => dispatch(setSearchListPage(e))}
              />
            </div>
          }
        </>
      )}
      {!objectsData && !isLoading && (
      <div className="text-3xl w-full h-96 flex items-center justify-center text-cgray-400 relative">
      <div
        className={`transition ease-out duration-100 absolute inset-0 flex items-center justify-center ${!searchHelp ? 'opacity-100' : 'opacity-0'}`}
      >
        {t('Search results will be displayed here')}
      </div>
      <div
        className={`transition ease-in delay-500 duration-100 absolute inset-0 flex items-center justify-center ${searchHelp ? 'opacity-100' : 'opacity-0'}`}
      >
        {t('Please hit Enter to start Search')}
      </div>
    </div>
    
      )}
      {!!isLoading && (
        <LoadingIndicator className="w-full h-96 flex justify-center items-center" />
      )}
    </>
  );
}
