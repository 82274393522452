import { useQuery } from '@apollo/client';
import { ElementType } from 'react';
import { InputMaybe, SearchResponseObjectType } from '../../__generated__/graphql';
import { SEARCH_BY_QUOTATION_QUERY } from '../../api/queries/searchRecords';
import { useDebounceArray } from '../../helpers/customHooks';
import { useAppSelector } from '../../helpers/reduxHooks';


interface Props {
  SearchListWrapper: ElementType,
}

export default function SearchListQuotationData(props: Props) {
  const { SearchListWrapper } = props;
  const searchInputValue = useAppSelector(state => state.search.searchInputValue);
  const debouncedSearchInput = useDebounceArray(searchInputValue, 200);
  const searchProjectId = useAppSelector(state => state.search.QuotationContext.searchProjectId);
  const searchQuotationId = useAppSelector(state => state.search.QuotationContext.searchQuotationId);

  const isSkip = !searchProjectId || !searchQuotationId;
  const {
    data: searchRecordsByQuotationData,
    loading: searchRecordsByQuotationLoading,
  } = useQuery(SEARCH_BY_QUOTATION_QUERY, {
    variables: {
      project: searchProjectId as string,
      quotation: searchQuotationId as string,
      searchInput: debouncedSearchInput as InputMaybe<string>[],
    },
    fetchPolicy: 'network-only',
    skip: isSkip,
  });
  
  let quotationData: SearchResponseObjectType[] | undefined = undefined;
  
  if (searchRecordsByQuotationData
    && searchRecordsByQuotationData.searchRecordsByQuotation
    && searchRecordsByQuotationData.searchRecordsByQuotation.response
  ) {
    quotationData = searchRecordsByQuotationData.searchRecordsByQuotation.response as SearchResponseObjectType[];
  }  
  
  return (
    <SearchListWrapper
      objectsData={quotationData as SearchResponseObjectType[]}
      isLoading={searchRecordsByQuotationLoading}
      totalObjectsQuantity={quotationData?.length}
    />
  );
}
