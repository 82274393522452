
import { useTranslation } from 'react-i18next';
import {
  EtimReleasesEtimFeatureFeatureTypeChoices,
  QuotationGroupFeatureType,
  GroupTemplateGroupFeatureType,
} from '../../../../../../../__generated__/graphql';
import { getLogicalLabel } from '../../../../../../../helpers/utils';

interface Props {
  groupFeatures: QuotationGroupFeatureType[] | GroupTemplateGroupFeatureType[],
}

const ROGroupFeatures = (props: Props) => {
  const { groupFeatures } = props;
  const { t } = useTranslation();

  return (
    <>
      {groupFeatures.map((feature) => {                
        const featureValue = feature?.etimFeature.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Logical
          ? t(getLogicalLabel(feature.value) as string)
          : feature?.etimFeature.featureType === EtimReleasesEtimFeatureFeatureTypeChoices.Range 
            ? `${feature.value[0]} - ${feature.value[1]}`
            : getLogicalLabel(feature?.value);
        return (
            <div key={feature?.id} className='cursor-default h-10 flex flex-col px-2 rounded-t border border-cgray-200'>
              <p className="text-2xs text-cgray-400">{feature?.etimFeature.localizedDescription}</p>
              <p className='text-sm overflow-auto grow'>{featureValue}</p>
            </div>
        );
      },
      )
      }
    </>
  );
};

export default ROGroupFeatures;