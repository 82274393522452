import { gql } from '../../__generated__/gql';

export const CREATE_PROJECT_WITH_ADDRESS_MUTATION = gql(`
mutation CreateProjectWithAddress(
  $projectType: String!,
  $externalId: Int!,
  $daysOfValidity: Int,
  $customer: ID!,
  $name: String!,
  $notes: String,
  $street: String!,
  $zipCode: String!,
  $city: String!,
  $country: String!,
  $managers: [ID],
  ){
    createProjectWithAddress(
      input: {
        projectType: $projectType
        externalId: $externalId
        daysOfValidity: $daysOfValidity
        customer: $customer
        name: $name
        notes: $notes
        street: $street
        zipCode: $zipCode
        city: $city
        country: $country
        managers: $managers
      }
    )
  { response{
    id
    name
  }
    errors{
      field
      messages
    }
  }
}
`);


export const UPDATE_PROJECT_DETAILS_MUTATION = gql(`
mutation UpdateProject(
  $projectType: String!,
  $externalId: Int!,
  $daysOfValidity: Int!,
  $customer: ID!,
  $name: String!,
  $notes: String,
  $managers: [ID],
  $id: ID,
  ){
    updateProject(
      input: {
        projectType: $projectType
        externalId: $externalId
        daysOfValidity: $daysOfValidity
        customer: $customer
        name: $name
        notes: $notes
        managers: $managers
        id: $id
      }
    )
  { response{
    id
    name
  }
    errors{
      field
      messages
    }
  }
}
`);


export const UPDATE_PROJECT_ADDRESS_MUTATION = gql(`
  mutation UpdateProjectAddress(
    $street: String!,
    $zipCode: String!,
    $city: String!,
    $country: String!,
    $project: ID!,
    $id: ID,
    ){
      updateProjectAddress(
        input: {
          street: $street
          zipCode: $zipCode
          city: $city
          country: $country
          project: $project
          id: $id
        }
      )
    { response{
      id
    }
      errors{
        field
        messages
      }
    }
  }
  `);


export const UPDATE_PROJECT_STATUS_MUTATION = gql(`
  mutation UpdateProjectStatus(
    $quotation: ID!
    $projectStatus: String!
    $triggeredBy: ID!
  ) {
    updateProjectStatus(
      input: {
        quotation: $quotation
        projectStatus: $projectStatus
        triggeredBy: $triggeredBy
      }
    ) {
      response {
        id
        latestQuotation{
          id
        }
      }
      errors {
        field
        messages
      }
    }
  }
`);
