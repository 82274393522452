import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CREATE_GROUP_TEMPLATE } from '../../../../api/mutations/groupTemplates';
import { SHOW_MANUAL_PRICE_CONVERSION_WARNING } from '../../../../api/queries/quotations/group';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { AlertType } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { IconT } from '../../../../layout/icons';
import { setSuccessAlert } from '../../../../redux/alertSlice';
import { setShowCreateGTConfirmationModal, setIsLoadingUpdate, setItemIdToCreateGTFrom } from '../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../shared/dnd/types';

interface Props {
  item: FlattenedItem,
}

export default function CreateGroupTemplate(props: Props) {
  const { item } = props;
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const isError = useAppSelector(state => state.alert.alert?.alertType) === AlertType.ERROR;
  const dispatch = useAppDispatch();

  const [
    getShowManualPriceConversionWarning, 
  ] = useLazyQuery(SHOW_MANUAL_PRICE_CONVERSION_WARNING, {
    fetchPolicy: 'network-only',
    variables: {
      group: item.group?.id as string,
    },
  });

  
  const [
    createGroupTemplate,
    {
      data: mutationData,
      loading: mutationLoading,
    },
  ] = useMutation(CREATE_GROUP_TEMPLATE, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationId as string,
      },
    }],
  });

  const handleStoreGroupAsTemplate = () => {
    createGroupTemplate(
      {
        variables: {
          item: item.id as string,
        },
        onError: () => {
          dispatch(setIsLoadingUpdate(false));
        },
      },
    );
  };

  useEffect(() => {
    if (mutationData && mutationData.createGroupTemplate && mutationData.createGroupTemplate.response) {
      dispatch(setSuccessAlert(
        [`${t('Group Template was successfully created')}`],
      ));
    }
  }, [mutationData, dispatch, t]);

  useEffect(() => {
    if (isError) {
      dispatch(setIsLoadingUpdate(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(mutationLoading));
  }, [dispatch, mutationLoading]);

  const handleClick = () => {
    getShowManualPriceConversionWarning().then((result) => {
      const showWarning = result.data?.showManualPriceConversionWarning?.response;
      
      if (!showWarning) {
        handleStoreGroupAsTemplate();
      } else {
        dispatch(setShowCreateGTConfirmationModal(true));
        dispatch(setItemIdToCreateGTFrom(item.id as string));
      }
    });
  };

  return (
    <div
      onClick={handleClick}
    >
      <IconT className="h-5 w-7 text-xs bg-white text-cgray-600 border-cgray-600 flex items-center border-2 justify-center gap-0.5 font-bold rounded cursor-pointer hover:bg-cblue-200" />
    </div>
  );
}
