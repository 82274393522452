import Tippy from '@tippyjs/react';
import { CSSProperties, forwardRef, useEffect, useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import { setSelectedItem, unsetSelectedItem } from '../../../../../../redux/quotationSlice';
import { Handle } from '../../../../../shared/dnd/Item';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import DefaultProductDetailsCompact from './DefaultProductDetailsCompact';

export interface Props {
  clone?: boolean;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: object;
  indicator?: boolean;
  indentationWidth: number;
  item: FlattenedItem;
  style: CSSProperties,
  wrapperRef?(node: HTMLLIElement): void;
  depth: number;
}

export const ProductItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      clone,
      disableInteraction,
      disableSelection,
      ghost,
      handleProps,
      indicator,
      indentationWidth,
      item,
      style,
      wrapperRef,
      depth,
      ...props
    },
    ref,
  ) => {
    const [showDetails, setShowDetails] = useState(false);

    const copiedItem = useAppSelector(state => state.quotation.copiedItem);
    const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
    const dispatch = useAppDispatch();

    const selectTreeItem = () => {
      dispatch(setSelectedItem(item));
    };

    useEffect(() => {
      if (!copiedItem) {
        dispatch(unsetSelectedItem());
      }
    }, [dispatch, copiedItem]);

    const actualDepth = ghost ? depth : item.depth;

    return (
      <>
        <li
          className={classNames(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction,
          )}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${indentationWidth * actualDepth}px`,
            } as React.CSSProperties
          }
          {...props}
        >
          <div
            onClick={selectTreeItem}
            className={classNames(styles.TreeItem)}
            ref={ref}
            style={
              {
                ...style,
                '--bgColor': 'white',
                '--justifyContent': 'start',
                '--borderBottomColor': (showDetails) ? 'transparent' : '#d3d3d3',
                '--border-b-l-radius': (showDetails) ? '0px 0px' : '4px 4px',
                '--border-b-r-radius': (showDetails) ? '0px 0px' : '4px 4px',
              } as React.CSSProperties
            }
          >
            <div
              className='flex justify-end'
              style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, item.depth)}px` }}
            ></div>
            <div className='w-14 py-3.5 flex justify-between'>
              <div>
                <TreeItemIcon 
                  item={item} 
                  setShowDetails={setShowDetails}
                  showDetails={showDetails}
                />
              </div>
              <Handle {...handleProps} />
            </div>
            <div className="w-12">{item.product?.supplierLabel}</div>
            <div className="w-32">{item.product?.externalId}</div>
            <Tippy content={item?.product?.description} disabled={!item?.product?.description || item?.product?.description.length < 30} placement="top">
              <div className="w-[300px] 3xl:w-[360px] 4xl:w-[440px] truncate">{item?.product?.description}</div>
            </Tippy>
          </div>
          {showDetails && (
            <DefaultProductDetailsCompact
              externalProductId={item.product?.externalId as ID}
              catalogId={item?.product?.catalogId as ID}
              indentationWidth={indentationWidth}
              depth={item.depth}
              productId={item.product?.id as ID}
              maximumUncollapsedDepth={maximumQuotationUncollapsedDepth}
            />
          )}
        </li>
      </>
    );
  },
);
