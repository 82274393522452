import { useQuery } from '@apollo/client';
import { IconEqual, IconPlus, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WageType } from '../../../../../__generated__/graphql';
import { PRODUCT_ESTIMATE_DETAILS_QUERY } from '../../../../../api/queries/quotations/details';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../helpers/utils';
import { LoadingIndicator } from '../../../../../layout';
import ObsoletableValue from '../../fields/ObsoletableValue';
import {
  ManufacturingTimeInput,
  ManufacturingWageSelect,
  ProfitSurchargeInput,
  SalesTimeInput,
  SalesWageSelect,
  ShippingCostInput,
  SpecificSurchargeInput,
} from '../../fields/product';
import AddArrowWrapper from './AddArrowWrapper';
import ProductPriceSuggestion from './productPriceSuggestion/ProductPriceSuggestion';

interface Props {
  productId: ID
  indentationWidth: number;
  depth: number;
}

export default function EstimateProductDetails(props: Props) {
  const { productId, indentationWidth, depth } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();
  const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);

  const [isPriceHovered, setIsPriceHovered] = useState(false);
  const [isLabourCostHovered, setIsLabourCostHovered] = useState(false);
  const [isManufacturingCostHovered, setIsManufacturingCostHovered] = useState(false);

  const {
    data: estimateData,
    loading: estimateLoading,
  } = useQuery(PRODUCT_ESTIMATE_DETAILS_QUERY, {
    variables: {
      product: productId as string,
    },
  });

  if (estimateLoading) {
    return (
      <div className='p-2.5 pr-5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex text-xs 2xl:text-sm text-cgray-400'>
        <LoadingIndicator className="w-full flex h-56 justify-center items-center" />
      </div>
    );
  } else if (!estimateLoading && estimateData && estimateData.productEstimateDetails && estimateData.productEstimateDetails.response) {
    const details = estimateData.productEstimateDetails.response;
    return (
      <div id="styled-scroll" className='p-2.5 border-[1px] border-t-0 rounded-bl rounded-br border-cgray-300 flex h-full text-xs 2xl:text-sm text-left text-cgray-600 overflow-x-auto'>
        <div
          className="flex justify-end"
          style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, depth)}px` }}
        ></div>

        <AddArrowWrapper nestingLevel={1}>
          <div className='relative h-full grid grid-rows-10 border-[1px] gap-5 rounded rounded-tl border-cgray-400 grow'>
            <div className='row-span-5 flex flex-row h-full items-center'>
              <AddArrowWrapper nestingLevel={2}>
                <div className='relative h-full grid grid-rows-10 gap-3 border-b-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                  <div className='row-span-5 flex flex-row h-full items-center '>
                    <AddArrowWrapper nestingLevel={3}>
                      <div className='relative h-full grid grid-rows-10 border-b-[1px] border-r-[1px] rounded border-cgray-400  grow'>
                        <div className='row-span-5 flex flex-row items-center '>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='h-full border-b-[1px] border-r-[1px] rounded border-cgray-400 items-center flex flex-row justify-between grow'>
                              <div className='pl-1 pr-2'>
                                <p className='font-bold'>{t('Net Price/Piece')}</p>
                                <p className='text-cgray-400'>{details.netPricePieceLabel}</p>
                              </div>
                              <div className='h-full flex gap-1 items-center'>
                                <IconPlus className='w-4 h-4' />
                                <div className=" w-28 h-full">
                                  <SpecificSurchargeInput
                                    productId={productId as ID}
                                    quotationId={quotationId as ID}
                                    refetchQueries={[
                                      {
                                        query: QUOTATION_QUERY,
                                        variables: {
                                          quotation: quotationId as string,
                                        },
                                      },
                                      {
                                        query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                        variables: {
                                          product: productId as string,
                                        },
                                      },
                                    ]}
                                    specificSurcharge={details.specificSurcharge}
                                    label
                                  />
                                </div>
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div
                              className={classNames('font-bold', isPriceHovered ? 'text-cblue-500' : '')}
                              onMouseEnter={() => { setIsPriceHovered(true); }}
                              onMouseLeave={() => { setIsPriceHovered(false); }}
                            >{t('Base Sales Price/Piece')}</div>
                            <p className='text-cgray-400'>{details.baseSalesPricePieceLabel}</p>
                          </div>
                        </div>
                        <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
                          <IconPlus className='w-4 h-4 ' />
                        </div>
                        <div className='row-span-5 flex flex-row h-full items-center'>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='pl-1 pr-2 h-full border-t-[1px] border-r-[1px] rounded border-cgray-400 text-left grow'>
                              <div className='font-bold flex items-center'>
                                <div
                                  className={classNames('font-bold', isPriceHovered ? 'text-cblue-500' : '')}
                                  onMouseEnter={() => { setIsPriceHovered(true); }}
                                  onMouseLeave={() => { setIsPriceHovered(false); }}
                                >
                                  {t('Base Sales Price/Piece')}
                                </div>
                                <IconX className='w-4 h-4 mx-1' />
                                {t('Material Cost Burden Rate')}
                              </div>
                              <div className='text-cgray-400 flex items-center'>
                                <p>{details.baseSalesPricePieceLabel}</p>
                                <IconX className='w-4 h-4 mx-1' />
                                <ObsoletableValue
                                  value={details.materialCostBurdenRate?.label}
                                  isCurrent={details.materialCostBurdenRate?.isCurrent}
                                />
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div className='font-bold'>{t('Material Overhead')}</div>
                            <p className='text-cgray-400'>{details.materialOverheadLabel}</p>
                          </div>
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-56 flex justify-between'>
                      <div className='font-bold'>{t('Material Costs')}</div>
                      <p className='text-cgray-400'>{details.materialCostLabel}</p>
                    </div>
                  </div>
                  <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
                    <IconPlus className="w-5 h-5 " />
                  </div>
                  <div className='row-span-5 flex flex-row h-full items-center'>
                    <AddArrowWrapper nestingLevel={3}>
                      <div className='relative h-full grid grid-rows-10 border-t-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                        <div className='row-span-5 flex flex-row h-full items-center'>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='h-full border-b-[1px] border-r-[1px] flex justify-between items-center grow'>
                              <div className="w-40 h-full">
                                <ManufacturingWageSelect
                                  productId={productId}
                                  quotationId={quotationId as ID}
                                  refetchQueries={[
                                    {
                                      query: QUOTATION_QUERY,
                                      variables: {
                                        quotation: quotationId as string,
                                      },
                                    },
                                    {
                                      query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                      variables: {
                                        product: productId as string,
                                      },
                                    },
                                  ]}
                                  manufacturingWage={details.manufacturingWage as WageType}
                                  label={t('Manufacturing wage')}
                                  className='pl-1'
                                />
                              </div>

                              <div className='h-full flex gap-1 items-center'>
                                <IconX className='w-4 h-4' />
                                <div className='w-28 h-full flex flex-col items-center justify-center rounded-sm  bg-cgray-100'>
                                  <div className='w-28 h-full'>
                                    <ManufacturingTimeInput
                                      productId={productId}
                                      quotationId={quotationId as ID}
                                      refetchQueries={[
                                        {
                                          query: QUOTATION_QUERY,
                                          variables: {
                                            quotation: quotationId as string,
                                          },
                                        },
                                        {
                                          query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                          variables: {
                                            product: productId as string,
                                          },
                                        },
                                      ]}
                                      manufacturingTime={details.manufacturingTime}
                                      label={t('Manufacturing Time')}
                                      className="text-right pr-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div
                              className={classNames('font-bold', isLabourCostHovered ? 'text-cblue-500' : '')}
                              onMouseEnter={() => { setIsLabourCostHovered(true); }}
                              onMouseLeave={() => { setIsLabourCostHovered(false); }}
                            >
                              {t('Manufacturing Labour Cost')}
                            </div>
                            <p className='text-cgray-400'>{details.manufacturingLabourCostLabel}</p>
                          </div>
                        </div>
                        <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
                          <IconPlus className='w-4 h-4 ' />
                        </div>
                        <div className='row-span-5 flex flex-row h-full  items-center'>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='pl-1 pr-2 h-full border-t-[1px] border-r-[1px] rounded border-cgray-400 text-left  grow'>
                              <div className='font-bold flex items-center'>
                                <div
                                  className={classNames('font-bold', isLabourCostHovered ? 'text-cblue-500' : '')}
                                  onMouseEnter={() => { setIsLabourCostHovered(true); }}
                                  onMouseLeave={() => { setIsLabourCostHovered(false); }}
                                >
                                  {t('Manufacturing Labour Cost')}
                                </div>
                                <IconX className='w-4 h-4 mx-1' />
                                {t('Manufacturing Overhead Rate')}
                              </div>
                              <div className='text-cgray-400 flex items-center'>
                                {details.manufacturingLabourCostLabel}
                                <IconX className='w-4 h-4 mx-1' />
                                <ObsoletableValue
                                  value={details.manufacturingOverheadRate?.label}
                                  isCurrent={details.manufacturingOverheadRate?.isCurrent}
                                />
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div className='font-bold'>{t('Manufacturing Overhead')}</div>
                            <p className='text-cgray-400'>{details.manufacturingOverheadLabel}</p>
                          </div>
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-56 flex justify-between'>
                      <div className='font-bold'>{t('Production Cost')}</div>
                      <p className='text-cgray-400'>{details.productionCostLabel}</p>
                    </div>
                  </div>
                </div>
              </AddArrowWrapper>
              <div className='pl-1 pr-2 w-72 flex justify-between'>
                <div
                  className={classNames('font-bold', isManufacturingCostHovered ? 'text-cblue-500' : '')}
                  onMouseEnter={() => { setIsManufacturingCostHovered(true); }}
                  onMouseLeave={() => { setIsManufacturingCostHovered(false); }}
                >
                  {t('Manufacturing Cost')}
                </div>
                <p className='text-cgray-400'>{details.manufacturingCostLabel}</p>
              </div>
            </div>
            <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 items-center'>
              <IconPlus className='w-6 h-6 ' />
            </div>

            <div className='row-span-5 flex flex-row items-center h-full'>
              <AddArrowWrapper nestingLevel={2}>
                <div className='relative h-full grid grid-rows-10 gap-3 border-t-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                  <div className='row-span-5 flex flex-row h-full items-center'>
                    <AddArrowWrapper nestingLevel={3}>
                      <div className='relative h-full flex flex-col border-b-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                        <div className='flex flex-row  items-center'>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='pl-1 pr-2 h-full border-b-[1px] border-r-[1px] rounded border-cgray-400 text-left  grow'>
                              <div className='font-bold flex items-center'>
                                <div
                                  className={classNames('font-bold', isManufacturingCostHovered ? 'text-cblue-500' : '')}
                                  onMouseEnter={() => { setIsManufacturingCostHovered(true); }}
                                  onMouseLeave={() => { setIsManufacturingCostHovered(false); }}
                                >
                                  {t('Manufacturing Cost')}
                                </div>
                                <IconX className='w-4 h-4 mx-1' />
                                {t('Sales Overhead Rate')}
                              </div>
                              <div className='text-cgray-400 flex items-center'>
                                {details.manufacturingCostLabel}
                                <IconX className='w-4 h-4 mx-1' />
                                <ObsoletableValue
                                  value={details.salesOverheadRate?.label}
                                  isCurrent={details.salesOverheadRate?.isCurrent}
                                />
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div className='font-bold'>{t('Sales Overhead')}</div>
                            <p className='text-cgray-400'>{details.salesOverheadLabel}</p>
                          </div>
                        </div>
                        <div className=' -z-10 absolute top-1/4 right-0 h-full w-full flex justify-end pr-4'>
                          <IconPlus className='w-4 h-4 ' />
                        </div>
                        <div className='flex flex-row  items-center'>
                          <AddArrowWrapper nestingLevel={4}>
                            <div className='h-full border-b-[1px] border-t-[1px] border-r-[1px] rounded border-cgray-400 text-center flex flex-row justify-between items-center grow'>
                              <div className="w-40 h-full">
                                <SalesWageSelect
                                  productId={productId}
                                  salesWage={details.salesWage as WageType | undefined}
                                  quotationId={quotationId as ID}
                                  refetchQueries={[
                                    {
                                      query: QUOTATION_QUERY,
                                      variables: {
                                        quotation: quotationId as string,
                                      },
                                    },
                                    {
                                      query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                      variables: {
                                        product: productId as string,
                                      },
                                    },
                                  ]}
                                  label={t('Sales Wage')}
                                  className='pl-1'
                                />
                              </div>
                              <div className='h-full flex gap-1 items-center'>
                                <IconX className='w-4 h-4' />
                                <div className='w-28 h-full'>
                                  <SalesTimeInput
                                    productId={productId}
                                    salesTime={details.salesTime as number | undefined}
                                    quotationId={quotationId as ID}
                                    isSalesWage={!!details.salesWage}
                                    refetchQueries={[
                                      {
                                        query: QUOTATION_QUERY,
                                        variables: {
                                          quotation: quotationId as string,
                                        },
                                      },
                                      {
                                        query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                        variables: {
                                          product: productId as string,
                                        },
                                      },
                                    ]}
                                    label={t('Sales Time')}
                                  />
                                </div>
                              </div>
                            </div>
                          </AddArrowWrapper>
                          <div className='pl-1 pr-2 w-60 flex justify-between'>
                            <div className='font-bold'>{t('Special Direct Costs Sales')}</div>
                            <p className='text-cgray-400'>{details.specialDirectCostsSalesLabel}</p>
                          </div>
                        </div>
                        <div className=' -z-10 absolute top-1/2 right-0 flex justify-end pr-4'>
                          <IconPlus className='w-4 h-4' />
                        </div>
                        <div className='pl-1 pr-2 my-2 self-end flex justify-end items-center w-60 h-8'>
                          <div className='w-28'>
                            <ShippingCostInput
                              productId={productId}
                              quotationId={quotationId as ID}
                              refetchQueries={[
                                {
                                  query: QUOTATION_QUERY,
                                  variables: {
                                    quotation: quotationId as string,
                                  },
                                },
                                {
                                  query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                                  variables: {
                                    product: productId as string,
                                  },
                                },
                              ]}
                              shippingCost={details.shippingCost}
                              label
                            />
                          </div>
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-56 flex justify-between'>
                      <div className='font-bold'>{t('Sales Cost')}</div>
                      <p className='text-cgray-400'>{details.salesCostLabel}</p>
                    </div>
                  </div>
                  <div className=' -z-10 absolute top-0 right-0 h-full w-full flex justify-end pr-4 pt-10 items-center'>
                    <IconPlus className='w-5 h-5 ' />
                  </div>
                  <div className='row-span-5 flex flex-row items-center'>
                    <AddArrowWrapper nestingLevel={3}>
                      <div className='relative flex flex-col border-t-[1px] border-r-[1px] rounded border-cgray-400 grow'>
                        <div className='pl-1 pr-2 flex items-center gap-1'>
                          <div
                            className={classNames('font-bold', isManufacturingCostHovered ? 'text-cblue-500' : '')}
                            onMouseEnter={() => { setIsManufacturingCostHovered(true); }}
                            onMouseLeave={() => { setIsManufacturingCostHovered(false); }}
                          >
                            {t('Manufacturing Cost')}
                          </div>
                          <div className='flex items-center font-bold text-lg'>
                            <IconX className='w-4 h-4' />
                          </div>
                          <div className='font-bold'>{t('Administrative Overhead Rate')}</div>
                        </div>
                        <div className='pl-1 pr-2 flex items-center gap-1 text-cgray-400'>
                          <p>{details.manufacturingCostLabel}</p>
                          <IconX className='w-4 h-4' />
                          <ObsoletableValue
                            value={details.administrativeOverheadRate?.label}
                            isCurrent={details.administrativeOverheadRate?.isCurrent}
                          />
                        </div>
                      </div>
                    </AddArrowWrapper>
                    <div className='pl-1 pr-2 w-56 flex justify-between'>
                      <div className='font-bold'>{t('Administrative Costs')}</div>
                      <p className='text-cgray-400'>{details.administrativeCostsLabel}</p>
                    </div>
                  </div>
                </div>
              </AddArrowWrapper>
              <div className='pl-1 pr-2 w-72 flex justify-between'>
                <div className='font-bold'>{t('Cost Administration Sales')}</div>
                <p className='text-cgray-400'>{details.costAdministrationSalesLabel}</p>
              </div>
            </div>
          </div>
        </AddArrowWrapper>
        <div className='pl-5 pb-2 w-72 2xl:w-80 flex flex-col gap-4 justify-center items-center border-[1px] rounded border-cgray-400 -ml-5 self-stretch'>
          <div className='pl-1 pr-2 w-full flex justify-between text-lg'>
            <div className='font-bold'>{t('Cost Per Item')}</div>
            <div className='text-cgray-400 font-bold'>{details.costPerItemLabel}</div>
          </div>
          <div className='w-full flex justify-between items-center'>
            <div className='w-28 h-full'>
              <ProfitSurchargeInput
                productId={productId}
                profitSurcharge={details.profitSurcharge}
                quotationId={quotationId as ID}
                refetchQueries={[
                  {
                    query: QUOTATION_QUERY,
                    variables: {
                      quotation: quotationId as string,
                    },
                  },
                  {
                    query: PRODUCT_ESTIMATE_DETAILS_QUERY,
                    variables: {
                      product: productId as string,
                    },
                  },
                ]}
                label
              />
            </div>
            <div className='font-bold text-xl pr-6'><IconEqual /></div>
          </div>
          <div className='pl-1 pr-2 w-full flex justify-between text-lg'>
            <div className='font-bold'>{t('Net Sales Price/Piece')}</div>
            <div className='text-cgray-400 font-bold'>{details.netSalesPricePieceLabel}</div>
          </div>
          <div className='pl-1 pr-2 w-full flex justify-between border-t pt-2'>
            <div className='font-bold'>{t('Profit per Piece')}</div>
            <div className='text-cgray-400 font-bold'>{details.profitPerPieceLabel}</div>
          </div>
          <ProductPriceSuggestion productId={productId} />
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
