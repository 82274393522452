import type { UniqueIdentifier } from '@dnd-kit/core';
import { useEffect, useMemo, useState } from 'react';
import { MaxUncollapsedDepthSource } from '../../../constants';
import { flattenTree, removeChildrenOf } from '../../../helpers/dnd/utilities';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { classNames, handleQuotationGroupCollapse } from '../../../helpers/utils';
import { setItemToCollapse, setItemToUncollapse, setMaximumQuotationUncollapsedDepth, 
  setTreeItems, setCollapsedItemsId,
} from '../../../redux/quotationSlice';
import type { FlattenedItem } from '../../shared/dnd/types';
import HotKeysHandler from './actions/HotKeysHandler';
import QuotationListDndItem from './QuotationListDndItem';
import { QuotationListItem } from './row/QuotationListItem';

interface Props {
  defaultItems: FlattenedItem[];
  indentationWidth: number;
}

export default function QuotationList(props: Props) {
  const {
    indentationWidth, defaultItems,
  } = props;

  const [items, setItems] = useState(defaultItems);
  const dndGroupId = useAppSelector(state => state.quotation.dndGroupId);
  const singleGroupItems = items.filter(item => item.id === dndGroupId);
  const collapsedItemsId = useAppSelector(state => state.quotation.collapsedItemsId);
  const dispatch = useAppDispatch();

  const staticListFlattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce<any>(
      (acc, { children, collapsed, id }) =>
        collapsed && children.length ? [...acc, id] : acc,
      [],
    );

    return removeChildrenOf(
      flattenedTree,
      collapsedItems,
    );
  }, [items]);

  // Update array of excluded from staticListFlattenedItems items IDs each time when Group shown in DnD mode are change.
  const excludedItemsIdList: UniqueIdentifier[] = useMemo(() => {
    const singleGroupflattenedTree = flattenTree(singleGroupItems);
    const excludedItemsId = singleGroupflattenedTree.map(item => item.id);

    return excludedItemsId;
  }, [singleGroupItems]);

  useEffect(() => {
    const preparedStaticListFlattenedItems = staticListFlattenedItems.filter(item => !excludedItemsIdList.includes(item.id));
    const deepestQuotationUncollapsedLevel = Math.max(...preparedStaticListFlattenedItems.map(item => item.depth));
    dispatch(setMaximumQuotationUncollapsedDepth({ 
      maxDepth: deepestQuotationUncollapsedLevel, 
      source: MaxUncollapsedDepthSource.QuotationListStaticItem,
    }));

    // used to navigate by arrows keys
    dispatch(setTreeItems(staticListFlattenedItems));
  }, [dndGroupId, staticListFlattenedItems, dispatch, excludedItemsIdList]);

  useEffect(() => {
    // Update local items each time we have updated items incoming outside this component.
    setItems(handleQuotationGroupCollapse(defaultItems, collapsedItemsId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItems]);

  function handleCollapse(idsToProcess: UniqueIdentifier[]) {
    setItems((itemsToUpdate) =>
      handleQuotationGroupCollapse(itemsToUpdate, idsToProcess),
    );
    dispatch(setItemToCollapse(''));
    dispatch(setItemToUncollapse(''));

    dispatch(setCollapsedItemsId(idsToProcess));
  }

  const QuotationItemsList = () => {
    const CombinedList = staticListFlattenedItems.map((staticFlattenedItem) => {
      if (!dndGroupId || (dndGroupId && !excludedItemsIdList.includes(staticFlattenedItem.id))) {
        const isRootItem = !staticFlattenedItem.parentId;
        
        return (
          <div key={staticFlattenedItem.id} className={classNames(isRootItem && 'sticky top-[139px] z-20')}>
              <QuotationListItem
                key={staticFlattenedItem.id}
                id={staticFlattenedItem.id}
                item={staticFlattenedItem}
                depth={staticFlattenedItem.depth}
                indentationWidth={indentationWidth}
                indicator
                collapsed={Boolean(staticFlattenedItem.collapsed && staticFlattenedItem.children.length)}
                onCollapse={
                  staticFlattenedItem.children.length
                    ? () => handleCollapse([staticFlattenedItem.id])
                    : undefined
                }
                quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
                initialIndex={staticFlattenedItem.index}
              />
          </div>
        );
      } else if (dndGroupId === staticFlattenedItem.id) {
        return <QuotationListDndItem 
          key={staticFlattenedItem.id} 
          indentationWidth={indentationWidth} 
          defaultItems={singleGroupItems} 
          onCollapse={
            staticFlattenedItem.children.length
              ? (id) => handleCollapse([id])
              : undefined
          }
          quotationLength={staticListFlattenedItems.filter(item => !item.parentId).length}
          initialIndex={staticFlattenedItem.index}
        />;
      }
    });

    return <>{CombinedList}</>;
  };

  return (
    <>
      <HotKeysHandler />
      <QuotationItemsList />
    </>
  );
}
