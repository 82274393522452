import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { DELETE_QUOTATION_ITEMS_MUTATION } from '../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import ModalButtons from '../../../layout/fields/ModalButtons';
import { setSuccessAlert } from '../../../redux/alertSlice';
import { setIsLoadingUpdate, unsetSelectedItem } from '../../../redux/quotationSlice';

interface Props {
  handleClose: () => void,
}

export default function DeleteItemModal(props: Props) {
  const { handleClose } = props;
  const { quotationId } = useParams();
  const QuotationContext = useAppSelector(state => state.search.QuotationContext);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const dispatch = useAppDispatch();
  const location = useLocation();
  let quotationIdFromContext: string | undefined = undefined;
  const { t } = useTranslation();


  if (quotationId) {
    quotationIdFromContext = quotationId;
  } else if (!quotationId && location.pathname.includes('paste') && QuotationContext) {
    quotationIdFromContext = QuotationContext.destinationQuotationId;
  }

  const [
    deleteMutation,
    {
      data: mutationData,
      loading: deleteMutationLoading,
    }] = useMutation(DELETE_QUOTATION_ITEMS_MUTATION, {
    refetchQueries: [{
      query: QUOTATION_QUERY,
      variables: {
        quotation: quotationIdFromContext as string,
      },
      fetchPolicy: 'no-cache',
    }],
  });

  const onDelete = () => {
    const variables = {
      items: [selectedItem?.id as string], 
      quotation: quotationIdFromContext as string,
    };

    deleteMutation({
      variables,
      onError: () => {
        handleClose();
        dispatch(setIsLoadingUpdate(false));
      },
    },
    );
  };

  useEffect(() => {
    if (mutationData && mutationData.deleteQuotationItems && mutationData.deleteQuotationItems.response) {
      dispatch(setSuccessAlert(
        [`${t('Item was successfully deleted')}`],
      ));
      dispatch(unsetSelectedItem());
      handleClose();
    }
  }, [mutationData, dispatch, quotationIdFromContext, handleClose, t]);

  useEffect(() => {
    dispatch(setIsLoadingUpdate(deleteMutationLoading));
  }, [dispatch, deleteMutationLoading]);
  
  if (!selectedItem) {
    return (<></>);
  }

  return (
      <div className='flex flex-col justify-between items-center rounded drop-shadow-xl bg-white' onClick={e => e.stopPropagation()}>
        <div className='pl-8 mb-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'>Delete Item</div>
        <div className='w-full pl-8 mb-8'>
        <span>{t('This action will delete')}</span>
          <span>{' '}</span>
          <span className='font-semibold'>{`${selectedItem?.itemType}`}</span>
          <span>{' '}</span>
          <span>{t('item')}</span>
        </div>
        <ModalButtons 
          mutationLoading={deleteMutationLoading} 
          cancelButtonText='Cancel' 
          submitButtonText="Delete" 
          handleClose={handleClose} 
          onSubmit={onDelete}
          wrapperClassName='mb-8 pr-8 flex justify-end w-full gap-x-8'
        />
      </div>
  );
}
