import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { getItemWidth } from '../../../../../helpers/dnd/utilities';
import { FlattenedItem } from '../../../../shared/dnd/types';
import { DescriptionInput, NotesInput } from '../../fields/service';

interface Props {
  item: FlattenedItem,
  indentationWidth: number;
  depth: number;
  maximumUncollapsedDepth: number;
}

export default function DefaultServiceDetailsEditable(props: Props) {
  const { item, indentationWidth, depth, maximumUncollapsedDepth } = props;
  const { t } = useTranslation();
  const { quotationId } = useParams();

  return (
    <div className='p-2.5 pr-5 flex border border-t-0 rounded-bl rounded-br border-cgray-300 text-xs 2xl:text-sm text-cgray-400 z-20 bg-white'>
      <div
        className="flex justify-end"
        style={{ width: `${getItemWidth(maximumUncollapsedDepth, indentationWidth, depth)}px` }}
      ></div>
      <div className="flex flex-col w-full gap-2 text-sm text-cgray-600 whitespace-normal">
        <div className="grid grid-cols-12 w-full gap-2 text-sm text-cgray-600">
          <div className="col-span-4">
            <DescriptionInput 
              refetchQueries={[{
                query: QUOTATION_QUERY,
                variables: {
                  quotation: quotationId as string,
                },
              }]}
              description={item.service?.description as string}
              id={item.service?.id}
            />
          </div>
          <div className="col-span-1"></div>
          <div className='col-span-7 justify-self-end'></div>
          <div className='h-36 bg-cgray-100 hover:bg-cgray-200 cursor-text col-span-4 flex flex-col pl-2 py-px rounded-t'>
            <p className=" text-2xs text-cgray-400">{t('Notes')}</p>
            <NotesInput 
              notes={item.service?.notes}
              id={item.service?.id}
              refetchQueries={
                [{
                  query: QUOTATION_QUERY,
                  variables: {
                    quotation: quotationId as string,
                  },
                }]
              }
            />
          </div>
        </div>

      </div>
    </div>
  );
}
