import React, { CSSProperties, forwardRef } from 'react';
import { classNames } from '../../../../../helpers/utils';
import styles from '../../../../shared/dnd/TreeItem.module.css';

export interface Props {
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  indentationWidth: number;
  indicator?: boolean;
  wrapperRef?(node: HTMLLIElement): void;
  style: CSSProperties;
  children: JSX.Element;
}

const WrapperRowGroup = forwardRef<HTMLDivElement, Props>(
  (
    {
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      indentationWidth,
      indicator,
      collapsed,
      style,
      wrapperRef,
      children,
      ...props
    },
    ref,
  ) => {

    return (
      <>
        <li
          className={classNames(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction,
          )}
          ref={wrapperRef}
          style={
            {
              '--spacing': `${indentationWidth * depth}px`,
            } as React.CSSProperties
          }
          {...props}
        >
          <div ref={ref} className='w-full h-full'>{children}</div>
        </li>
      </>
    );
  },
);

export default WrapperRowGroup;