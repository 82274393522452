export const SIGNATURE_EXPIRED_ERROR = 'Signature has expired';
export const REFRESH_TOKEN_EXPIRED_ERROR = 'Refresh token is expired';

export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum SearchType {
  DESCRIPTION = 'DESCRIPTION',
  ETIM = 'ETIM',
  QUOTATION = 'QUOTATION',
  UNIVERSAL = 'UNIVERSAL',
}

export enum SearchResultsType {
  PRODUCTS = 'PRODUCTS',
  All = 'ALL',
  GROUPS = 'GROUPS',
}

export enum MaxUncollapsedDepthSource {
  QuotationListStaticItem = 'QuotationListStaticItem',
  QuotationListDndItem = 'QuotationListDndItem',
}
