import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { EtimGroupType } from '../../../__generated__/graphql';
import { GROUPS_QUERY } from '../../../api/queries/etimReleases';
import { EtimGroup } from './classFilter';

export default function FilterScope() {
  const { t } = useTranslation();

  const {
    data: groupsData,
  } = useQuery(GROUPS_QUERY);

  return (
    <div
      className="w-72 flex flex-col gap-4 pr-2 border-r-2 border-cgray-300 max-h-screen overflow-y-scroll overflow-x-clip"
    >
      <h1 className="font-bold text-cblue-500">{t('Groups')}</h1>

      <div className='pb-16'>
        {(groupsData && groupsData.groups && groupsData.groups.response) && (
          groupsData.groups.response.map((group) => (
            <EtimGroup key={group?.id} group={group as EtimGroupType} />
          ))
        )}
      </div>
    </div>
  );
}
