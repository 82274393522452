import { useQuery, useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { IconX } from '@tabler/icons-react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationGroupFeatureType } from '../../../__generated__/graphql';
import { DUPLICATE_FEATURE_PRESET } from '../../../api/mutations/etimReleases';
import { PAGINATED_FEATURES_PRESET_QUERY } from '../../../api/queries/etimReleases';
import { QUOTATION_GROUP_DETAILS_QUERY } from '../../../api/queries/quotations/details';
import { useAppDispatch, useAppSelector } from '../../../helpers/reduxHooks';
import { LoadingIndicator, Pagination } from '../../../layout';
import { setIsPresetSuggestionOpened } from '../../../redux/quotationSlice';
import FeatureSearchInput from '../../projects/fields/FeatureSearchInput';
import EtimGroupFeature from '../list/row/details/groupDetails/groupFeature/EtimGroupFeature';

  
function PresetSuggestionOverlay() {
  const isPresetSuggestionOpened = useAppSelector(state => state.quotation.isPresetSuggestionOpened);
  const activeQuotationGroupId = useAppSelector(state => state.quotation.activeQuotationGroupId);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState<string>('');

  const {
    data: presetData,
    loading: presetLoading,
  } = useQuery(PAGINATED_FEATURES_PRESET_QUERY, {
    variables: {
      page: page,
      searchInput: searchInput,
    },
    skip: !activeQuotationGroupId,
  });

  const presetResponse = presetData?.paginatedFeaturesPreset?.response;

  const [duplicatePreset, { loading: duplicateLoading }] = useMutation(DUPLICATE_FEATURE_PRESET, {
    refetchQueries: [{
      query: QUOTATION_GROUP_DETAILS_QUERY,
      variables: { group: activeQuotationGroupId },
    }],
  });

  const handleUsePreset = async (presetId: string) => {
    try {
      await duplicatePreset({
        variables: {
          group: activeQuotationGroupId,
          featurePreset: presetId,
        },
      });
      dispatch(setIsPresetSuggestionOpened(false));
    } catch (error) {
      console.error('Error duplicating preset:', error);
    }
  };


  return (
    <Transition.Root show={isPresetSuggestionOpened} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-40"
        onClose={() => dispatch(setIsPresetSuggestionOpened(false))}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 backdrop-blur-sm bg-white/10" />

          <div className="fixed w-2/5 inset-y-0 right-0 max-w-full flex pl-8">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-full flex">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 -left-8 flex bg-cgray-200">
                    <button
                      type="button"
                      className="text-cgray-500"
                      onClick={() => dispatch(setIsPresetSuggestionOpened(false))}
                    >
                      <IconX className="h-8 w-8" />
                    </button>
                  </div>
                </Transition.Child>
                <div id="styled-scroll" className="grow flex h-full bg-white shadow-xl overflow-y-auto">
                  <div className='grow flex gap-8 flex-col justify-start items-center h-full rounded bg-white' onClick={e => e.stopPropagation()}>
                    <div
                      className='shrink-0 pl-8 bg-cblue-100 w-full h-14 border-b-2 border-cblue-500 font-bold flex items-center text-cblue-500'
                    >
                      {t('Add all preset features to the selected group')}
                    </div>
                    <FeatureSearchInput
                      placeholder={t('Search')}
                      onChange={(value) => setSearchInput(value)}
                      value={searchInput}
                    />
                    <div className='w-full pl-8'>
                      <span>{t('The following preset(s) can be used:')}</span>
                    </div>
                    <div className='w-full px-10 flex flex-col gap-4'>
                        
                        
                      {presetLoading && <LoadingIndicator className="w-full h-96 flex justify-center items-center" /> }
                      {presetResponse?.objects?.length === 0 && <div className='w-full h-96 flex justify-center items-center'>{t('No presets found')}</div>}
                      {presetResponse?.objects?.map((item) => (
                        <div key={item?.id} className='flex justify-between items-center border-b border-cgray-200 pb-4'>
                          <div className='flex gap-2 items-center flex-wrap'>
                            {item?.features?.map((feature) => (
                              <EtimGroupFeature key={feature?.etimFeature?.id} item={feature as QuotationGroupFeatureType} groupId={activeQuotationGroupId} readOnly/>
                            ))}
                          </div>
                          <button
                            type="button"
                            disabled={duplicateLoading}
                            onClick={() => handleUsePreset(item?.id)}
                            className="col-span-1 flex flex-shrink-0 justify-center items-center w-20 h-7 rounded my-3 text-sm text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
                          >
                            {t('Use')}
                          </button>
                        </div>
                      ))}
                      {
                        !!presetResponse?.totalObjectsQuantity &&
                        <div className="h-14">
                          <Pagination
                            page={presetResponse.page as number}
                            hasNext={presetResponse.hasNext as boolean}
                            hasPrev={presetResponse.hasPrev as boolean}
                            elidedPageRange={presetResponse.elidedPageRange as string[]}
                            onPageChange={(e:number) => setPage(e)}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PresetSuggestionOverlay;
