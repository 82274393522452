import { Switch as HeadlessSwitch } from '@headlessui/react';
import { IconCheck, IconX } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../helpers/utils';

interface Props {
  value: boolean,
  onChange: () => void,
  className: string,
  isLoading?: boolean,
  label: string,
  isDisabled?: boolean,
}

const SwitchNav = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { t } = useTranslation();

  const {
    value, onChange, className, isLoading, label, isDisabled = false,
  } = props;

  let switchColor = 'bg-cblue-500 hover:bg-cblue-200';
  if (isLoading) {
    switchColor = 'cyellow-500';
  } else if (value) {
    switchColor = 'bg-white';
  }
  
  return (
    <>
      {
        !isDisabled ?
        <HeadlessSwitch.Group as="div" className={className}>
        <HeadlessSwitch
          ref={ref}
          checked={value}
          onChange={onChange}
          className={`${switchColor} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent ring-2 ring-white rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
        >
          <span      
            aria-hidden="true"
            className={classNames(
              value ? 'translate-x-5' : 'translate-x-0',
              'text-cblue-500 flex items-center justify-center pointer-events-none h-5 w-5 rounded-full border-2 border-cblue-500 bg-white shadow transform transition ease-in-out duration-200',
            )}
          >
            {value ? <IconCheck className='w-3 h-3'/> : <IconX  className='w-3 h-3'/>}
            </span>
        </HeadlessSwitch>
        <HeadlessSwitch.Label passive className={classNames('text-white', 'text-base font-medium transition-colors ease-in-out duration-200')}>{label}</HeadlessSwitch.Label>
      </HeadlessSwitch.Group>
          :
          <button className={classNames(className, 'cursor-default')} ref={ref}>
            <Tippy content={t('Compose mode is disabled for ReadOnly quotations')}>
              <div
                className={'cursor-default relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent ring-2 ring-cgray-400 rounded-full transition-colors ease-in-out duration-200 focus:outline-none'}
              >
                  <span
                    aria-hidden="true"
                    className='text-cblue-500 flex items-center justify-center translate-x-0 h-5 w-5 rounded-full border-2 border-cgray-400 bg-cgray-400 shadow transform transition ease-in-out duration-200'
                  >
                    {value ? <IconCheck className='w-3 h-3'/> : <IconX  className='w-3 h-3'/>}
                  </span>
              </div>
            </Tippy>
            <div className='text-cgray-400 text-base font-medium transition-colors ease-in-out duration-200'>{label}</div>
          </button>
      }
    </>
  );
});

export default SwitchNav;