import { gql } from '../../__generated__/gql';


export const INLINE_CATALOGS_QUERY = gql(`
  query inlineCatalogs{
    inlineCatalogs{
      id
      name
    }
  }
`);

