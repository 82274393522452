import { useTranslation } from 'react-i18next';
import { getItemWidth } from '../../helpers/dnd/utilities';
import { useAppSelector } from '../../helpers/reduxHooks';

interface Props {
  indentationWidth: number;
}

export default function SearchListHeader(props: Props) {
  const { indentationWidth } = props;
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);
  const { t } = useTranslation();

  return (
    <div className="sticky top-0 z-20">
      <div className="w-full flex items-center gap-6 bg-cblue-100 border-t-2 border-b-2 border-cblue-500 pt-2 pb-2 mb-3 font-bold text-sm text-cgray-600">
        <div
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, 0)}px` }}
        ></div>
        <div className='w-14 h-8 flex items-center justify-end'></div>
        <div className="w-20">{t('Unit')}</div>
        <div className="w-24">{t('Supplier')}</div>
        <div className="w-32">{t('Product ID')}</div>
        <div className="w-[500px] 3xl:w-[650px] 4xl:w-[1000px] truncate">{t('Description')}</div>
        <div className="w-8"></div>
      </div>
    </div>
  );
}
