import type { UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useEffect } from 'react';
import { findDeepestUncollapsedLevel, getChildCount } from '../../../../helpers/dnd/utilities';
import { useAppDispatch } from '../../../../helpers/reduxHooks';
import { setMaximumQuotationUncollapsedDepth } from '../../../../redux/quotationSlice';
import type { FlattenedItem } from '../../../shared/dnd/types';
import QuotationListItem from './QuotationListItem';

interface Props {
  items: FlattenedItem[],
  indentationWidth: number,
  handleCollapse: (id: UniqueIdentifier[]) => void,
  depth: number;
}

export default function QuotationList(props: Props) {
  const { items, indentationWidth, handleCollapse, depth } = props;

  const sortedIds = items.map(({ id }) => id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const deepestUncollapsedLevel = findDeepestUncollapsedLevel(items);
    dispatch(setMaximumQuotationUncollapsedDepth({ maxDepth: deepestUncollapsedLevel }));
  }, [items, dispatch]);

  return (
    <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {items.map((item) => (
          <QuotationListItem
            key={item.id}
            id={item.id}
            item={item}
            depth={depth}
            indentationWidth={indentationWidth}
            indicator
            collapsed={Boolean(item.collapsed && item.children.length)}
            childCount={getChildCount(items, item.id) + 1}
            onCollapse={item.children.length
              ? () => handleCollapse([item.id])
              : undefined
            }
          />
        ))}
      </SortableContext>
  );
}