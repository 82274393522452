import { gql } from '../../../__generated__/gql';

export const PRODUCTION_UNIT_STATUSES_QUERY = gql(`
query productionUnitStatuses{
    productionUnitStatuses{
      response {
        id
        sortOrder
        title
        color
        statusType
        isReadonly
      }
      errors {
        messages
        field
      }
  }
}
`);


export const SHOW_MANUAL_PRICE_CONVERSION_WARNING = gql(`
  query showManualPriceConversionWarning($group: ID!) {
      showManualPriceConversionWarning(group: $group){
        response
        errors{
          messages
          field
        }
      }
  }
  `);
