import {
  IconIdOff,
  IconListDetails,
} from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { FlattenedItem } from '../../../shared/dnd/types';

interface Props {
  item: FlattenedItem;
  showDetails: boolean,
  setShowDetails: Dispatch<SetStateAction<boolean>>,
  isItemHoveredId: string,
  setIsItemHoveredId: (id: string | null) => void,
}

export default function HoveredContextMenu(props: Props) {
  const { item, showDetails, setShowDetails, isItemHoveredId, setIsItemHoveredId } = props;
  const copiedItem = useAppSelector(state => state.quotation.copiedItem);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);
  const { t } = useTranslation();


  const showDetailsHandleClick = (isShow: boolean) => {
    if (setShowDetails) setShowDetails(!isShow);
  };

  const hoveredStyles = () => {
    if (copiedItem && copiedItem.id === item.id) {
      return 'bg-cblue-300';
    }
    if (selectedItem && selectedItem.id === item.id) {
      return 'bg-cblue-200';
    }
    return 'bg-white';
  };

  return (
    <div 
      className={`absolute right-0 inset-y-0 mr-4 flex items-center justify-center gap-3 ${hoveredStyles()}`}
      onMouseLeave={() => {if (isItemHoveredId !== item.id) setIsItemHoveredId(null);}}
    >
      {showDetails ? (
        <>
          <Tippy content={t('Hide details')} placement="top" >
            <IconIdOff className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
      ) : (
        <>
          <Tippy content={t('Show details')} placement="top" >
            <IconListDetails className="h-5 w-5 hover:bg-cblue-200 cursor-pointer" onClick={() => showDetailsHandleClick(showDetails)} />
          </Tippy>
        </>
        
      )}
    </div>
  );
}
