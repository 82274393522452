import { useQuery } from '@apollo/client';
import { ClassType } from '../../../../__generated__/graphql';
import {
  CLASSES_QUERY,
} from '../../../../api/queries/etimReleases';
import { useDebounceValue } from '../../../../helpers/customHooks';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { EtimClass } from './EtimClass';
import EtimSearchInput from './EtimSearchInput';

interface Props {
  selectedGroupId: string,
}

function EtimClasses(props: Props) {
  const { selectedGroupId } = props;

  const searchClassesInput = useAppSelector(state => state.search.searchClassesInput);
  const debouncedSearch = useDebounceValue(searchClassesInput, 200);
  const searchInput = debouncedSearch ? debouncedSearch.toString() : undefined;
  const {
    data: classesData,
  } = useQuery(CLASSES_QUERY, {
    variables: {
      group: selectedGroupId,
      searchInput: searchInput,
    },
  });


  // Make a subquery to get the total number of classes for current group and show the EtimSearchInput component
  const {
    data: totalClassesQuantityData,
  } = useQuery(CLASSES_QUERY, {
    variables: {
      group: selectedGroupId,
    },
  });

  let showEtimSearchInput = false;
  if (totalClassesQuantityData
    && totalClassesQuantityData.classes
    && totalClassesQuantityData.classes.response) {
    showEtimSearchInput = totalClassesQuantityData.classes.response.length > 10 ? true : false;
  }


  return (
    <div className="flex flex-col items-start gap-4">
      {showEtimSearchInput && (
        <EtimSearchInput />
      )}

      <div className="py-2 pl-10 w-full">
        <div className="space-y-4">
          {classesData && classesData.classes && classesData.classes.response
            && (classesData.classes.response.map(
              (option) => (<EtimClass key={option?.id} etimClass={option as ClassType} selectedGroupId={selectedGroupId}/>))
            )
          }
        </div>
      </div>
    </div>
  );
}

export default EtimClasses;
