import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';


export default function DefaultHeader() {
  const { t } = useTranslation();

  return (
    <>
      <div className='flex items-center gap-2 2xl:gap-5 3xl:gap-8'>
        <div className="w-20">{t('Position')}</div>
        <div className="w-16">{t('Type')}</div>
        <div className="w-11">{t('Qty')}</div>
        <div className="w-32">{t('Product ID')}</div>
        <div className="w-96 3xl:w-[450px] 4xl:w-[800px] ">{t('Description')}</div>
        <div className="w-20">{t('Wage')}</div>
        <div className="w-12">{t('Time')}</div>
      </div>
      <div className='flex items-center justify-between 3xl:grow'>
        <Tippy content={t('Profit Surcharge')} placement="top">
          <div className="w-16">{t('Profit')}</div>
        </Tippy>
        <div className="w-16">{t('Supplier')}</div>
        <Tippy content={t('Purchase Price per Piece')} placement="top">
          <div className="w-20">{t('PP/Piece')}</div>
        </Tippy>
        <Tippy content={t('Sales Price per Piece')} placement="top">
          <div className="w-28">{t('SP/Piece')}</div>
        </Tippy>
        <Tippy content={t('Total Sales Price')} placement="top">
          <div className="w-28">{t('Total SP')}</div>
        </Tippy>
      </div>
    </>
  );
}
