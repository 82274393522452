import { useMutation } from '@apollo/client';
import { arrayToTree, Item, TreeItem } from 'performant-array-to-tree';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TemplateItemType } from '../../../../__generated__/graphql';
import { REPLACE_GROUP_WITH_TEMPLATE } from '../../../../api/mutations/groupTemplates';
import { QUOTATION_QUERY } from '../../../../api/queries/quotations/quotation';
import { useAppDispatch, useAppSelector } from '../../../../helpers/reduxHooks';
import { setIsGroupSuggestionOpened } from '../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../shared/dnd/types';
import SuggestionsList from './SuggestionsList';

interface Props {
  objectsData: TemplateItemType[],
  deepestUncollapsedLevel: number,
}

export default function SuggestionsListWrapper(props: Props) {
  const { objectsData, deepestUncollapsedLevel } = props;
  const [itemsTree, setItemsTree] = useState<TreeItem[]>([]);
  const dispatch = useAppDispatch();
  const groupItemIdForSuggestion = useAppSelector(state => state.quotation.groupItemIdForSuggestion);
  const { quotationId } = useParams();
  const { t } = useTranslation();

  const rootGroup = objectsData.find(item => item.parentId === null);

  const [replaceGroupWithTemplateMutation] = useMutation(REPLACE_GROUP_WITH_TEMPLATE, {
    refetchQueries: [
      {
        query: QUOTATION_QUERY,
        variables: {
          quotation: quotationId,
        },
      },
    ],
  },
  );

  useEffect(() => {
    if (objectsData?.length) {
      setItemsTree(arrayToTree(objectsData as Item[], { dataField: null }));
    }
  }, [objectsData]);

  return (
    <>
      <div className='grid grid-cols-6 gap-4'>
        <div className='col-span-5'>
          <SuggestionsList
            indentationWidth={50}
            defaultItems={itemsTree as FlattenedItem[]}
            deepestUncollapsedLevel={deepestUncollapsedLevel}
          />
        </div>
        <button
          type="button"
          onClick={() => {
            replaceGroupWithTemplateMutation({
              variables:
              {
                groupTemplateItem: rootGroup?.id as string,
                quotationItem: groupItemIdForSuggestion,
                quotation: quotationId as string,
              },
            });
            dispatch(setIsGroupSuggestionOpened(false));
          }}
          className="col-span-1 flex justify-center items-center w-20 h-7 rounded my-3 text-sm text-white bg-cblue-500 hover:bg-cblue-700 hover:opacity-90 transition duration-150 focus:bg-cblue-700 focus:opacity-100 active:bg-cblue-700 active:opacity-100"
        >
          {t('Use')}
        </button>
      </div>
      <hr />
    </>
  );
}