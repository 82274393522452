import { Transition } from '@headlessui/react';
import { IconCircleCheck, IconCircleX, IconX } from '@tabler/icons-react';
import { Fragment, useEffect } from 'react';
import { AlertType } from '../../constants';
import { classNames } from '../../helpers/utils';

interface AlertIconProps {
  alertType: string,

}

interface ReduxAlertProps {
  alertType: string,
  isOpen: boolean,
  toggle: () => void,
  messages: string[],
}


function AlertIcon(props: AlertIconProps) {
  const { alertType } = props;
  switch (alertType) {
    case AlertType.ERROR:
      return <IconCircleX className="h-8 w-8 text-red-500" aria-hidden="true" />;
    case AlertType.SUCCESS:
      return <IconCircleCheck className="h-8 w-8 text-green-500" aria-hidden="true" />;
    default:
      return <IconCircleX className="h-8 w-8 text-red-500" aria-hidden="true" />;
  }
}

export default function ReduxAlert(props: ReduxAlertProps) {
  const {
    alertType, isOpen, toggle, messages,
  } = props;
  const isOneMessage = messages.length === 1;

  useEffect(() => {
    if (alertType === AlertType.SUCCESS) {
      setTimeout(() => {
        toggle();
      }, 5000);
    }
  }, [alertType, toggle]);
  return (
    <>
      {/* Global Alert live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-40 fixed bottom-0 inset-0 flex items-end px-4 py-8 pointer-events-none sm:pt-10 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Alert panel, dynamically insert this into the live
          region when it needs to be displayed */}
          <Transition
            show={isOpen}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={
              classNames(
                alertType === AlertType.SUCCESS
                  ? 'bg-green-50 ring-green-200'
                  : 'bg-red-50 ring-red-200',
                'absolute bottom-5 max-w-sm w-full  shadow-2xl rounded-t pointer-events-auto  ring-1 overflow-hidden',
              )
            }
            >
              <div className="py-6 px-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0 my-auto">
                    <AlertIcon alertType={alertType} />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-1">
                    {isOneMessage ? (
                      <span className="mt-1 text-sm ">{messages[0]}</span>
                    )
                      : (
                        <ul className="list-disc pl-5 space-y-1">
                          { }
                          {!!messages.length && messages.map((item, index) => (
                            <li
                              key={index}
                              className={classNames(
                                alertType === AlertType.SUCCESS
                                  ? 'text-green-900'
                                  : 'text-red-900',
                                'mt-1 text-sm',
                              )}
                            >
                              {item}
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <IconX className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}