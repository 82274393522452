/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import type { GroupBase } from 'react-select';
import type { LoadOptions } from 'react-select-async-paginate';
import { AsyncSelectInput } from '../../../layout/fields';

interface Props {
  setFieldValue: SetFieldValue,
  loadOptions: LoadOptions<unknown, GroupBase<unknown>, { skip: any }>,
  initialCustomer?: SelectOption | undefined,
}

export default function CustomerSelect(props: Props) {
  const { setFieldValue, loadOptions, initialCustomer } = props;
  const { t } = useTranslation();

  return (
    <AsyncSelectInput
      placeholder={t('Select Customer')}
      onChange={(e: SelectOption) => setFieldValue('customer', e)}
      loadOptions={loadOptions}
      value={initialCustomer ? initialCustomer : undefined}
    />
  );
}
