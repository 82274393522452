import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { PROJECT_ADDRESS_QUERY } from '../../api/queries/projects';
import { LoadingIndicator } from '../../layout';
import EditProjectAddressForm from './EditProjectAdressForm';


export default function EditProjectAddressFormWrapper() {

  const { projectId } = useParams();
  
  const {
    data: projectAddressData,
  } = useQuery(PROJECT_ADDRESS_QUERY, {
    variables: {
      project: projectId as string,
    },
  });

  if (projectAddressData?.projectAddress?.response) {
    return (
     <EditProjectAddressForm projectAddressData={projectAddressData.projectAddress.response} />
    );
  } else {
    return <LoadingIndicator className="w-full h-96 flex justify-center items-center" />;
  }
}
