import { IconMinus } from '@tabler/icons-react';
import { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../helpers/reduxHooks';
import {
  QuotationGroupFeature, setSelectedGroupFeature,
} from '../../../../../../../redux/quotationSlice';
import NumberFeatureValueInput from '../../../../fields/group/NumberFeatureValueInput';

export default function GroupFeatureRangeValue() {
  const selectedGroupFeature = useAppSelector(state => state.quotation.selectedGroupFeature);
  const dispatch = useAppDispatch();

  // set default value for range type feature if it is not set
  useEffect(() => {
    if (selectedGroupFeature?.value === null || selectedGroupFeature?.value === undefined || !Array.isArray(selectedGroupFeature?.value)) {
      const payload = {
        ...selectedGroupFeature,
        value: [0, 0],
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeMinValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseFloat(e.target.value))) {
      const newValue = [parseFloat(e.target.value), (selectedGroupFeature?.value as number[])[1]];
      const payload = {
        ...selectedGroupFeature,
        value: newValue,
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  };
  const handleChangeMaxValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseFloat(e.target.value))) {
      const newValue = [(selectedGroupFeature?.value as number[])[0], parseFloat(e.target.value)];
      const payload = {
        ...selectedGroupFeature,
        value: newValue,
      } as QuotationGroupFeature;
      dispatch(setSelectedGroupFeature(payload));
    }
  };

  const minValue = Array.isArray(selectedGroupFeature?.value) 
    ? selectedGroupFeature?.value[0] 
    : (selectedGroupFeature?.value !== undefined && selectedGroupFeature?.value !== null)
      ? selectedGroupFeature?.value 
      : 0;

  const maxValue = Array.isArray(selectedGroupFeature?.value)
    ? selectedGroupFeature?.value[1]
    : (selectedGroupFeature?.value !== undefined && selectedGroupFeature?.value !== null)
      ? selectedGroupFeature?.value
      : 0;

  return (
    <div className='flex items-center justify-between'>
      <NumberFeatureValueInput
        value={minValue as number}
        onChange={handleChangeMinValue}
      />
      <IconMinus />
      <NumberFeatureValueInput
        value={maxValue as number}
        onChange={handleChangeMaxValue}
      />
    </div>
  );
}
