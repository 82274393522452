import { useTranslation } from 'react-i18next';


interface Props {
  hasManualSalesPricePiece: boolean;
  calculatedNetSalesPricePieceIncludingDiscountLabel: string;
  netSalesPricePieceIncludingDiscountLabel: string;
}

function NetSalesPriceCell(props: Props) {
  const { 
    hasManualSalesPricePiece,
    calculatedNetSalesPricePieceIncludingDiscountLabel,
    netSalesPricePieceIncludingDiscountLabel,
  } = props;
  const { t } = useTranslation();
  if (hasManualSalesPricePiece) {
    return (
      <div className="flex items-center">
        <div
          className="absolute -top-2 -left-8 bg-cblue-500 w-6 -rotate-180 font-medium text-white overflow-hidden whitespace-nowrap h-14 shadow-lg rounded-tr rounded-tl [writing-mode:vertical-lr]"
        >
          <span className='truncate cursor-default' style={{ marginTop: '0.5rem' }}>
            {t('Manual')}
          </span>
        </div>
        <div>
          <div className="text-sm">{netSalesPricePieceIncludingDiscountLabel}</div>
          <div className="text-[0.6rem]">{calculatedNetSalesPricePieceIncludingDiscountLabel}</div>
        </div>
      </div>
    );
  }
  return <span>{netSalesPricePieceIncludingDiscountLabel}</span>;
}

export default NetSalesPriceCell;
