import { AlertType } from '../../../../constants';
import { getItemWidth } from '../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../helpers/reduxHooks';
import { classNames } from '../../../../helpers/utils';
import { RobotIndicator } from '../../../../layout';
import PasteArrowIndicator from '../fields/PasteArrowIndicator';
import DefaultHeader from './DefaultHeader';
import EstimateHeader from './EstimateHeader';

interface Props {
  indentationWidth: number;
}

export default function ListHeader(props: Props) {
  const { indentationWidth } = props;
  const maximumQuotationUncollapsedDepth = useAppSelector(state => state.quotation.maximumQuotationUncollapsedDepth);
  const isEstimateMode = useAppSelector(state => state.quotation.isEstimateMode);
  const isLoadingUpdate = useAppSelector(state => state.quotation.isLoadingUpdate);
  const isError = useAppSelector((state) => state.alert.alert?.alertType) === AlertType.ERROR;
  const isComposeMode = useAppSelector(state => state.quotation.isComposeMode);
  const selectedItem = useAppSelector(state => state.quotation.selectedItem);

  return (
    <div className={classNames(location.pathname.includes('single') ? '' : 'pt-4 sticky top-14 z-20', 'flex text-xs pb-4 bg-white')}>
      <div className={classNames(location.pathname.includes('single') ? 'text-2xs pt-1 pb-1' : 'text-sm border-t-2 pt-2 pb-2', 'w-full flex gap-6 justify-between items-center bg-cblue-100 border-b-2 border-cblue-500 font-bold text-cgray-600 relative')}>
        <div 
          style={{ width: `${getItemWidth(maximumQuotationUncollapsedDepth, indentationWidth, 0)}px` }}
          >
        </div>
        {isComposeMode && selectedItem && <PasteArrowIndicator />}
        <div className='w-14 items-center justify-center'>
          <RobotIndicator 
            isLoading={isLoadingUpdate} 
            isError={isError}
            />
        </div>
        {!isEstimateMode ? (
          <DefaultHeader />
        ) : (
          <EstimateHeader />
        )}
      </div>
    </div>
  );
}
