import { useMutation } from '@apollo/client';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import Tippy from '@tippyjs/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MOVE_QUOTATION_ITEM_MUTATION } from '../../../../../api/mutations/quotations/item';
import { QUOTATION_QUERY } from '../../../../../api/queries/quotations/quotation';
import { useAppDispatch } from '../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../helpers/utils';
import { setIsLoadingUpdate } from '../../../../../redux/quotationSlice';
import { FlattenedItem } from '../../../../shared/dnd/types';

interface Props {
  item: FlattenedItem;
  quotationLength: number;
  quotationId: string;
  initialIndex: number;
}


const ChangeOrderArrows = (props: Props) => {
  const { item, quotationLength, quotationId, initialIndex } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    moveMutation,
    {
      loading: moveMutationLoading,
    },
  ] = useMutation(MOVE_QUOTATION_ITEM_MUTATION, {
    refetchQueries:[
      { query:QUOTATION_QUERY,
        variables: {
          quotation: quotationId,
        }, 
      },
    ],
  },
  );

  const moveItem = (payload: number) => {
    moveMutation(
      {
        variables: {
          quotation: quotationId as string,
          items: [item?.id as string],
          parent: item?.parentId as string,
          order: initialIndex + payload,
        },
      },
    );
  };

  useEffect(() => {
    dispatch(setIsLoadingUpdate(moveMutationLoading));
  }, [dispatch, moveMutationLoading]);

  return (
    <div className='w-12 flex justify-end items-center'>
      {!item.parentId &&
      <>
        {initialIndex + 1 !== quotationLength && 
          <Tippy content={t('Move Group Down')}>
            <IconArrowDown className={classNames('cursor-pointer', 'text-cgray-500')} onClick={()=>!moveMutationLoading && moveItem(+1)}/>
          </Tippy>
        }
        {initialIndex !== 0 && 
          <Tippy content={t('Move Group Up')}>
            <IconArrowUp className={classNames('cursor-pointer', 'text-cgray-500')} onClick={()=>!moveMutationLoading && moveItem(-1)} />
          </Tippy>
        }
      </>}  
    </div>
  );
};

export default ChangeOrderArrows;
