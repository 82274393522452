import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditProjectAddressFormWrapper from '../components/editProject/EditProjectAdressFormWrapper';
import EditProjectDetailsFormWrapper from '../components/editProject/EditProjectDetailsFormWrapper';
import ReduxAlertWrapper from '../components/ReduxAlertWrapper';
import { classNames } from '../helpers/utils';
import { Navbar } from '../layout';


export default function EditProject() {
  const { t } = useTranslation();
  const [DisplayAddressEdit, setDisplayAddressEdit] = useState<boolean>(true);

  return (
    <div className="flex flex-wrap min-h-screen">
      <div className="w-full">
        <Navbar />
        <div className="pt-1">
          <ReduxAlertWrapper />
          <main>
            <div className="rounded-b-xl drop-shadow-xl bg-white w-[900px] mx-auto m-12">
              <div 
                className='pl-12 bg-cblue-100 w-[900px] mx-auto h-14 border-b-2 border-cblue-500 font-bold text-cblue-500 rounded-t-xl flex gap-8 items-center'
              >
                <p 
                  className={classNames(!DisplayAddressEdit && 'text-cgray-600 hover:text-cgray-900 cursor-pointer')}
                  onClick={()=>setDisplayAddressEdit(true)}
                  >
                  {t('Edit Project Details')}
                </p>
                <p 
                  className={classNames(DisplayAddressEdit && 'text-cgray-600 hover:text-cgray-900 cursor-pointer')}
                  onClick={()=>setDisplayAddressEdit(false)}
                >
                  {t('Edit Project Address')}
                </p>
              </div>
              {DisplayAddressEdit ? <EditProjectDetailsFormWrapper /> : <EditProjectAddressFormWrapper />}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
