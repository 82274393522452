interface Props {
  label: string,
  code: number,
}

function ErrorPageTempate(props: Props) {
  const { label, code } = props;
  return (
    <div className="flex justify-center items-center min-h-screen bg-cgray-100 shadow sm:rounded-lg">
      <div className="text-center">
        <div className="text-5xl leading-6 font-medium text-cgray-500">{code}</div>
        <div className="mt-5 max-w-xl text-xl text-cgray-500">
          <p>{label}</p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPageTempate;
