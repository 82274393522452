import { useMutation } from '@apollo/client';
import { IconStarFilled, IconStar } from '@tabler/icons-react';
import { TOGGLE_ETIM_CLASS_FAVOURITE_STATUS } from '../../../../api/mutations/etimReleases';
import {
  CLASSES_QUERY,
} from '../../../../api/queries/etimReleases';
import { LoadingIndicator } from '../../../../layout';
// import { useState } from 'react';

interface Props {
  selectedGroupId: string,
  selectedClassId: string,
  searchInput?: string,
  isFavourite: boolean
}

function FavouriteButton(props: Props) {
  const { selectedGroupId, selectedClassId, searchInput, isFavourite } = props;
  // const [isLoading, setIsLoading] = useState(false);

  const [
    toggleFavouriteStatus,
    {
      loading: toggleFavouriteStatusLoading,
    },
  ] = useMutation(TOGGLE_ETIM_CLASS_FAVOURITE_STATUS, {
    // Looks like adding this line syncs up mutation loading time
    // This way icon display works like tihs:
    // Initial state
    // button clicked -> loading is showing all the time mutation and following query is performed
    // Reversed state
    onQueryUpdated: () => {return true;},
    refetchQueries: [
      {
        query: CLASSES_QUERY,
        variables: {
          group: selectedGroupId,
          searchInput: searchInput,
        },
        // fetchPolicy: 'network-only',
      },
    ],
  },
  );

  const handleFavoriteClick = () => {
    toggleFavouriteStatus(
      {
        variables: {
          etimClass: selectedClassId,
        },
      },
    );
  };

  return (
    <div onClick={handleFavoriteClick}>
      {toggleFavouriteStatusLoading
        ? <div className='w-5 h-5'> <LoadingIndicator /></div>
        : isFavourite
          ? (<IconStarFilled className='w-5 h-5 cursor-pointer text-cblue-500' />)
          : (<IconStar className='w-5 h-5 cursor-pointer text-cgray-500' />)
      }
    </div>
  );
}

export default FavouriteButton;
