import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { IconClock } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';
import { QuotationNoteType, UserType } from '../../../__generated__/graphql';
import { classNames } from '../../../helpers/utils';
import TimeAgo from '../../../layout/TimeAgo';
import UpdateNoteForm from './UpdateNoteForm';
import UserAvatar from './UserAvatar';



function convertTextWithNewLinesIntoDivs(inputText: string) {
  return inputText.split('\n').map((str, index) => <div key={index} className="pt-1">{str}</div>);
}

interface Props {
  item: QuotationNoteType;
  user: UserType;
  projectId: string;
  quotationId: string;
  refetchQueries: InternalRefetchQueriesInclude;
}

const NoteItem = (props: Props) => {
  const { item, user, quotationId, refetchQueries } = props;
  const [editModeEnabled, setEditModeEnabled] = useState(false);
  const userOwnsNote = user.id === item.author?.id;


  const updateNoteFormRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (editModeEnabled && updateNoteFormRef.current) {
      updateNoteFormRef.current.focus();
    }
  }, [editModeEnabled]);
  
  return (
    <>
      <div className="relative">
        <UserAvatar user={item.author as UserType} />
      </div>
      {editModeEnabled
        ? (
          <UpdateNoteForm
            ref={updateNoteFormRef}
            note={item}
            user={user}
            handleClose={() => setEditModeEnabled(false)}
            quotationId={quotationId}
            refetchQueries={refetchQueries}
          />
        )
        : (
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                <div className="flex font-medium text-gray-900">
                  {item.author?.fullName}
                </div>
              </div>
            </div>
            <div
              onClick={userOwnsNote ? () => setEditModeEnabled(true) : undefined}
              className={
                classNames(
                  userOwnsNote ? 'cursor-text bg-cgray-100 hover:bg-cgray-200 focus:bg-cgray-200' : 'border-l-4 border-cblue-300 bg-cblue-100',
                  'p-4 mt-2 text-sm text-gray-700',
                )
              }
            >
              {convertTextWithNewLinesIntoDivs(item.body)}
            </div>
            <div className="mt-0.5 text-xs text-cgray-400 flex">
              <IconClock className="h-4 w-4 mr-1 text-cgray-400" aria-hidden="true" />
              <TimeAgo date={item.createdAt} />
            </div>
          </div>

        )
      }
    </>
  );
};

export default NoteItem;
