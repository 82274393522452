import { gql } from '../../__generated__/gql';

export const CURRENT_USER_QUERY = gql(`
  query User{
    user{
      id
      fullName
      email
      firstName
      lastName
      isSuperuser
    }
  }
`);


export const INLINE_USERS_QUERY = gql(`
  query inlineUsers{
    inlineUsers{
      id
      fullName
    }
  }
`);
