import { gql } from '../../../__generated__/gql';

export const QUOTATION_NOTE_MUTATION = gql(`
  mutation QuotationNote(
    $quotation: ID!
    $author: ID!
    $body: String!
    $id: ID
    ){
      quotationNote(input:{
        quotation: $quotation
        author: $author
        body: $body
        id: $id
      })
      {
        response{
          id
          createdAt
          updatedAt
          body
          author{
            id
            fullName
            firstName
            lastName
          }
        }
        errors{
          field
          messages
        }
    }
  }
`);

export const DELETE_QUOTATION_NOTE_MUTATION = gql(`
  mutation DeleteQuotationNote(
    $note: ID!){
      deleteQuotationNote(input:{
      note: $note})
    {
      response
      errors{
        field
        messages
    }
    }
  }
`);
