import { Range } from 'rc-slider';
import '../../assets/css/rc-slider.css';

interface Props {
  values: number[],
  min: number,
  max: number,
  onChange?: (selectedValue: number[]) => void,
  label: string,
  abbreviation?: string,
  isDisabled?: boolean,
}

function RangeInput(props: Props) {
  const {
    min, max, values, onChange, label, abbreviation, isDisabled,
  } = props;

  const handleChange = (currentUserValue: number[]) => {
    if (onChange) {
      onChange(currentUserValue);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <label className="text-cgray-400 font-medium text-sm">
        <span>{label}
        {abbreviation ? (<span>, {abbreviation}</span>) : ''}
        :
        </span>
      </label>
      <div className="flex items-center gap-4">
        <Range
          defaultValue={[values[0], values[1]]}
          value={[values[0], values[1]]}
          min={min}
          max={max}
          onChange={handleChange}
          pushable={true}
          disabled={isDisabled}
          handleStyle={isDisabled ? [{ backgroundColor: '#e9e9e9' }, { backgroundColor: '#e9e9e9' }] : []}
          trackStyle={isDisabled ? [{ backgroundColor: '#e9e9e9' }] : [{ }]}
          className={'bg-transparent' }
        />
      </div>
    </div>
  );
}

export default RangeInput;
