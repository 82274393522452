import { gql } from '../../../__generated__/gql';

export const PRODUCT_PRICE_SUGGESTION_QUERY = gql(`
query productPriceSuggestion(
    $product: ID!
  ){
    productPriceSuggestion(
      product: $product
    ){
      response{
        id
        suggestedPrice
        suggestedPriceLabel
        triggeredBy{
          id
          fullName
        }
        userIsAbleToRegenerate
      }
      errors {
        messages
        field
      }
  }
}
`);
