import { FlattenedItem } from '../../shared/dnd/types';

export const getSubTotalRowStyles = (index: number, array: FlattenedItem[]) => {
  let borderStyles;

  if (index === 0 && array.length > 1) {
    borderStyles = ' border-b border-r';
  } else if (index === array.length - 1 && array.length > 1) {
    borderStyles = 'border-t border-r';
  } else if (array.length === 1) {
    borderStyles = 'border-r';
  } else {
    borderStyles = 'border-t border-b border-r';
  }

  return `${borderStyles} pl-1 flex items-center justify-between grow rounded border-cgray-400`;
};