import React, { useState } from 'react';
import { getItemWidth } from '../../../../../../helpers/dnd/utilities';
import { useAppSelector } from '../../../../../../helpers/reduxHooks';
import { classNames } from '../../../../../../helpers/utils';
import RODefaultServiceDetails from '../../../../../quotation/list/row/details/RODefaultServiceDetails';
import styles from '../../../../../shared/dnd/TreeItem.module.css';
import { TreeItemIcon } from '../../../../../shared/dnd/TreeItemIcon';
import { FlattenedItem } from '../../../../../shared/dnd/types';
import PasteArrow from '../../../fields/PasteArrow';

interface Props {
  indentationWidth: number;
  item: FlattenedItem;
  isSearchResult: boolean;
}

function ServiceItem(props: Props) {
  const { indentationWidth, item } = props;
  const [showDetails, setShowDetails] = useState(false);
  const maximumSearchUncollapsedDepth = useAppSelector(state => state.search.maximumSearchUncollapsedDepth);

  return (
    <li
      className={classNames(styles.Wrapper, 'relative')}
      style={
        {
          '--spacing': `${indentationWidth * item.depth}px`,
        } as React.CSSProperties
      }
    >
      <div
        className={classNames(styles.TreeItem, 'overflow-hidden')}
        style={
          {
            '--justifyContent': 'start',
            '--borderBottomColor': '#d3d3d3',
            '--border-b-l-radius': '4px 4px',
            '--border-b-r-radius': '4px 4px',
          } as React.CSSProperties
        }
      >
        <div
          className='flex justify-end'
          style={{ width: `${getItemWidth(maximumSearchUncollapsedDepth, indentationWidth, item.depth)}px` }}
        >
        </div>
        <div className='w-14 py-3.5 flex items-center justify-between'>
          <TreeItemIcon
            item={item}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
          />
        </div>
        <div className="w-16 "></div>
        <div className="w-20 "></div>
        <div className="w-28 "></div>
        <div className="w-[600px] 3xl:w-[900px] 4xl:w-[1400px] truncate">{item?.service?.description}</div>
        <PasteArrow item={item} />
      </div>
      {showDetails && (
        <RODefaultServiceDetails
          item={item}
          indentationWidth={indentationWidth}
          depth={item.depth}
          maximumUncollapsedDepth={maximumSearchUncollapsedDepth}
        />
      )}
    </li>
  );
}

export default ServiceItem;
