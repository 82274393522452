import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../helpers/reduxHooks';
import { IconG } from '../../../layout/icons';
import { setShowCreateGroupModal } from '../../../redux/quotationSlice';


export default function NoGroupRecordsArea() {
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();


  const className = isHovered 
    ? 'w-6 h-6 flex items-center justify-center font-bold bg-cblue-200 text-cblue-500 border-2 border-cblue-500 rounded' 
    : 'w-6 h-6 flex items-center justify-center font-bold bg-cgray-100 text-cgray-400 border-2 border-cgray-400 rounded';

  return (
    <>
      <div
        onClick={() => dispatch(setShowCreateGroupModal(true))}
        onMouseEnter={()=>setIsHovered(!isHovered)}
        onMouseLeave={()=>setIsHovered(!isHovered)}
        className="w-full py-3.5 pl-12 pr-28 flex items-center border-[1px] rounded border-gray-300 bg-cgray-100 hover:bg-cgray-200 text-sm text-cgray-400 hover:text-cgray-600 cursor-pointer"
      >
        <IconG className={className} />
        <p className="m-auto">{t('There are no Groups at the moment. Click here to create a Group.')}</p>
      </div>
    </>
  );
}
