import type { InternalRefetchQueriesInclude } from '@apollo/client';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { UPDATE_GROUP_DISCOUNT } from '../../../../../api/mutations/quotations/group';
import { useQuotationItemInputHandler } from '../../../../../helpers/customHooks';
import { classNames } from '../../../../../helpers/utils';

interface Props {
  label?: boolean,
  refetchQueries: InternalRefetchQueriesInclude,
  discount: number,
  id: ID,
}

interface FormValues {
  discount: number,
  group: ID,
  quotation: ID,
}

export default function DiscountInput(props: Props) {
  const { discount, id, label, refetchQueries } = props;
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quotationId } = useParams();
  const { t } = useTranslation();
  const fieldName = 'discount';

  const validationSchema = yup.object({
    [fieldName]: yup.number()
      .transform((_, transformedValue) => {
        if (typeof transformedValue === 'string') {
          const numberValue = parseFloat(transformedValue);
          if (isNaN(numberValue)) {
            return undefined; // return original string if it's not a valid number
          }
          // If it is a valid number, format it to two decimal places
          return parseFloat(numberValue.toFixed(2));
        }
        // If transformedValue is already a number, convert it anyway,
        // othwerwise it occasionally fails to work with GQL types
        return parseFloat(transformedValue.toFixed(2));
      })
      .required(t('Required')),
  });


  const initialValues: FormValues = {
    discount: discount,
    group: id,
    quotation: quotationId as ID,
  };

  const { handleChange, handleSubmit, values, handleBlur, isError  } = useQuotationItemInputHandler({
    mutation: UPDATE_GROUP_DISCOUNT,
    fieldName,
    validationSchema,
    initialValues,
    refetchQueries,
    setInputFocused,
    inputFocused,
  });

  return (
    <div
      className={classNames(inputFocused && 'bg-cgray-200 border-cblue-500', isError && 'border-cred-500', 'relative border-b pl-2 py-px h-full flex items-center justify-between rounded-t bg-cgray-100 hover:bg-cgray-200 cursor-text group')}
      onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
    >
        {label && (
          <label
            className={classNames(isError ? 'text-cred-500' : 'text-cgray-400', 'absolute top-0 left-2 text-2xs cursor-text')}
            onClick={() => { if (inputRef.current) inputRef.current.focus(); }}
          >
            {t('Discount')}
          </label>
        )}
        <input
          name={fieldName}
          value={values[fieldName]}
          onChange={handleChange}
          className={classNames(!label ? 'min-h-[40px] ' : 'max-h-[21px]', 'p-0 w-full text-sm border-none text-right rounded-t ring-0 outline-none bg-cgray-100 group-hover:bg-cgray-200 focus:bg-cgray-200 focus:ring-0')}
          ref={inputRef}
          onFocus={() => setInputFocused(true)}
          onBlur={() => handleBlur()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
              (e.target as HTMLInputElement).blur();
            }
          }}
        />
      <p className="text-gray-400 text-sm px-1.5 h-full flex justify-center items-center">%</p>
    </div>
  );
}
